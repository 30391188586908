import pako from 'pako'

export function decompressData(data) {
  if (!data) {
    console.error('没有提供数据进行解压')
    return null
  }

  try {
    // 解码 Base64 数据
    const binaryString = atob(data)
    const charData = binaryString.split('').map((char) => char.charCodeAt(0))
    const binData = new Uint8Array(charData)

    // 解压缩数据
    const decompressedData = pako.ungzip(binData, { to: 'string' })
    
    // 尝试解析 JSON
    const parsedData = JSON.parse(decompressedData)
    
    if (!parsedData || (Array.isArray(parsedData) && parsedData.length === 0)) {
      console.warn('解压后的数据为空')
      return null
    }
    
    return parsedData
  } catch (error) {
    console.error('数据解压或解析失败:', error)
    return null
  }
}
