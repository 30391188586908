<template>
  <div class="match-list-wrapper">
    <div class="match-container" v-for="match in matches" :key="match.commonAttr.matchId">
      <div class="header-container">
        <div class="league-info">
          <img :src="match.commonAttr.leagueLog" alt="联赛logo" class="league-logo" />
          {{ match.commonAttr.leagueName }}
        </div>
        <div class="header-titles">
          <span class="session">1</span>
          <span class="session">2</span>
          <span class="session">3</span>
          <span class="session"></span>
          <span class="session"></span>
          <span class="session final">全场</span>
          <span class="session total">总计</span>
          <span class="live">直播</span>
          <span class="winloss">胜负</span>
          <span class="handicap">让局</span>
          <span class="over-under">大小</span>
          <span class="op">操作</span>
        </div>
      </div>

      <div class="match-info">
        <div class="left-info">
          <div class="date-time">
            <span class="date">{{ formatDate(match.commonAttr.matchTime) }}</span>
            <span class="time">{{ formatTime(match.commonAttr.matchTime) }}</span>
          </div>
          <div class="status" :class="getStatusClass(match)">
            {{ match.commonAttr.matchStatusTxt }}
          </div>
          <div class="teams">
            <div
              class="team"
              v-for="team in [match.commonAttr.homeTeam, match.commonAttr.guestTeam]"
              :key="team.id"
            >
              <img :src="team.logo" alt="队伍logo" class="team-logo" />
              <span class="team-name">{{ team.name }}</span>
            </div>
          </div>
        </div>

        <div class="current-scores">
          <div class="score-row" v-show="isLive(match)">
            <p>
              <img
                src="@assets/icons/score/tennis_icon.webp"
                v-show="match.scoreTable.side === 1"
              />{{ match.scoreTable.homeGameScore }}
            </p>
          </div>
          <div class="score-row" v-show="isLive(match)">
            <p>
              <img
                src="@assets/icons/score/tennis_icon.webp"
                v-show="match.scoreTable.side === 2"
              />{{ match.scoreTable.guestGameScore }}
            </p>
          </div>
        </div>

        <div class="scores">
          <div class="score-row">
            <span v-for="(score, index) in getScores(match, match.scoreTable.home)" :key="index">{{
              score
            }}</span>
          </div>
          <div class="score-row">
            <span v-for="(score, index) in getScores(match, match.scoreTable.guest)" :key="index">{{
              score
            }}</span>
          </div>
        </div>

        <div class="total-score">
          <p>{{ isShowingData(match) ? match.commonAttr.homeScore : '-' }}</p>
          <p>{{ isShowingData(match) ? match.commonAttr.guestScore : '-' }}</p>
        </div>

        <div class="total-points">
          <p><span>总</span>{{ isShowingData(match) ? match.scoreTable.sumScore : '-' }}</p>
          <p><span>分</span>{{ isShowingData(match) ? match.scoreTable.subScore : '-' }}</p>
        </div>

        <div class="live-icon">
          <img
            v-if="match.commonAttr.hasVid"
            src="@assets/icons/score/video-active.webp"
            alt="直播"
          />
          <img
            v-if="match.commonAttr.hasAnim"
            src="@assets/icons/score/animation-active.webp"
            alt="动画"
          />
        </div>

        <div class="winloss">
          <span>{{ isShowingData(match) ? match.TennisEi.home[0] : '-' }}</span>
          <span>{{ isShowingData(match) ? match.TennisEi.guest[0] : '-' }}</span>
        </div>

        <div class="handicap"></div>

        <div class="over-under"></div>

        <div class="actions">
          <img src="@assets/icons/score/collect.webp" alt="收藏" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import dayjs from 'dayjs'
const myProps = defineProps(['list', 'hasReset'])
const myEmit = defineEmits(['selectMatch'])
const matches = ref([])
const selectIndex = ref(0)
const init = ref(false)
const selectMatch = (res, index) => {
  myEmit('selectMatch', res)
  selectIndex.value = index
}
const handleSelect = (index) => {
  console.log(index, 'index')
}
watch(
  () => myProps.list,
  (newProps) => {
    // console.log(newProps, 'newProps')
    matches.value = newProps
  }
)

watch(
  () => myProps.hasReset,
  (newProps) => {
    if (newProps) {
      selectIndex.value = 0
      myEmit('selectMatch', myProps.list[0])
    }
  }
)

const formatDate = (timestamp) => {
  return dayjs(timestamp).format('MM-DD')
}

const formatTime = (timestamp) => {
  return dayjs(timestamp).format('HH:mm')
}

const isShowingData = (match) => {
  return match.commonAttr.status === 2 || match.commonAttr.status === 3
}

const isLive = (match) => {
  return match.commonAttr.status === 2
}

const getStatusClass = (match) => {
  if (match.commonAttr.status === 2) {
    return 'status-live'
  } else if (match.commonAttr.status === 3) {
    return 'status-finished'
  } else {
    return 'status-not-started'
  }
}

const getScores = (match, scores) => {
  return isShowingData(match) ? scores : Array(scores.length).fill('-')
}
</script>

<style lang="scss" scoped>
.match-list-wrapper {
  width: 1200px;

  .match-container {
    background: $scoreTennis-matchList-bg;
    border-radius: 12px;
    border: 1px solid $scoreTennis-matchList-border;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
      border: 1px solid $scoreTennis-matchList-hover-border;
    }
    .header-container {
      display: flex;
      align-items: center;
      height: 30px;
      border-bottom: 1px solid $scoreTennis-matchList-border;
      padding: 0 20px;

      .league-info {
        width: 455px;
        font-size: 12px;
        color: $scoreTennis-matchList-text-dark;
        display: flex;
        align-items: center;

        .league-logo {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }

      .header-titles {
        display: flex;
        flex: 1;
        font-size: 12px;
        color: $scoreTennis-matchList-text-medium;

        span {
          text-align: center;
        }

        .session {
          width: 40px;
        }
        .final,
        .total {
          width: 50px;
        }
        .live {
          width: 50px;
        }
        .winloss {
          width: 60px;
        }
        .handicap {
          width: 100px;
        }
        .over-under {
          width: 130px;
        }
        .op {
          width: 60px;
        }
      }
    }

    .match-info {
      display: flex;
      align-items: center;
      height: 70px;

      .left-info {
        display: flex;
        height: 100%;

        .date-time {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 80px;
          font-size: 12px;
          height: 100%;
          color: $scoreTennis-matchList-text-dark;
          border-right: 1px solid $scoreTennis-matchList-divider;
        }

        .status {
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          height: 100%;
          border-right: 1px solid $scoreTennis-matchList-divider;

          &.status-live {
            color: $scoreTennis-matchList-total-score;
          }

          &.status-finished {
            color: $scoreTennis-matchList-text-dark;
          }

          &.status-not-started {
            color: $scoreTennis-matchList-text-light;
          }
        }

        .teams {
          width: 285px;
          height: 100%;

          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          .team {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            margin-left: 20px;

            .team-logo {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

            .team-name {
              font-size: 14px;
              color: $scoreTennis-matchList-text-dark;
            }
          }
        }
      }
      .current-scores {
        border-right: 1px solid $scoreTennis-matchList-divider;
        height: 100%;
        width: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        .score-row {
          width: 100%;
          height: 16px;
          border-radius: 2px;
          color: $scoreTennis-matchList-live-score;
          font-size: 12px;
          font-weight: 1000;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;
          p {
            display: flex;
            align-items: center;
          }
          img {
            width: 13px;
            margin-right: 10px;
          }
        }
      }
      .scores {
        display: flex;
        flex-direction: column;
        width: 200px;

        .score-row {
          display: flex;
          span {
            width: 40px;
            text-align: center;
            font-weight: bold;
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $scoreTennis-matchList-text-dark;
          }
        }
      }

      .total-score {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $scoreTennis-matchList-divider;
        height: 100%;

        p {
          font-size: 14px;
          font-weight: bold;
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 800;
          font-size: 14px;
          color: $scoreTennis-matchList-total-score;
        }
      }

      .total-points {
        width: 50px;
        text-align: center;
        font-size: 12px;
        color: $scoreTennis-matchList-text-medium;
        flex-direction: column;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $scoreTennis-matchList-divider;
        p {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          span {
            width: 10px;
          }
        }
      }

      .live-icon,
      .winloss,
      .handicap,
      .over-under,
      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .live-icon {
        flex-direction: column;
      }
      .live-icon {
        width: 50px;
        border-right: 1px solid $scoreTennis-matchList-divider;
        height: 100%;
        img {
          width: 20px;
        }
      }
      .winloss {
        width: 60px;
        flex-direction: column;
        height: 100%;
        border-right: 1px solid $scoreTennis-matchList-divider;
        span {
          font-size: 12px;
          color: $scoreTennis-matchList-winloss;
        }
      }
      .handicap {
        width: 100px;
        height: 100%;
        font-size: 12px;
        border-right: 1px solid $scoreTennis-matchList-divider;
      }
      .over-under {
        width: 130px;
        height: 100%;
        font-size: 12px;
        border-right: 1px solid $scoreTennis-matchList-divider;
      }
      .actions {
        width: 60px;
        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
