<template>
  <div class="match-list-wrapper">
    <div class="match-container" v-for="match in matches" :key="match.commonAttr.matchId">
      <div class="header-container">
        <div class="league-info">
          <img :src="match.commonAttr.leagueLog" alt="联赛logo" class="league-logo" />
          {{ match.commonAttr.leagueName }}
        </div>
        <div class="header-titles">
          <span class="session">1</span>
          <span class="session">2</span>
          <span class="session">3</span>
          <span class="session">4</span>
          <span class="session">5</span>
          <span class="session">6</span>
          <span class="session">7</span>
          <span class="session">8</span>
          <span class="session">9</span>
          <span class="session">EI</span>
          <span class="final">全场</span>
          <span class="total">总计</span>
          <span class="live">直播</span>
          <span class="winloss">欧指</span>
          <span class="handicap">让分</span>
          <span class="over-under">总分</span>
          <span class="op">操作</span>
        </div>
      </div>

      <div class="match-info">
        <div class="left-info">
          <div class="date-time">
            <span class="date">{{ formatDate(match.commonAttr.matchTime) }}</span>
            <span class="time">{{ formatTime(match.commonAttr.matchTime) }}</span>
          </div>
          <div class="status" :class="getStatusClass(match)">
            {{ match.commonAttr.matchStatusTxt }}
          </div>
          <div class="teams">
            <div
              class="team"
              v-for="team in [match.commonAttr.homeTeam, match.commonAttr.guestTeam]"
              :key="team.id"
            >
              <img :src="team.logo" alt="队伍logo" class="team-logo" />
              <span class="team-name">{{ team.name }}</span>
            </div>
          </div>
        </div>

        <div class="scores">
          <div class="score-row" v-for="(team, index) in ['home', 'guest']" :key="team">
            <span
              v-for="(score, scoreIndex) in match.scoreTable[team]"
              :key="scoreIndex"
              v-show="scoreIndex < 10"
            >
              {{ isShowingData(match) ? score : '-' }}
            </span>
          </div>
        </div>

        <div class="total-score">
          <p>{{ isShowingData(match) ? match.commonAttr.homeScore : '-' }}</p>
          <p>{{ isShowingData(match) ? match.commonAttr.guestScore : '-' }}</p>
        </div>

        <div class="total-points">
          <p><span>总</span>{{ isShowingData(match) ? match.scoreTable.sumScore : '-' }}</p>
          <p><span>差</span>{{ isShowingData(match) ? match.scoreTable.subScore : '-' }}</p>
        </div>

        <div class="live-icon">
          <img
            v-if="match.commonAttr.hasVid"
            src="@assets/icons/score/video-active.webp"
            alt="直播"
          />
          <img
            v-if="match.commonAttr.hasAnim"
            src="@assets/icons/score/animation-active.webp"
            alt="动画"
          />
        </div>

        <div class="winloss">
          <span>{{ isShowingData(match) ? match.baseEi.home[0] : '-' }}</span>
          <span>{{ isShowingData(match) ? match.baseEi.guest[0] : '-' }}</span>
        </div>

        <div class="handicap">
          <!-- <span>{{ isShowingData(match) ? match.baseEi.home[3] : '-' }}</span> -->
          <span>{{ isShowingData(match) ? match.baseEi.home[2] : '-' }}</span>
          <span>{{ isShowingData(match) ? match.baseEi.guest[2] : '-' }}</span>
        </div>

        <div class="over-under">
          <p>
            <span>{{ isShowingData(match) ? match.baseEi.home[3] : '-' }}</span>
            <span>{{ isShowingData(match) ? match.baseEi.home[4] : '-' }}</span>
          </p>
          <p>
            <span>{{ isShowingData(match) ? match.baseEi.guest[3] : '-' }}</span>
            <span>{{ isShowingData(match) ? match.baseEi.guest[4] : '-' }}</span>
          </p>
        </div>

        <div class="actions">
          <img src="@assets/icons/score/collect.webp" alt="收藏" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import dayjs from 'dayjs'
const myProps = defineProps(['list', 'hasReset'])
const myEmit = defineEmits(['selectMatch'])
const matches = ref([])
const selectIndex = ref(0)
const init = ref(false)
const selectMatch = (res, index) => {
  myEmit('selectMatch', res)
  selectIndex.value = index
}
const handleSelect = (index) => {
  console.log(index, 'index')
}
watch(
  () => myProps.list,
  (newProps) => {
    // console.log(newProps, 'newProps')
    matches.value = newProps
  }
)

watch(
  () => myProps.hasReset,
  (newProps) => {
    if (newProps) {
      selectIndex.value = 0
      myEmit('selectMatch', myProps.list[0])
    }
  }
)

const formatDate = (timestamp) => {
  return dayjs(timestamp).format('MM-DD')
}

const formatTime = (timestamp) => {
  return dayjs(timestamp).format('HH:mm')
}

const isShowingData = (match) => {
  return match.commonAttr.status === 2 || match.commonAttr.status === 3
}

const isLive = (match) => {
  return match.commonAttr.status === 2
}

const getStatusClass = (match) => {
  if (match.commonAttr.status === 2) {
    return 'status-live'
  } else if (match.commonAttr.status === 3) {
    return 'status-finished'
  } else {
    return 'status-not-started'
  }
}

const getScores = (match, scores) => {
  return isShowingData(match) ? scores : Array(scores.length).fill('-')
}
</script>

<style lang="scss" scoped>
.match-list-wrapper {
  width: 1200px;

  .match-container {
    background: $scoreBaseball-schedule-match-list-bg-white;
    border-radius: 12px;
    border: 1px solid $scoreBaseball-schedule-match-list-border;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
      border: 1px solid $scoreBaseball-schedule-match-list-border-hover;
    }
    .header-container {
      display: flex;
      align-items: center;
      height: 30px;
      border-bottom: 1px solid $scoreBaseball-schedule-match-list-border;
      padding: 0 0 0 20px;

      .league-info {
        width: 389px;
        font-size: 12px;
        color: $scoreBaseball-schedule-match-list-text-dark;
        display: flex;
        align-items: center;

        .league-logo {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }

      .header-titles {
        display: flex;
        flex: 1;
        font-size: 12px;
        color: $scoreBaseball-schedule-match-list-text-medium;

        span {
          text-align: center;
        }

        .session {
          width: 30px;
        }
        .final,
        .total {
          width: 50px;
        }
        .live {
          width: 50px;
        }
        .winloss {
          width: 60px;
        }
        .handicap {
          width: 100px;
        }
        .over-under {
          width: 130px;
        }
        .op {
          width: 60px;
        }
      }
    }

    .match-info {
      display: flex;
      align-items: center;
      height: 70px;
      // padding: 0 20px;

      .left-info {
        display: flex;
        height: 100%;
        // width: 300px;

        .date-time {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 115px;
          font-size: 12px;
          height: 100%;
          color: $scoreBaseball-schedule-match-list-text-medium;
          border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
        }

        .status {
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          height: 100%;
          border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;

          &.status-live {
            color: $scoreBaseball-schedule-match-list-text-red;
          }

          &.status-finished {
            color: $scoreBaseball-schedule-match-list-text-dark;
          }

          &.status-not-started {
            color: $scoreBaseball-schedule-match-list-text-light;
          }
        }

        .teams {
          width: 225px;
          height: 100%;

          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
          .team {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            margin-left: 20px;

            .team-logo {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

            .team-name {
              font-size: 14px;
              color: $scoreBaseball-schedule-match-list-text-dark;
            }
          }
        }
      }
      .scores {
        display: flex;
        flex-direction: column;
        width: 300px;

        .score-row {
          display: flex;

          span {
            width: 30px;
            text-align: center;
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $scoreBaseball-schedule-match-list-text-dark;
          }
        }
      }

      .total-score {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $scoreBaseball-schedule-match-list-border-light;
        height: 100%;

        p {
          font-size: 14px;
          font-weight: bold;
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 800;
          font-size: 14px;
          color: $scoreBaseball-schedule-match-list-text-red;
        }
      }

      .total-points {
        width: 50px;
        text-align: center;
        font-size: 12px;
        color: $scoreBaseball-schedule-match-list-text-medium;
        flex-direction: column;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
        p {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          span {
            width: 10px;
            // margin-right: 10px;
          }
        }
      }

      .live-icon,
      .winloss,
      .handicap,
      .over-under,
      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .over-under {
        p > span:first-child {
          margin-right: 10px;
        }
      }
      .live-icon {
        flex-direction: column;
      }
      .live-icon {
        width: 50px;
        border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
        height: 100%;
        img {
          width: 20px;
        }
      }
      .winloss {
        width: 60px;
        flex-direction: column;
        height: 100%;
        border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
        span {
          font-size: 12px;
          color: $scoreBaseball-schedule-match-list-text-dark;
        }
      }
      .handicap {
        width: 100px;
        height: 100%;
        font-size: 12px;
        // color: #666;
        border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $scoreBaseball-schedule-match-list-text-dark;
      }
      .over-under {
        width: 130px;
        height: 100%;
        font-size: 12px;
        // color: #666;
        border-right: 1px solid $scoreBaseball-schedule-match-list-border-light;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $scoreBaseball-schedule-match-list-text-dark;
      }
      .actions {
        width: 60px;
        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
