<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="wrapper">
        <div class="score-container">
          <div class="top">
            <div class="category-list">
              <button
                class="button"
                :class="{ active: activeButton === '0' }"
                @click="setActive('0')"
              >
                即时
              </button>
              <button
                class="button"
                :class="{ active: activeButton === '3' }"
                @click="setActive('3')"
              >
                赛果
              </button>
              <button
                class="button"
                :class="{ active: activeButton === '1' }"
                @click="setActive('1')"
              >
                赛程
              </button>
              <button
                class="button"
                :class="{ active: activeButton === '2' }"
                @click="setActive('2')"
              >
                收藏
              </button>
            </div>
            <ul class="actions_list">
              <li class="action_item">
                <span>赛事</span>
                <img src="@assets/icons/score/more.webp" alt="下拉箭头" class="down-arrow" />
              </li>
              <li class="action_other">
                <span :class="'active'">专业版</span>
              </li>

              <li class="action_item">
                <span>默认</span>
                <img src="@assets/icons/score/music.webp" alt="下拉箭头" class="down-arrow" />
              </li>
              <li class="action_item">
                <span>设置</span>
                <img src="@assets/icons/score/setting.webp" alt="设置" class="settings-icon" />
              </li>
            </ul>
          </div>

          <TabNavigation :menusList="menusList" @categorySelect="categorySelect" />
        </div>
        <div class="list">
          <div class="list-scroll" v-if="plainMatches.length > 0">
            <MatchList
              :list="plainMatches"
              :hasReset="hasReset"
              :selectedMatch="matchSelect.match"
              @selectMatch="selectMatch"
            />
          </div>
          <div class="match-detail" v-if="plainMatches.length > 0">
            <MatchDetails :match="matchSelect.match" />
          </div>
          <div v-if="loading && plainMatches.length === 0" class="loading-container">
            <SkeletonLoader type="match-list" :count="5" />
          </div>
          <div v-if="!loading && plainMatches.length === 0" style="width: 100%; height: 100%">
            <div class="empty_list">
              <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TabNavigation from './TabNavigation.vue'
import MatchList from './MatchList.vue'
import MatchDetails from './MatchDetails.vue'
// import LoadingSpinner from '@/components/loading.vue'
import SkeletonLoader from '@/components/SkeletonLoader.vue'
// import { ref } from 'vue'
import { onMounted, ref, watch, computed, onUnmounted, reactive } from 'vue'
import { getFootballMatchCategory } from '@/api/match'

import { getMatchFootballList } from '@/api/match'
import { fromJS, Map } from 'immutable'
import { getDQToken } from '@/api/user'

import dayjs from 'dayjs'
import pako from 'pako'
import axios from 'axios'
import { message } from 'ant-design-vue'
import { decompressData } from '@/utils/dataProcessing'

const menusList = ref([])
const hasReset = ref(false)
const loading = ref(true)
const activeButton = ref('0')

// 将match选择状态集中管理
const matchSelect = reactive({
  match: {},
  selectedMatchId: null
})

// 将列表数据和状态集中管理
const listState = reactive({
  list: fromJS([]),
  categoryId: null,
  state: null,
  init: false
})

// 计算属性获取plain数组
const plainMatches = computed(() => listState.list.toJS())

// 处理match选择
const selectMatch = (match) => {
  // 添加防御性检查
  if (!match) return

  matchSelect.match = match
  matchSelect.selectedMatchId = match.match_id
}

// 添加轮询间隔常量
const POLLING_INTERVAL = 30000 // 30秒轮询一次

// 轮询控制
const polling = ref(null)

// 获取列表数据
const fetchMatchList = async () => {
  if (plainMatches.value.length === 0) {
    loading.value = true
  }

  try {
    const params = {
      leagueId: listState.categoryId || null,
      state: listState.state || null
    }
    const res = await getMatchFootballList(params)

    if (res.code === 200) {
      const data = decompressData(res.data)
      if (data?.length) {
        listState.list = fromJS(data)
        return true
      }
    }
  } catch (error) {
    console.error('获取比赛列表失败:', error)
  } finally {
    loading.value = false
  }
  return false
}

// 处理match选中状态 - 优化逻辑
const handleMatchSelection = () => {
  if (!plainMatches.value.length) {
    resetMatchSelect()
    return
  }

  if (matchSelect.selectedMatchId) {
    // 在新数据中查找当前选中的match
    const selectedMatch = plainMatches.value.find(
      (match) => match.match_id === matchSelect.selectedMatchId
    )
    if (selectedMatch) {
      // 更新选中match的数据
      matchSelect.match = selectedMatch
    } else {
      // 如果找不到之前选中的match,选择第一个
      selectMatch(plainMatches.value[0])
    }
  } else {
    // 没有选中项时,选择第一个
    selectMatch(plainMatches.value[0])
  }
}

// 触发列表重置动画
const triggerListReset = () => {
  hasReset.value = true
  setTimeout(() => {
    hasReset.value = false
  }, 100)
}

// 启动轮询
const startPolling = () => {
  stopPolling() // 确保先停止已存在的轮询
  polling.value = setInterval(() => {
    getLists()
  }, POLLING_INTERVAL)
}

// 停止轮询
const stopPolling = () => {
  if (polling.value) {
    clearInterval(polling.value)
    polling.value = null
  }
}

// 处理tab切换
const setActive = (button) => {
  if (button === '2') {
    message.success('当前无收藏的比赛')
    return
  }
  activeButton.value = button
  listState.state = parseInt(button)
  resetMatchSelect() // 切换tab时重置选中状态
  getLists()
  startPolling() // 切换tab时重启轮询
}

// 处理分类选择
const categorySelect = (tab) => {
  listState.categoryId = tab.leagueIds
  resetMatchSelect()
  getLists()
  startPolling() // 切换分类时重启轮询
}

// 重置match选择状态 - 简化逻辑
const resetMatchSelect = () => {
  matchSelect.selectedMatchId = null
  matchSelect.match = {}
}

// 优化获取列表并处理选中状态的逻辑
const getLists = async () => {
  const success = await fetchMatchList()
  if (success) {
    handleMatchSelection()
    triggerListReset()
  } else {
    resetMatchSelect()
  }
}

onMounted(() => {
  getFootballCetegoryLists()
  startPolling()
  getLists()
})

const getFootballCetegoryLists = () => {
  getFootballMatchCategory()
    .then((res) => {
      console.log(res)
      menusList.value = res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

// 组件卸载时清理轮询
onUnmounted(() => {
  stopPolling()
})
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  background: $scoreFootball-page-bg;
}

.score-container {
  position: sticky;
  top: 70px;
  z-index: 10;

  background: $scoreFootball-page-bg;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions_list {
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: center;
      padding: 0;
      margin: 0;
      width: 466px;

      .action_item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: $scoreFootball-text-secondary;
        width: 72px;
        height: 30px;
        background: $scoreFootball-white;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid $scoreFootball-border-light;
        margin-right: 10px;

        span {
          margin-right: 4px;
        }
      }

      .action_other {
        width: 70px;
        height: 32px;
        background: $scoreFootball-white;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid $scoreFootball-border-light;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;
        padding: 4px 2px;

        span {
          width: 70px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
        }

        .active {
          background: $scoreFootball-success;
          font-weight: bold;
          color: $scoreFootball-white;
          border-radius: 20px 20px 20px 20px;
        }
      }

      .down-arrow,
      .settings-icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  .category-list {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.button {
  width: 64px;
  /* 根据需要调整按钮大小 */
  height: 36px;
  /* 根据需要调整按钮大小 */
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background: url('@assets/icons/score_status_btn.webp') no-repeat;
  background-size: 100% 100%;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  color: $scoreFootball-text-primary;
  box-shadow: 0px 0px 10px $scoreFootball-shadow-default;
  border-radius: 20px 20px 20px 20px;
}

.button.active {
  background: url('@assets/icons/score_status_btn_active.webp') no-repeat;
  box-shadow: 0px 0px 10px $scoreFootball-shadow-success;
  background-size: 100% 100%;
  color: $scoreFootball-white;
  /* 在需要时添加额外的选中样式 */
}

.outer-container {
  width: 100%;
  background: $scoreFootball-page-bg;
  box-sizing: border-box;
  min-height: 100vh;
}

.inner-container {
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;

  background: $scoreFootball-page-bg;
}

/* 最小宽度限制 */
@media (max-width: 1400px) {
  .inner-container {
    min-width: 1200px;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  position: relative;

  .list-scroll {
    height: 100%;
    padding-right: 10px;
  }
}

.empty_list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 100%;
  background: $scoreFootball-white;
  color: $scoreFootball-text-muted;

  .empty-image {
    width: 300px;
  }
}

.match-detail {
  // position: sticky;
  // top: 200px;
  width: 375px;
  z-index: 1;
  border-radius: 10px;
  // overflow: hidden;
  // z-index: 10;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
}
</style>
