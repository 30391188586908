<template>
  <div class="match-list">
    <!-- 标题行 -->
    <div class="header">
      <div class="header-item header-time-league">时间/联赛</div>
      <div class="header-item header-progress">状态</div>
      <div class="header-item teams">比赛对阵</div>
      <div class="header-item full-score">全场</div>
      <div class="header-item half-score">半场</div>
      <div class="header-item corner">角球</div>
      <div class="header-item betting-info">指数</div>
      <div class="header-item icons">直播</div>
      <div class="header-item favorite">操作</div>
    </div>
    <!-- 比赛列表 -->
    <div class="list">
      <template v-for="(group, groupIndex) in groupedMatches" :key="groupIndex">
        <template v-if="group.matches.length > 0">
          <div class="group-header">{{ getGroupTitle(group.status) }}</div>
          <div
            v-for="match in group.matches"
            :key="match.match_id"
            class="match-item"
            :class="isSelected(match) && 'active'"
            @click="() => selectMatch(match)"
          >
            <div class="time-league">
              <div class="select" @click.stop="handleSelect">
                <img src="@assets/icons/score/select.webp" class="active" loading="lazy" />
                <img src="@assets/icons/score/selected.webp" class="default" loading="lazy" />
              </div>
              <div class="league-container">
                <div class="time">{{ formatTime(match.matchTime) }}</div>
                <div class="league">{{ match.title }}</div>
              </div>
            </div>
            <div class="progress" :class="{ 'progress-red': match.status === 2 }">
              <span>{{ match.match_status_txt }}</span>
              <img v-if="match.status === 2" src="@assets/icons/score/minite.gif" loading="lazy" />
            </div>
            <div class="teams">
              <div class="team">
                <span class="team-name">{{ match.home_team_name }}</span>
                <span class="cards">
                  <span v-if="match?.corner?.[2] > 0" class="yellow-card">
                    {{ match.corner[2] }}
                  </span>
                  <span v-if="match?.corner?.[4] > 0" class="red-card">
                    {{ match.corner[4] }}
                  </span>
                </span>
              </div>
              <div class="team">
                <span class="team-name">{{ match.away_team_name }}</span>
                <span class="cards">
                  <span v-if="match?.corner?.[3] > 0" class="yellow-card">
                    {{ match.corner[3] }}
                  </span>
                  <span v-if="match?.corner?.[5] > 0" class="red-card">
                    {{ match.corner[5] }}
                  </span>
                </span>
              </div>
            </div>
            <div
              class="full-score item-full-score"
              :class="{ 'item-full-score-not': match.status === 1 }"
            >
              <div>{{ statusMatchScoreText(match.status, match?.home_grades) }}</div>
              <div>{{ statusMatchScoreText(match.status, match?.away_grades) }}</div>
            </div>
            <div class="half-score">
              <div>{{ statusMatchScoreText(match.status, match?.half_score[0]) }}</div>
              <div>{{ statusMatchScoreText(match.status, match?.half_score[1]) }}</div>
            </div>
            <div class="corner">
              <div>{{ statusMatchScoreText(match.status, match?.corner[0]) }}</div>
              <div>{{ statusMatchScoreText(match.status, match?.corner[1]) }}</div>
            </div>
            <div class="betting-info">
              <div class="index">
                <span v-for="(item, index) in match.w_1" :key="index">{{ item }}</span>
              </div>
              <div class="odds">
                <span
                  v-for="(item, index) in match.w_2"
                  :key="index"
                  :class="{
                    up: match.odds1_change === 'up',
                    down: match.odds1_change === 'down'
                  }"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="icons">
              <img
                v-if="hasAnimationIcon(match)"
                src="@assets/icons/score/animation-active.webp"
                loading="lazy"
              />
              <img
                v-if="hasVideoIcon(match)"
                src="@assets/icons/score/video-active.webp"
                loading="lazy"
              />
            </div>
            <div class="favorite">
              <img src="@assets/icons/score/collect.webp" alt="收藏" loading="lazy" />
            </div>
          </div>
        </template>
      </template>
    </div>
    <Legend />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import dayjs from 'dayjs'
import Legend from './Legend.vue'
import { scoreRightStore } from '@/stores/useScoreRight'

const useScoreRightStore = scoreRightStore()

const props = defineProps({
  list: Array,
  hasReset: Boolean,
  onSelectMatch: Function,
  selectedMatch: Object
})

// 预加载状态图标
const preloadStatusIcons = () => {
  const icons = [
    'minite.gif',
    'select.webp',
    'selected.webp',
    'animation-active.webp',
    'video-active.webp',
    'collect.webp'
  ]
  icons.forEach((icon) => {
    const img = new Image()
    img.src = new URL(`@assets/icons/score/${icon}`, import.meta.url).href
  })
}

// 分组比赛数据
const groupedMatches = computed(() => groupMatchesByStatus(props.list))

const getGroupTitle = (status) => {
  switch (status) {
    case 1:
      return '未开始'
    case 2:
      return '进行中'
    case 3:
      return '已结束'
    default:
      return ''
  }
}

const isSelected = (match) => {
  return match?.match_id === props.selectedMatch?.match_id
}

const selectMatch = (match) => {
  props.onSelectMatch(match)
  useScoreRightStore.getScoreItemData(match)
}

const handleSelect = () => {
  // 处理选择逻辑
}

const formatTime = (num) => {
  return dayjs(num).format('HH:mm')
}

const statusMatchScoreText = (statusIndex, score) => {
  switch (statusIndex) {
    case 1:
      return '-'
    case 2:
    case 3:
      return score
    default:
      return '-'
  }
}

const hasAnimationIcon = (match) => {
  return match.has_vid !== 1 && match.has_anim === 1
}

const hasVideoIcon = (match) => {
  return match.has_vid === 1
}

// 分组函数
const groupMatchesByStatus = (matches = []) => {
  const groups = {
    inProgress: { status: 2, matches: [] },
    notStarted: { status: 1, matches: [] },
    finished: { status: 3, matches: [] }
  }

  if (!matches) return Object.values(groups)

  matches.forEach((match) => {
    if (match.status === 2) {
      groups.inProgress.matches.push(match)
    } else if (match.status === 1) {
      groups.notStarted.matches.push(match)
    } else if (match.status === 3) {
      groups.finished.matches.push(match)
    }
  })

  return Object.values(groups).filter((group) => group.matches.length > 0)
}

onMounted(() => {
  preloadStatusIcons()
})
</script>
<style scoped lang="scss">
.match-list {
  width: 815px;
  border-radius: 10px;
  background-color: $scoreFootball-matchList-white;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.header {
  display: flex;
  height: 53px;
  font-weight: 400;
  font-size: 14px;
  color: $scoreFootball-matchList-text-primary;
  align-items: center;
  background-color: $scoreFootball-matchList-white;
  position: sticky;
  top: 200px;
  z-index: 10;
  border-bottom: 1px solid $scoreFootball-matchList-border;
}

.header-item {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: $scoreFootball-matchList-text-primary;
}

.header-item.header-time-league {
  flex: 1.5;
}

.header-item.progress {
  flex: 0.5;
}

.header-item.teams {
  flex: 2;
}

.header-item.full-score,
.header-item.half-score,
.header-item.corner {
  flex: 0.4;
}

.header-item.betting-info {
  flex: 2;
}

.header-item.icons,
.header-item.favorite {
  flex: 0.5;
}

.header-progress {
  flex: 0.5;
}

.list {
  min-height: 100vh;
}

.match-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $scoreFootball-matchList-border;
  position: relative;
  height: 46px;
  font-size: 12px;
  background: $scoreFootball-matchList-white;
  cursor: pointer;

  &:nth-child(2n) {
    background: $scoreFootball-matchList-bg-alternate;
  }

  &:hover {
    border: 1px solid $scoreFootball-matchList-active-border;
  }

  &.active {
    background: $scoreFootball-matchList-active-bg;
    border: 1px solid $scoreFootball-matchList-active-border;

    &::after {
      content: '';
      width: 9px;
      height: 9px;
      border-top: 1px solid $scoreFootball-matchList-active-border;
      border-right: 1px solid $scoreFootball-matchList-active-border;
      position: absolute;
      right: -5px;
      top: 42%;
      transform: rotate(45deg);
      background-color: $scoreFootball-matchList-active-bg;
    }
  }
}

.time-league {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 50px;
    width: 4px;
    height: 32px;
    background: $scoreFootball-matchList-progress-bar;
    border-radius: 2px 2px 2px 2px;
    top: 50%;
    margin-top: -16px;
  }

  .select {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -9px;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
    }

    .default {
      display: none;
    }

    .active {
      display: inline;
    }
  }
}

.league-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.time {
  color: $scoreFootball-matchList-text-secondary;
}

.league {
  color: $scoreFootball-matchList-text-secondary;
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

.progress {
  flex: 0.5;
  color: $scoreFootball-matchList-text-light;

  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    width: 2px;
  }
}

.progress-red {
  color: $scoreFootball-matchList-text-danger;
}

.teams {
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
}

.team {
  display: flex;
  align-items: center;
}

.team-name {
  text-align: left;
  padding-left: 25px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 170px;
  color: $scoreFootball-matchList-text-dark;

  .home_icon {
    position: absolute;
    left: 0px;
    padding: 0px 4px;
    color: $scoreFootball-matchList-white;
    background: $scoreFootball-matchList-active-border;
    border-radius: 20px;
    font-size: 10px;
    font-style: normal;
  }
}

.cards {
  position: absolute;
  right: 10px;
  display: flex;
  gap: 5px;
}

.yellow-card {
  color: $scoreFootball-matchList-white;
  width: 15px;
  height: 15px;
  background: $scoreFootball-matchList-yellow-card;
  border-radius: 2px 2px 2px 2px;
  text-align: center;
  line-height: 15px;
}

.red-card {
  color: $scoreFootball-matchList-white;
  background-color: $scoreFootball-matchList-red-card;
  width: 15px;
  height: 15px;
  border-radius: 2px 2px 2px 2px;
  text-align: center;
  line-height: 15px;
}

.full-score,
.half-score,
.corner {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-full-score {
  color: $scoreFootball-matchList-text-danger;
  font-weight: bold;
  font-size: 14px;
}

.item-full-score-not {
  color: $scoreFootball-matchList-text-dark;
  font-weight: bold;
  font-size: 14px;
}

.betting-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.index,
.odds {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.index span,
.odds span {
  color: $scoreFootball-matchList-text-secondary;
}

.odds span.up {
  color: $scoreFootball-matchList-text-danger;
}

.odds span.down {
  color: $scoreFootball-matchList-active-border;
}

.icons {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .avatar {
    border-radius: 50%;
  }
}

.icons img {
  width: 20px;
  height: 20px;
  margin: 0 2px;
  cursor: pointer;
}

.favorite {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite img {
  width: 20px;
  height: 20px;
}

.group-header {
  padding: 15px 15px;
  background-color: $scoreFootball-matchList-white;
  text-align: center;
  border-bottom: 1px solid $scoreFootball-matchList-border;
  color: $scoreFootball-matchList-text-dark;
  font-size: 12px;
}
</style>
