

type EventLegendIcon = {
    [key: string]: {
        key: number;
        name: string;
        src: string;
    };
};


export const occurTime = (time: number) => {
    return Math.round(time / 60) + '\''
}

export const eventLegendIcon: EventLegendIcon = {
    '进球': {key: 9, name: '进球', src: '/src/assets/icons/score/goal.webp'},
    '点球': {key: 0, name: '点球', src: '/src/assets/icons/score/penalty.webp'},
    '点球不进': {key: 0, name: '点球不进', src: '/src/assets/icons/score/penalty_miss.webp'},
    '比赛开始': {key: 1, name: '比赛开始', src: '/src/assets/icons/score/whistle.webp'},
    '默认': {key: 34, name: '默认', src: '/src/assets/icons/score/default_score.webp'},
    '乌龙球': {key: 0, name: '乌龙球', src: '/src/assets/icons/score/own_goal.webp'},
    '角球': {key: 30, name: '角球', src: '/src/assets/icons/score/corner.webp'},
    '助攻': {key: 0, name: '助攻', src: '/src/assets/icons/score/assist.webp'},
    '黄牌': {key: 18, name: '黄牌', src: '/src/assets/icons/score/yellow_card.webp'},
    '红牌': {key: 0, name: '红牌', src: '/src/assets/icons/score/red_card.webp'},
    '两黄一红': {key: 21, name: '两黄一红', src: '/src/assets/icons/score/yellow_red_card.webp'},
    '换人': {key: 23, name: '换人', src: '/src/assets/icons/score/substitution.webp'},
    '受伤': {key: 0, name: '受伤', src: '/src/assets/icons/score/injury.webp'},
    'VAR': {key: 0, name: 'VAR', src: '/src/assets/icons/score/var.webp'},
}


export const mockData_event = [
    {
        "eventId": 3,
        "team": 0,
        "iconType": "",
        "typeId": 3,
        "goalType": 0,
        "playerName": "",
        "playerName2": "",
        "content": "比赛结束",
        "content2": "",
        "occurTime": 9999,
        "overTime": 0,
        "confirmResult": null,
        "substitute": null,
        "scores": null,
        "stage": 8
    },
    {
        "eventId": 72347852,
        "team": 2,
        "iconType": "card",
        "typeId": 18,
        "goalType": null,
        "playerName": "普列文斯巴达",
        "playerName2": "",
        "content": "黄牌，全场第5张",
        "content2": "",
        "occurTime": 5340,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 72347628,
        "team": 1,
        "iconType": "card",
        "typeId": 18,
        "goalType": null,
        "playerName": "普罗夫迪夫博特夫二队",
        "playerName2": "",
        "content": "黄牌，全场第4张",
        "content2": "",
        "occurTime": 4500,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 72347525,
        "team": 2,
        "iconType": "card",
        "typeId": 18,
        "goalType": null,
        "playerName": "普列文斯巴达",
        "playerName2": "",
        "content": "黄牌，全场第3张",
        "content2": "",
        "occurTime": 4380,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 72347520,
        "team": 1,
        "iconType": "corner",
        "typeId": 30,
        "goalType": null,
        "playerName": "普罗夫迪夫博特夫二队",
        "playerName2": "",
        "content": "第4个角球",
        "content2": "全场4-0",
        "occurTime": 4320,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 72347285,
        "team": 1,
        "iconType": "corner",
        "typeId": 30,
        "goalType": null,
        "playerName": "普罗夫迪夫博特夫二队",
        "playerName2": "",
        "content": "第3个角球",
        "content2": "全场3-0",
        "occurTime": 3720,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 72347273,
        "team": 2,
        "iconType": "card",
        "typeId": 18,
        "goalType": null,
        "playerName": "普列文斯巴达",
        "playerName2": "",
        "content": "黄牌，全场第2张",
        "content2": "",
        "occurTime": 3600,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 72347255,
        "team": 1,
        "iconType": "corner",
        "typeId": 30,
        "goalType": null,
        "playerName": "普罗夫迪夫博特夫二队",
        "playerName2": "",
        "content": "第2个角球",
        "content2": "全场2-0",
        "occurTime": 3480,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 3
    },
    {
        "eventId": 13,
        "team": 0,
        "iconType": "",
        "typeId": 13,
        "goalType": 0,
        "playerName": "",
        "playerName2": "",
        "content": "中场休息",
        "content2": "",
        "occurTime": 2700,
        "overTime": 0,
        "confirmResult": null,
        "substitute": null,
        "scores": null,
        "stage": 2
    },
    {
        "eventId": 72345504,
        "team": 2,
        "iconType": "card",
        "typeId": 18,
        "goalType": null,
        "playerName": "普列文斯巴达",
        "playerName2": "",
        "content": "黄牌，全场第1张",
        "content2": "",
        "occurTime": 2160,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 1
    },
    {
        "eventId": 72345497,
        "team": 2,
        "iconType": "goal",
        "typeId": 9,
        "goalType": 1,
        "playerName": "普列文斯巴达",
        "playerName2": "",
        "content": "进球",
        "content2": "0-1",
        "occurTime": 2040,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:1",
        "stage": 1
    },
    {
        "eventId": 72345200,
        "team": 1,
        "iconType": "corner",
        "typeId": 30,
        "goalType": null,
        "playerName": "普罗夫迪夫博特夫二队",
        "playerName2": "",
        "content": "第1个角球",
        "content2": "全场1-0",
        "occurTime": 1020,
        "overTime": 0,
        "confirmResult": null,
        "substitute": 0,
        "scores": "0:0",
        "stage": 1
    },
    {
        "eventId": 1,
        "team": 0,
        "iconType": "",
        "typeId": 1,
        "goalType": 0,
        "playerName": "",
        "playerName2": "",
        "content": "比赛开始",
        "content2": "",
        "occurTime": 1,
        "overTime": 0,
        "confirmResult": null,
        "substitute": null,
        "scores": null,
        "stage": 0
    },
    {
        "eventId": 0,
        "team": 0,
        "iconType": "default",
        "typeId": 0,
        "goalType": 0,
        "playerName": "",
        "playerName2": "",
        "content": "比赛在赫里斯托·博特夫体育场, 主场相距142km进行",
        "content2": "",
        "occurTime": 0,
        "overTime": 0,
        "confirmResult": null,
        "substitute": null,
        "scores": null,
        "stage": 0
    }
]

export const mockData = {
    "id": 10685494,
    "sportId": 1,
    "matchTimeTimestamp": "2024-11-05 20:30:00",
    "hostTeamId": 188457,
    "guestTeamId": 5694,
    "hostTeamName": "普罗夫迪夫博特夫二队",
    "guestTeamName": "普列文斯巴达",
    "hostTeamScore": 0,
    "guestTeamScore": 1,
    "hostTeamLogo": "https://sta-prod-score01-new.qaffvz.com/teams/20241029112342379_150x150.png",
    "guestTeamLogo": "https://sta-prod-score01-new.qaffvz.com/teams/20241005083507985_150x150.png",
    "winner": 0,
    "refereeId": null,
    "refereeName": null,
    "stadiumId": 3493,
    "stadiumName": "赫里斯托·博特夫体育场",
    "hasLineup": 1,
    "hostFormation": "",
    "guestFormation": null,
    "matchTime": 1730809800000,
    "hostCoachId": 25601,
    "hostCoachName": "多米尼克伯斯尼亚克",
    "hostCoachPic": "https://sta-prod-score01-new.qaffvz.com/managers/20240812075940575_150x150.png",
    "hostMarketValue": "--",
    "hostFormationHistoryWinNum": 0,
    "hostFormationHistoryDrawNum": 0,
    "hostFormationHistoryLoseNum": 0,
    "hostTeamMatchLineupList": [
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 132443,
            "playerName": "丹尼尔凯泽",
            "position": 0,
            "shirtNumber": "1",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241031062045332_150x150.png",
            "playerEnName": "D. Kajzer",
            "positionOften": "门将",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": "32岁",
            "height": "189cm",
            "weight": "83kg",
            "marketValue": "97000",
            "nationality": "波兰",
            "birthdate": "1992-02-23",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20180906/20180906093407999_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 993854,
            "playerName": "伊瓦伊洛·维德夫",
            "position": 0,
            "shirtNumber": "3",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241031062031938_150x150.png",
            "playerEnName": "I. Videv",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "171cm",
            "weight": null,
            "marketValue": "54000",
            "nationality": "保加利亚",
            "birthdate": "2006-01-17",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 984850,
            "playerName": "加布里埃尔·兹拉塔诺夫",
            "position": 0,
            "shirtNumber": "20",
            "picUrl": null,
            "playerEnName": "G. Zlatanov",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2005-03-24",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 414634,
            "playerName": "安德烈约尔丹诺夫",
            "position": 0,
            "shirtNumber": "7",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241031062028316_150x150.png",
            "playerEnName": "A. Yordanov",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": "23岁",
            "height": "170cm",
            "weight": null,
            "marketValue": "425000",
            "nationality": "保加利亚",
            "birthdate": "2001-09-06",
            "ability1": 42,
            "ability2": 43,
            "ability3": 48,
            "ability4": 31,
            "ability5": 58,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 171386,
            "playerName": "贾斯-海尔特姆",
            "position": 0,
            "shirtNumber": "2",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20240916114013820_150x150.png",
            "playerEnName": "J. V. Heertum",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": "27岁",
            "height": "191cm",
            "weight": null,
            "marketValue": "265000",
            "nationality": "比利时",
            "birthdate": "1997-11-10",
            "ability1": 38,
            "ability2": 40,
            "ability3": 64,
            "ability4": 53,
            "ability5": 48,
            "countryLogo": "_file/team/20180903/20180903152639707_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 900105,
            "playerName": "埃德加-皮洛扬",
            "position": 0,
            "shirtNumber": "8",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241010125741213_150x150.png",
            "playerEnName": "E. Piloyan",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "179cm",
            "weight": null,
            "marketValue": null,
            "nationality": "亚美尼亚",
            "birthdate": "2004-11-07",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831164725030_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 679702,
            "playerName": "比泽博涅夫",
            "position": 0,
            "shirtNumber": "10",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20240916114008309_150x150.png",
            "playerEnName": "B. Bonev",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "180cm",
            "weight": null,
            "marketValue": "160000",
            "nationality": "保加利亚",
            "birthdate": "2003-06-04",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 981740,
            "playerName": "马丁·西特夫",
            "position": 0,
            "shirtNumber": "13",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20240714202703453_150x150.png",
            "playerEnName": "M. Sitev",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "172cm",
            "weight": null,
            "marketValue": "49000",
            "nationality": "保加利亚",
            "birthdate": "2006-09-06",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 984848,
            "playerName": "迪米塔尔·迪克列夫",
            "position": 0,
            "shirtNumber": "15",
            "picUrl": null,
            "playerEnName": "D. Dikliev",
            "positionOften": "前锋",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "175cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2006-05-04",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 949332,
            "playerName": "鲍里斯拉夫·鲁梅诺夫·马里诺夫",
            "position": 0,
            "shirtNumber": "11",
            "picUrl": null,
            "playerEnName": "B. R. Marinov",
            "positionOften": "前锋",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 949339,
            "playerName": "康斯坦丁·亚沃罗夫·巴甫洛夫",
            "position": 0,
            "shirtNumber": "9",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 981735,
            "playerName": "科斯塔丁·塔塔罗夫",
            "position": 0,
            "shirtNumber": "5",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20240915023657367_150x150.png",
            "playerEnName": "K. Tatarov",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "162cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2005-01-18",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 982324,
            "playerName": "达米安·约尔丹诺夫",
            "position": 0,
            "shirtNumber": "14",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241011045347276_150x150.png",
            "playerEnName": "D. Yordanov",
            "positionOften": "前锋",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": "52000",
            "nationality": "保加利亚",
            "birthdate": "2005-01-01",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1275543,
            "playerName": "亚历山大·卡尔塔洛夫",
            "position": 0,
            "shirtNumber": "6",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1228999,
            "playerName": "内希米·金图图",
            "position": 0,
            "shirtNumber": "29",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241020045409428_150x150.png",
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1200942,
            "playerName": "安东尼·尼科洛夫",
            "position": 0,
            "shirtNumber": "21",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1255518,
            "playerName": "斯维托斯拉夫·托多罗夫",
            "position": 0,
            "shirtNumber": "18",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1056589,
            "playerName": "格奥尔基·库什纳罗夫·伊万诺夫",
            "position": 0,
            "shirtNumber": "4",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1258628,
            "playerName": "马丁·格奥尔基耶夫",
            "position": 0,
            "shirtNumber": "19",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 188457,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1046765,
            "playerName": "马克西米利安·西蒙诺夫",
            "position": 0,
            "shirtNumber": "17",
            "picUrl": null,
            "playerEnName": "M. Simeonov",
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2007-01-01",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        }
    ],
    "hostEventList": [],
    "guestCoachId": 410044,
    "guestCoachName": "Tsvetkov, Boyko",
    "guestCoachPic": null,
    "guestMarketValue": "--",
    "guestTeamMatchLineupList": [
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 530999,
            "playerName": "丹尼尔斯托亚诺夫",
            "position": 0,
            "shirtNumber": "26",
            "picUrl": null,
            "playerEnName": "D. Stoyanov",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2001-01-27",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 917510,
            "playerName": "伊沃·瓦尔巴诺夫",
            "position": 0,
            "shirtNumber": "5",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241030172816839_150x150.png",
            "playerEnName": "I. Varbanov",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "192cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "1987-03-01",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 239471,
            "playerName": "季米塔卡尔切夫",
            "position": 0,
            "shirtNumber": "21",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20240904000939964_150x150.png",
            "playerEnName": "D. Kalchev",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "170cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 1119844,
            "playerName": "普雷斯拉夫·格奥尔基耶夫",
            "position": 0,
            "shirtNumber": "22",
            "picUrl": null,
            "playerEnName": "P. Georgiev",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2005-10-17",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 92841,
            "playerName": "瓦西里肖波夫",
            "position": 0,
            "shirtNumber": "10",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20240601014722996_150x150.png",
            "playerEnName": "V. Shopov",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": "33岁",
            "height": "185cm",
            "weight": null,
            "marketValue": "210000",
            "nationality": "保加利亚",
            "birthdate": "1991-11-09",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 480116,
            "playerName": "维克托伊万诺夫",
            "position": 0,
            "shirtNumber": "6",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241030172725409_150x150.png",
            "playerEnName": "V. Ivanov",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "180cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "1997-03-02",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 199903,
            "playerName": "谢尔纳科夫",
            "position": 0,
            "shirtNumber": "8",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241030172719256_150x150.png",
            "playerEnName": "R. Chernakov",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": "25岁",
            "height": "175cm",
            "weight": null,
            "marketValue": "110000",
            "nationality": "保加利亚",
            "birthdate": "1999-01-21",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 43959,
            "playerName": "雅尼-佩利瓦诺夫",
            "position": 0,
            "shirtNumber": "19",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241007001014464_150x150.png",
            "playerEnName": "Y. Pehlivanov",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": "36岁",
            "height": "182cm",
            "weight": "79kg",
            "marketValue": "23000",
            "nationality": "保加利亚",
            "birthdate": "1988-07-14",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 1087714,
            "playerName": "克里斯蒂安·佩特科夫",
            "position": 0,
            "shirtNumber": "11",
            "picUrl": null,
            "playerEnName": "H. Petkov",
            "positionOften": "前锋",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "184cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2003-05-21",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 144624,
            "playerName": "普雷斯拉夫安东诺夫",
            "position": 0,
            "shirtNumber": "24",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241030172629431_150x150.png",
            "playerEnName": "P. Antonov",
            "positionOften": "前锋",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "185cm",
            "weight": null,
            "marketValue": "96000",
            "nationality": "保加利亚",
            "birthdate": "1996-10-02",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 0,
            "injury": 0,
            "reason": null,
            "playerId": 1214203,
            "playerName": "博伊安·迪什科夫",
            "position": 0,
            "shirtNumber": "12",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 718769,
            "playerName": "伊万巴卡尔斯基",
            "position": 0,
            "shirtNumber": "16",
            "picUrl": null,
            "playerEnName": "I. Bakalski",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2003-01-29",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1219798,
            "playerName": "康斯坦丁·格拉赫廖夫",
            "position": 0,
            "shirtNumber": "2",
            "picUrl": null,
            "playerEnName": "K. Grahlyov",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1059724,
            "playerName": "通乔·格奥尔基耶夫",
            "position": 0,
            "shirtNumber": "20",
            "picUrl": null,
            "playerEnName": "T. Georgiev",
            "positionOften": "后卫",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": "165cm",
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2004-08-06",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 934197,
            "playerName": "莫尼尔·巴达林",
            "position": 0,
            "shirtNumber": "7",
            "picUrl": "https://sta-prod-score01-new.qaffvz.com/players/20241030172655183_150x150.png",
            "playerEnName": "M. A. Badarin",
            "positionOften": "中场",
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": "保加利亚",
            "birthdate": "2005-07-08",
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": "_file/team/20160831/20160831170212718_90x90.png"
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1206601,
            "playerName": "伊维林·尼古拉耶夫",
            "position": 0,
            "shirtNumber": "9",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1012584,
            "playerName": "斯拉维克·佩特科夫",
            "position": 0,
            "shirtNumber": "66",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1241367,
            "playerName": "瓦伦丁·内迪亚科夫",
            "position": 0,
            "shirtNumber": "33",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1206602,
            "playerName": "米歇尔·泰罗夫",
            "position": 0,
            "shirtNumber": "18",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        },
        {
            "matchId": 10685494,
            "teamId": 5694,
            "substitute": 1,
            "injury": 0,
            "reason": null,
            "playerId": 1090514,
            "playerName": "迪米塔尔·斯特凡诺夫",
            "position": 0,
            "shirtNumber": "30",
            "picUrl": null,
            "playerEnName": null,
            "positionOften": null,
            "playTime": "",
            "events": "0^0^0^0^0^0^0^0^0^0^0",
            "redTimeList": [],
            "yellowTimeList": [],
            "goalTimeList": [],
            "assistTimeList": [],
            "upTimeList": [],
            "downTimeList": [],
            "rating": null,
            "age": null,
            "height": null,
            "weight": null,
            "marketValue": null,
            "nationality": null,
            "birthdate": null,
            "ability1": null,
            "ability2": null,
            "ability3": null,
            "ability4": null,
            "ability5": null,
            "countryLogo": null
        }
    ],
    "guestFormationHistoryWinNum": 0,
    "guestFormationHistoryDrawNum": 0,
    "guestFormationHistoryLoseNum": 0,
    "guestEventList": []
}
