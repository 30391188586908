<template>
  <div class="matchResult">
    <div class="table">
      <a-table :columns="columns" :pagination="false" :data-source="data"></a-table>
    </div>

    <div class="present-section">
      <div class="left item">
        <div class="foul">
          <p>本节犯规</p>
          <p>{{ totalStats.homeTeamStat?.currentPeriodFoul }}</p>
        </div>
        <div class="pause">
          <p>剩余暂停</p>
          <p>{{ totalStats.homeTeamStat?.currentPeriodRemainingPause }}</p>
        </div>
      </div>
      <StatisticsDisplay
        :homeStats="totalStats.homeTeamStat"
        :awayStats="totalStats.awayTeamStat"
        :stats="stats1"
      />
      <div class="right item">
        <div class="foul">
          <p>本节犯规</p>
          <p>{{ totalStats.awayTeamStat?.currentPeriodFoul }}</p>
        </div>
        <div class="pause">
          <p>剩余暂停</p>
          <p>{{ totalStats.awayTeamStat?.currentPeriodRemainingPause }}</p>
        </div>
      </div>
    </div>

    <div class="tab-container">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="{ active: activeTab === index }"
        @click="setActiveTab(index)"
      >
        {{ tab }}
        <div v-if="activeTab === index" class="underline"></div>
      </div>
    </div>
    <div class="chat" v-if="tabs.length">
      <div class="chat-content">
        <div v-if="activeTab === 0">
          <CriticalEvent v-if="props.chatId" :chatId="String(props.chatId)" />
        </div>
        <div v-else>
          <div class="present-section">
            <template v-if="statusCode !== 0">
              <StatisticsDisplay
                :homeStats="totalStats.homeTeamStat"
                :awayStats="totalStats.awayTeamStat"
                :stats="stats2"
              />
            </template>
            <template v-else>
              <div class="empty">
                <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
                <p>暂无数据</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, h } from 'vue'
import CriticalEvent from './criticalEvent.vue'
import { getBasketballStats } from '@/api/match'
import StatisticsDisplay from './StatisticsDisplay.vue'
const columns = [
  {
    title: '比赛对阵',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '1',
    dataIndex: 'Period1',
    key: '1',
    align: 'center'
  },
  {
    title: '2',
    dataIndex: 'Period2',
    key: '2',
    align: 'center'
  },
  {
    title: '3',
    dataIndex: 'Period3',
    key: '3',
    align: 'center'
  },
  {
    title: '4',
    dataIndex: 'Period4',
    key: '4',
    align: 'center'
  },
  {
    title: '全场',
    dataIndex: 'Current',
    key: 'allThose',
    align: 'center',
    customRender: ({ text }) => {
      return h(
        'span',
        {
          style: {
            color: '#E95D5D'
          }
        },
        text
      )
    }
  }
]
const stats1 = [
  { key: 'twoPointMade', label: '二分命中' },
  { key: 'threePointMade', label: '三分命中' },
  { key: 'freeThrowMade', label: '罚球命中' }
]
const stats2 = [
  { key: 'fieldGoalAttempted', label: '投篮' },
  { key: 'fieldGoalMade', label: '投篮命中' },
  { key: 'twoPointAttempted', label: '两分' },
  { key: 'twoPointMade', label: '两分命中' },
  { key: 'threePointAttempted', label: '三分' },
  { key: 'threePointMade', label: '三分命中' },
  { key: 'freeThrowAttempted', label: '罚球' },
  { key: 'freeThrowMade', label: '罚球命中' },
  { key: 'maxConsecutivePoints', label: '最高连续得分' },
  { key: 'maxLead', label: '最大领先优势' },
  { key: 'rebound', label: '篮板' },
  { key: 'offensiveRebounds', label: '进攻篮板' },
  { key: 'defensiveRebounds', label: '防守篮板' },
  { key: 'assist', label: '助攻' },
  { key: 'blocks', label: '盖帽' },
  { key: 'steals', label: '抢断' },
  { key: 'turnover', label: '失误' },
  { key: 'currentPeriodFoul', label: '犯规' },
  { key: 'longTimeouts', label: '长暂停' },
  { key: 'currentPeriodRemainingPause', label: '剩余暂停机会' }
]
const data = ref([])
const totalStats = ref({
  homeTeamStat: {},
  awayTeamStat: {}
})
const statusCode = ref(0)
const activeTab = ref(0)
const tabs: any = ref(['比赛进程', '技术统计'])

const props = defineProps<{
  chatId?: string | number
}>()

const processScoreData = (scores: any[]) => {
  const processedData = [
    {
      key: '1',
      name: '主队',
      Period1: '--',
      Period2: '--',
      Period3: '--',
      Period4: '--',
      Current: '--'
    },
    {
      key: '2',
      name: '客队',
      Period1: '--',
      Period2: '--',
      Period3: '--',
      Period4: '--',
      Current: '--'
    }
  ]

  scores.forEach((score) => {
    switch (score.type) {
      case 'Period1':
        processedData[0].Period1 = score.team1 || '--'
        processedData[1].Period1 = score.team2 || '--'
        break
      case 'Period2':
        processedData[0].Period2 = score.team1 || '--'
        processedData[1].Period2 = score.team2 || '--'
        break
      case 'Period3':
        processedData[0].Period3 = score.team1 || '--'
        processedData[1].Period3 = score.team2 || '--'
        break
      case 'Period4':
        processedData[0].Period4 = score.team1 || '--'
        processedData[1].Period4 = score.team2 || '--'
        if (score.team1 && score.team2) {
          processedData[0].Current = score.team1
          processedData[1].Current = score.team2
        }
        break
      case 'Current':
        if (processedData[0].Period4 !== '--') {
          processedData[0].Current = score.team1 || '--'
          processedData[1].Current = score.team2 || '--'
        }
        break
    }
  })

  return processedData
}

watch(
  () => props.chatId,
  (newVal) => {
    if (newVal) {
      getBasketballStats({ matchId: props.chatId }).then((res) => {
        data.value = processScoreData(res.data.scores)
        totalStats.value = res.data.tech
        statusCode.value = res.data.status_code
      })
    }
  },
  { immediate: true }
)

const setActiveTab = (index: number) => {
  console.log('chatId:', props.chatId)
  activeTab.value = index
}
</script>

<style scoped lang="scss">
.matchResult {
  background-color: $scoreBasketball-matchResult-bg;
  padding: 20px 16px;
  
  // 隐藏滚动条
  ::-webkit-scrollbar {
    display: none;
  }

  .table {
    :deep(.ant-table-thead th) {
      background-color: $scoreBasketball-matchResult-table-header;
      color: $scoreBasketball-matchResult-text-light;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
    }

    :deep(.ant-table-tbody td) {
      color: $scoreBasketball-matchResult-text-dark;
      background-color: $scoreBasketball-matchResult-bg;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .tab-container {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    background-color: $scoreBasketball-matchResult-bg;
    border-top: 1px solid $scoreBasketball-matchResult-border;
  }

  .tab {
    position: relative;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: $scoreBasketball-matchResult-text-light;
    text-align: center;
  }

  .tab.active {
    color: $scoreBasketball-matchResult-active;
    font-weight: bold;
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background-color: $scoreBasketball-matchResult-active;
    border-radius: 1px;
  }

  .present-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 35px;

    .item {
      .foul {
        margin-bottom: 5px;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 97px;
        height: 44px;
        background: $scoreBasketball-matchResult-table-header;
        border-radius: 4px;

        p {
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: $scoreBasketball-matchResult-text-dark;
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 280px;
      height: 100%;
      margin: 0 20px;

      .line {
        .text {
          display: flex;
          justify-content: space-between;

          p {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $scoreBasketball-matchResult-text-light;
          }

          p:nth-of-type(2) {
            color: $scoreBasketball-matchResult-text-dark;
          }
        }

        .bar {
          position: relative;
          width: 100%;
          height: 8px;
          margin-top: 2px;
          background: $scoreBasketball-matchResult-bar-bg;
          border-radius: 2px;

          > div {
            position: absolute;
            top: 0;
            height: 100%;
          }

          .right {
            left: 50%;
            width: 20%;
            border-radius: 0 2px 2px 0;
            background: $scoreBasketball-matchResult-bar-right;
          }

          .left {
            right: 50%;
            width: 20%;
            border-radius: 2px 0 0 2px;
            background: $scoreBasketball-matchResult-bar-left;
          }
        }
      }
    }
  }

  .chat {
    height: auto;
  }

  .chat-content {
    height: auto;
    min-height: 100px;
  }
}
</style>
