import request from '@/utils/request'
import axios from 'axios'

export function getFootballMatchCategory() {
  return request.post('/api/match/FootballCat')
}

export function getBasketballMatchCategory() {
  return request.post('/api/match/BasketballCat')
}
export function getMatchFootballList(params: any) {
  return request.post('/api/match/List', params)
}

export function getMatchBasketballList(params: any) {
  return request.post('/api/match/BasketList', params)
}

// 获取足球详情
export function getMatchFootballDetailAPI(params: any) {
  return request.post('/api/match/Detail', params)
}

// 获取篮球详情
export function getMatchBasketballDetailAPI(params: any) {
  return request.post('/api/match/BasketDetail', params)
}

// 获取足球数据

export function getMatchFootballDataAPI(params: any) {
  return request.post('/api/match/FootballSituations', params)
}

// 获取足球事件

export function getMatchFootballEventAPI(params: any) {
  return request.post('/api/match/FootballEvent', params)
}

// 足球比赛赛况技术统计

export function getFootballSituationsAPI(params: any) {
  return request.post('/api/match/FootballSituations', params)
}

// 获取足球指数

export function getMatchFootballOddsAPI(params: any) {
  return request.post('/api/match/FootballOdds', params)
}
// 文字直播

export function getMatchFootballTextDetail(params: any) {
  return request.post('/api/match/FootballLiveText', params)
}

// 技术统计
export function getMatchFootballStats(params: any) {
  return request.post('/api/match/FootballSituations', params)
}

// 篮球数据
export function getBasketballDetail(params: any) {
  return request.post('/api/match/BasketDetail', params)
}

// 篮球技术统计
export function getBasketballStats(params: any) {
  return request.post('/api/match/BasketStats', params)
}

// 篮球文字直播
export function getBasketballTextLive(params: any) {
  return request.post('/api/match/BasketLiveText', params)
}

// 网球列表
export function getTennisList(params: any) {
  return request.post('/api/match/TennisList', params)
}

export function getBaseballList(params: any) {
  return request.post('/api/match/BaseList', params)
}

// lol
export function getLOLList(params: any) {
  return request.post('/api/match/LOL', params)
}

// csgo
export function getCSGOLists(params: any) {
  return request.post('/api/match/CSGO', params)
}
// kog
export function getKogLists(params: any) {
  return request.post('/api/match/KOG', params)
}
// dota2
export function getDota2Lists(params: any) {
  return request.post('/api/match/DoTa2', params)
}
// 获取比赛信息
export function getMatchInfoAPI(params: any) {
  return axios.get('https://anime.zouwanlx.com/sc/gd.jsp', {
    params: {
      _t: new Date().getTime(),
      lan: 1,
      matchId: params.matchId
    }
  })
}
// 获取最新数据
export function getMatchLasterData(params: any) {
  return request.post('/api/match/ListScore', params)
}

export function getAllScheduleList(params: any) {
  return request.post('/api/match/AdminList', params)
}

// 足球比赛阵容
export function getFootballLineApi(params: any) {
  return request.post('/api/match/FootballLine', params)
}

// 足球比赛数据分析页积分排名
export function getFootballScoreRankApi(params: any) {
  return request.post('api/match/FootballScoreRank', params)
}

// 足球比赛数据分析页历史交锋
export function getFootballBattleHistoryApi(params: any) {
  return request.post('api/match/FootballBattleHistory', params)
}

// 足球比赛数据分析页队伍近况
export function getFootballRecentApi(params: any) {
  return request.post('api/match/FootballRecent', params)
}

// 足球比赛数据分析页赛果盘路统计
export function getFootballOddTrendApi(params: any) {
  return request.post('api/match/FootballOddTrend', params)
}

// 足球比赛数据分析页进球时间统计
export function getFootballGoalTimeApi(params: any) {
  return request.post('api/match/FootballGoalTime', params)
}

// 足球比赛数据分析页失球时间统计
export function getFootballLoseGoalTimeApi(params: any) {
  return request.post('api/match/FootballLoseGoalTime', params)
}

// 足球比赛数据分析页半全场赛果统计
export function getFootballHalfFullApi(params: any) {
  return request.post('api/match/FootballHalfFull', params)
}

// 足球比赛数据分析页进球总数统计
export function getFootballGoalSumApi(params: any) {
  return request.post('api/match/FootballGoalSum', params)
}

// 足球比赛数据分析页技术指标统计
export function getFootballTechStatApi(params: any) {
  return request.post('api/match/FootballTechStat', params)
}

// 足球比赛数据分析页双方未来赛程
export function getFootballFutureScheduleApi(params: any) {
  return request.post('api/match/FootballFutureSchedule', params)
}

// 篮球相同指数统计
export function getBasketballOddsLive(params: any) {
  return request.post('/api/match/BasketOdds', params)
}
// 篮球球员统计
export function getBasketballPlayerCount(params: any) {
  return request.post('/api/match/BasketPlayerCount', params)
}

// 篮球阵容
export function getBasketballLineApi(params: any) {
  return request.post('/api/match/BasketLine', params)
}

export function getBasketballBattleHistory(params: any) {
  return request.post('/api/match/BasketBattleHistory', params)
}

export function getBasketballScoreRank(params: any) {
  return request.post('/api/match/BasketScoreRank', params)
}

export function getBasketballRecentHistory(params: any) {
  return request.post('/api/match/BasketRecent', params)
}

export function getBasketballOddTrend(params: any) {
  return request.post('/api/match/BasketOddTrend', params)
}
