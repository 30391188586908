export async function testApiSpeed(url: string): Promise<number> {
  const timeout = 3000; // 3秒超时
  
  const fetchPromise = async () => {
    const startTime = performance.now();
    try {
      const response = await fetch(`${url}/api/app/Speed`, {
        method: 'GET'
      });
      const endTime = performance.now();
      return endTime - startTime;
    } catch (error) {
      return Infinity;
    }
  };

  const timeoutPromise = new Promise<number>((_, reject) =>
    setTimeout(() => reject('timeout'), timeout)
  );

  try {
    return await Promise.race([fetchPromise(), timeoutPromise]);
  } catch (error) {
    return Infinity; // 超时也返回无限大
  }
}

export async function getFastestApi(apis: string[]): Promise<string> {
  const speeds = await Promise.all(
    apis.map(async (api) => ({
      url: api,
      speed: await testApiSpeed(api)
    }))
  )

  // 选择响应最快的API
  const fastest = speeds.reduce((prev, current) => (prev.speed < current.speed ? prev : current))

  return fastest.url
}
