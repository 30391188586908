<template>
  <div class="cruxEvent">
    <a-collapse v-model:activeKey="activeKey" :expandIconPosition="expandIconPosition">
      <a-collapse-panel key="1" :header="title">
        <div class="criticalIncidentSlot" v-if="data.length">
          <div v-for="(item, index) in data" :key="index" class="item" >
           <div v-if="item.team === 1">
             <div class="icon flex">
               <img :src="(eventLegendIcon[item.typeId] || eventLegendIcon.default).src" alt="">
               <span>60'</span>
             </div>
             <div class="score" style="font-size: 12px;">{{item.playerName}}</div>
             <div class="center">{{item.content}}</div>
           </div>

            <div class="right" v-else>
              <div class="center">{{item.content}}</div>
              <div class="score" style="font-size: 12px;">{{item.playerName}}</div>
              <div class="icon flex">
                <img :src="(eventLegendIcon[item.typeId] || eventLegendIcon.default).src" alt="">
                <span>45'</span>
              </div>
            </div>
          </div>
        </div>
        <template #extra>
          <setting-outlined @click="handleClick"/>
        </template>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>


<script setup lang="ts">
import {onMounted, ref} from "vue";

const props = defineProps<{
  data: any[]
  title: string
}>()
const {data, title} = props

const activeKey = ref(['1']);
const expandIconPosition = ref('right');

const handleClick = (event: MouseEvent) => {
  event.stopPropagation();
};

const eventLegendIcon: any = {
  9: {key: 9, name: '进球', src: `/src/assets/${__TEMPLATE__}/icons/score/goal.webp`},
  0: {key: 0, name: '点球', src: `/src/assets/${__TEMPLATE__}/icons/score/penalty.webp`},
  7: {key: 0, name: '点球不进', src: `/src/assets/${__TEMPLATE__}/icons/score/penalty_miss.webp`},
  1: {key: 1, name: '比赛开始', src: `/src/assets/${__TEMPLATE__}/icons/score/whistle.webp`},
  34: {key: 34, name: '默认', src: `/src/assets/${__TEMPLATE__}/icons/score/default_score.webp`},
  2: {key: 0, name: '乌龙球', src: `/src/assets/${__TEMPLATE__}/icons/score/own_goal.webp`},
  30: {key: 30, name: '角球', src: `/src/assets/${__TEMPLATE__}/icons/score/corner.webp`},
  3: {key: 0, name: '助攻', src: `/src/assets/${__TEMPLATE__}/icons/score/assist.webp`},
  18: {key: 18, name: '黄牌', src: `/src/assets/${__TEMPLATE__}/icons/score/yellow_card.webp`},
  default: {key: 0, name: '红牌', src: `/src/assets/${__TEMPLATE__}/icons/score/red_card.webp`},
  21: {key: 21, name: '两黄一红', src: `/src/assets/${__TEMPLATE__}/icons/score/yellow_red_card.webp`},
  23: {key: 23, name: '换人', src: `/src/assets/${__TEMPLATE__}/icons/score/substitution.webp`},
  5: {key: 0, name: '受伤', src: `/src/assets/${__TEMPLATE__}/icons/score/injury.webp`},
  6: {key: 0, name: 'VAR', src: `/src/assets/${__TEMPLATE__}/icons/score/var.webp`}
}


</script>

<style scoped lang="scss">
.cruxEvent {
  :deep(.ant-collapse-header) {
    background-color: $scoreFootball-criticalEvent-header-bg;
    border-radius: 8px;
    text-align: center;
    color: #525A79;
  }

  :deep(.ant-collapse) {
    border: none;

    .ant-collapse-expand-icon {
      position: absolute;
      right: 16px;
      
      .ant-collapse-arrow {
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #AFB8CB transparent;
        width: 0;
        height: 0;
        
        svg {
          display: none;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon {
        .ant-collapse-arrow {
          border-width: 6px 6px 0 6px;
          border-color: #AFB8CB transparent transparent transparent;
        }
      }
    }
  }

  :deep(.ant-collapse-content-active) {
    background-color: $scoreFootball-criticalEvent-content-bg;
    border-top: none;
  }

  .flex {
    display: flex;
    justify-content: left;
  }

  .criticalIncidentSlot {


    .item {

      & > div {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .right {
        align-items: center;
        justify-content: flex-end;
      }

      .icon {
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          color: $scoreFootball-criticalEvent-time-color;
        }
      }

      .score {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: $scoreFootball-criticalEvent-score-color;
        margin: 0 34px;
      }

      .center {
        font-weight: 400;
        font-size: 12px;
        color: $scoreFootball-criticalEvent-text-color;
      }
    }
  }

  .statistics {
    width: 408px;
    height: 300px;
    background: $scoreFootball-criticalEvent-content-bg;
    border-radius: 8px 8px 8px 8px;
  }
}
</style>
