<template>
  <div class="tab-container">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tab"
      :class="{ active: activeIndex === index }"
      @click="setActive(index)"
    >
      {{ tab.content }}
      <div v-if="activeIndex === index" class="underline"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from 'vue'
import { useRoute } from 'vue-router'

const tabs = ref([])
const activeIndex = ref(0)
const myProps = defineProps(['menusList'])
const myEmits = defineEmits(['categorySelect'])
const route = useRoute()

watch(
  () => route.query.league,
  (newLeague) => {
    if (newLeague) {
      const index = tabs.value.findIndex(tab => tab.content === newLeague)
      if (index !== -1) {
        setActive(index)
      }
    }
  }
)

const setActive = (index) => {
  activeIndex.value = index
  // console.log(index, 'index')
  console.log(activeIndex.value, 'activeIndex')
  myEmits('categorySelect', myProps.menusList[index])
}
watch(
  () => myProps.menusList,
  (newProps) => {
    tabs.value = newProps
    tabs.value.unshift({
      content: '全部',
      id: '',
      sorted: 0,
      leagueIds: ''
    })
    
    const league = route.query.league
    if (league) {
      const index = tabs.value.findIndex(tab => tab.content === league)
      if (index !== -1) {
        setActive(index)
      }
    }
  }
)
</script>

<style scoped lang="scss">
.tab-container {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: $scoreFootball-tabNavigation-bg;
  border-radius: 12px;
  margin-bottom: 10px;
}

.tab {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: $scoreFootball-tabNavigation-text;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.tab.active {
  color: $scoreFootball-tabNavigation-active;
  font-weight: bold;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 4px;
  background-color: $scoreFootball-tabNavigation-active;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}
</style>
