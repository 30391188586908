<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="wrapper">
        <div class="score-container">
          <div class="category-list">
            <button
              class="button"
              :class="{ active: activeButton === '0' }"
              @click="setActive('0')"
            >
              即时
            </button>
            <button
              class="button"
              :class="{ active: activeButton === '3' }"
              @click="setActive('3')"
            >
              赛果
            </button>
            <button
              class="button"
              :class="{ active: activeButton === '1' }"
              @click="setActive('1')"
            >
              赛程
            </button>
            <button
              class="button"
              :class="{ active: activeButton === '2' }"
              @click="setActive('2')"
            >
              收藏
            </button>
          </div>
          <ul class="actions_list">
            <li class="action_item">
              <span>赛事</span>
              <img src="@assets/icons/score/more.webp" alt="下拉箭头" class="down-arrow" />
            </li>
            <li class="action_other">
              <span :class="{ active: activeAction === '网球' }" @click="setActiveAction('网球')"
                >网球</span
              >
              <span :class="{ active: activeAction === '棒球' }" @click="setActiveAction('棒球')"
                >棒球</span
              >
            </li>

            <li class="action_item">
              <span>默认</span>
              <img src="@assets/icons/score/music.webp" alt="下拉箭头" class="down-arrow" />
            </li>
            <li class="action_item">
              <span>设置</span>
              <img src="@assets/icons/score/setting.webp" alt="设置" class="settings-icon" />
            </li>
          </ul>
        </div>
        <div class="list">
          <SkeletonLoader :loading="loading" type="match-list" :count="5">
            <div class="list-scroll" v-show="plainMatches.length > 0">
              <MatchList :list="plainMatches" @selectMatch="selectMatch" :hasReset="hasReset" />
            </div>
            <div v-show="plainMatches.length <= 0">
              <div class="empty_list">
                <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
                <p>暂无数据</p>
              </div>
            </div>
          </SkeletonLoader>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import TabNavigation from './TabNavigation.vue'
import MatchList from './MatchList.vue'
// import MatchDetails from './MatchDetails.vue'
import LoadingSpinner from '@/components/loading.vue'
// import { ref } from 'vue'
import { onMounted, ref, watch, computed, onUnmounted, reactive } from 'vue'
import { getTennisList } from '@/api/match'

import { getMatchFootballList } from '@/api/match'
import { fromJS, Map } from 'immutable'
import { getDQToken } from '@/api/user'
import { useRouter } from 'vue-router'

import dayjs from 'dayjs'
import pako from 'pako'
import axios from 'axios'
import { message } from 'ant-design-vue'
import SkeletonLoader from '@/components/SkeletonLoader.vue'
const menusList = ref([])
const hasReset = ref(false)
const loading = ref(true)
const router = useRouter()
const matchSelect = reactive({
  match: {}
})

onMounted(() => {
  getLists()
})
const selectMatch = (res) => {
  matchSelect.match = res
}
const categorySelect = (tab) => {
  //   console.log(tab, 'tab')
  //   categoryIdRef.value = tab.leagueIds
  //   getLists()
}
// const getFootballCetegoryLists = () => {
//   getFootballMatchCategory()
//     .then((res) => {
//       console.log(res)
//       menusList.value = res.data
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }
// const activeButton = ref('0')
const setActive = (button) => {
  if (button === '2') {
    message.success('当前无收藏的比赛')
    return
  }
  activeButton.value = button
  stateRef.value = parseInt(button)
  getLists()
}

const myProps = defineProps(['categoryId', 'status'])
let ws = null
const list = ref(fromJS([]))
const plainMatches = computed(() => list.value.toJS())
const categoryIdRef = ref(null)
const stateRef = ref(null)
const init = ref(false)
// const leagueId = ref(null)

const updateMatches = (newData) => {
  const newImmutableData = fromJS(newData)

  if (newImmutableData) {
    // console.log(newImmutableData, 'newImmutableData')
    list.value = list.value?.map((match) => {
      let updatedMatch = newImmutableData.find(
        (newMatch) => newMatch.get('match_id') === parseInt(match.get('match_id'))
      )
      // console.log(updatedMatch?.toObject(), 'updatedMatch')
      return updatedMatch?.toObject() ? match.merge(updatedMatch) : match
    })
  }
}

let pollingInterval = null

const getTennisLists = () => {
  getTennisList({
    leagueId: categoryIdRef.value ? categoryIdRef.value : null,
    state: stateRef.value ? stateRef.value : null
  })
    .then((res) => {
      console.log(res)
      if (res.code === 200) {
        try {
          // 解码 Base64 数据
          const binaryString = atob(res.data)
          const charData = binaryString.split('').map((char) => char.charCodeAt(0))
          const binData = new Uint8Array(charData)

          // 解压缩数据
          const decompressedData = pako.ungzip(binData, { to: 'string' })
          // list.value = JSON.parse(decompressedData)
          const parseData = JSON.parse(decompressedData)
          if (parseData) {
            list.value = fromJS(JSON.parse(decompressedData))
            console.log(list.value.toJS(), 'list.value[0]')
            if (!init.value) {
              matchSelect.match = list.value.toJS()[0]
              init.value = true
            }
          } else {
            list.value = fromJS([])
          }
        } catch (error) {
          console.error('解压缩失败:', error)
          list.value = fromJS([])
        }
      } else {
        list.value = fromJS([])
      }
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      console.log('finally')
      loading.value = false
    })
}

const getLists = () => {
  loading.value = false
  getTennisList({
    leagueId: categoryIdRef.value ? categoryIdRef.value : null,
    state: stateRef.value ? stateRef.value : null
  })
    .then((res) => {
      console.log(res, 'res')
      if (res.code === 200) {
        try {
          // 解码 Base64 数据
          const binaryString = atob(res.data)
          const charData = binaryString.split('').map((char) => char.charCodeAt(0))
          const binData = new Uint8Array(charData)

          // 解压缩数据
          const decompressedData = pako.ungzip(binData, { to: 'string' })
          // list.value = JSON.parse(decompressedData)
          const parseData = JSON.parse(decompressedData)
          if (parseData) {
            list.value = fromJS(JSON.parse(decompressedData))
            console.log(list.value.toJS(), 'list.value[0]')
            hasReset.value = true
            setTimeout(() => {
              hasReset.value = false
            }, 100)
          } else {
            list.value = fromJS([])
          }
        } catch (error) {
          console.error('解压缩失败:', error)
          list.value = fromJS([])
        }
      } else {
        list.value = fromJS([])
      }
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      console.log('finally')
      loading.value = false
    })
}

const processData = (data) => {
  try {
    // 分割并提取消息数据
    const message = data.split('42/dqiu-prod,')[1]
    if (!message) {
      console.error('Invalid message format')
      return
    }

    // 去掉 "10" 之前的部分
    const cleanedMessage = message.replace(/^\d+/, '')

    const parsedMessage = JSON.parse(cleanedMessage)

    // 检查数据的结构
    if (!Array.isArray(parsedMessage) || parsedMessage.length < 2) {
      console.error('Unexpected parsed message structure')
      return
    }

    const eventType = parsedMessage[0]
    const eventData = parsedMessage[1]

    if (eventType !== 'message' || !eventData || !eventData.msg) {
      console.error('Unexpected event type or missing event data')
      return
    }

    const { msg } = eventData

    if (!Array.isArray(msg) || msg.length === 0) {
      console.error('No message data found')
      return
    }

    const extractedData = msg.reduce((acc, match) => {
      if (match.sportId === 1) {
        acc[match.matchId] = {
          matchId: match.matchId,
          match_id: match.matchId,
          sportId: match.sportId,
          createTime: match.createTime,
          messageId: match.messageId,
          startTime: match.startTime,
          matchTime: match.startTime,
          homeTeamName: match.homeTeamName,
          awayTeamName: match.awayTeamName,
          timePlayed: match.timePlayed,
          timeRemaining: match.timeRemaining,
          timeRunning: match.timeRunning,
          timeUpdate: match.timeUpdate,
          updateTimestamp: match.updateTimestamp,
          status: match.status,
          home_grades: match.homeTeamScore,
          away_grades: match.awayTeamScore,
          status_code: match.statusCode,
          statusCode: match.statusCode,
          awayTeamScore: match.awayTeamScore,
          awayTeamHalfTimeScore: match.awayTeamHalfTimeScore,
          awayTeamNormalTimeScore: match.awayTeamNormalTimeScore,
          homeTeamScore: match.homeTeamScore,
          homeTeamHalfTimeScore: match.homeTeamHalfTimeScore,
          homeTeamNormalTimeScore: match.homeTeamNormalTimeScore,
          awayTeamPenaltyScore: match.awayTeamPenaltyScore,
          awayTeamRed: match.awayTeamRed,
          awayTeamYellow: match.awayTeamYellow,
          awayTeamCorner: match.awayTeamCorner,
          homeTeamPenaltyScore: match.homeTeamPenaltyScore,
          homeTeamRed: match.homeTeamRed,
          homeTeamYellow: match.homeTeamYellow,
          homeTeamCorner: match.homeTeamCorner,
          homeTeamBallPossession: match.homeTeamBallPossession,
          awayTeamBallPossession: match.awayTeamBallPossession,
          homeTeamAttacks: match.homeTeamAttacks,
          awayTeamAttacks: match.awayTeamAttacks,
          homeTeamGoalKicks: match.homeTeamGoalKicks,
          awayTeamGoalKicks: match.awayTeamGoalKicks,
          homeTeamShotsOnGoal: match.homeTeamShotsOnGoal,
          awayTeamShotsOnGoal: match.awayTeamShotsOnGoal,
          homeTeamTimeouts: match.homeTeamTimeouts,
          awayTeamTimeouts: match.awayTeamTimeouts,
          homeTeamFouls: match.homeTeamFouls,
          awayTeamFouls: match.awayTeamFouls,
          homeTeamFieldGoalAttempted: match.homeTeamFieldGoalAttempted,
          awayTeamFieldGoalAttempted: match.awayTeamFieldGoalAttempted,
          homeTeamFieldGoalMade: match.homeTeamFieldGoalMade,
          awayTeamFieldGoalMade: match.awayTeamFieldGoalMade,
          homeTeamThreePointAttempted: match.homeTeamThreePointAttempted,
          awayTeamThreePointAttempted: match.awayTeamThreePointAttempted,
          homeTeamThreePointMade: match.homeTeamThreePointMade,
          awayTeamThreePointMade: match.awayTeamThreePointMade,
          homeTeamFreeThrowAttempted: match.homeTeamFreeThrowAttempted,
          awayTeamFreeThrowAttempted: match.awayTeamFreeThrowAttempted,
          homeTeamFreeThrowMade: match.homeTeamFreeThrowMade,
          awayTeamFreeThrowMade: match.awayTeamFreeThrowMade,
          homeTeamPeriod1Score: match.homeTeamPeriod1Score,
          homeTeamPeriod2Score: match.homeTeamPeriod2Score,
          homeTeamPeriod3Score: match.homeTeamPeriod3Score,
          homeTeamPeriod4Score: match.homeTeamPeriod4Score,
          awayTeamPeriod1Score: match.awayTeamPeriod1Score,
          awayTeamPeriod2Score: match.awayTeamPeriod2Score,
          awayTeamPeriod3Score: match.awayTeamPeriod3Score,
          awayTeamPeriod4Score: match.awayTeamPeriod4Score,
          homeTeamRemainingPause: match.homeTeamRemainingPause,
          awayTeamRemainingPause: match.awayTeamRemainingPause,
          homeTeamAssist: match.homeTeamAssist,
          awayTeamAssist: match.awayTeamAssist,
          homeTeamTurnover: match.homeTeamTurnover,
          awayTeamTurnover: match.awayTeamTurnover,
          homeTeamRebound: match.homeTeamRebound,
          awayTeamRebound: match.awayTeamRebound,
          homeTeamGameScore: match.homeTeamGameScore,
          awayTeamGameScore: match.awayTeamGameScore,
          homeTeamOvertimeScore: match.homeTeamOvertimeScore,
          awayTeamOvertimeScore: match.awayTeamOvertimeScore,
          homeTeamLastPeriodScore: match.homeTeamLastPeriodScore,
          awayTeamLastPeriodScore: match.awayTeamLastPeriodScore,
          side: match.side,
          latencyOfSource: match.latencyOfSource
        }
      }
      // console.log(acc, 'acc')
      return acc
    }, {})

    return extractedData
  } catch (error) {
    console.error('Error processing data:', error)
  }
}

const initSocket = async () => {
  // let userId = generateUserID()
  // let unix = dayjs().valueOf()
  try {
    let tokenData = await getDQToken()
    console.log(tokenData, 'token')
    const token = tokenData.data.token
    const wss = tokenData.data.connectionWsAddress
    ws = new WebSocket(
      `${wss}/socket.io/?token=${encodeURIComponent(token)}&EIO=3&transport=websocket`
    )

    let heartbeatInterval

    ws.onopen = function () {
      console.log('WebSocket connected!')
      let sendmsg1 = `40/dqiu-prod?token=${token}`
      ws.send(sendmsg1)
      let msg2 = `42/dqiu-prod,["join","livescore/*"]`
      ws.send(msg2)

      // Start heartbeat
      startHeartbeat()
    }

    ws.onmessage = function (event) {
      // console.log('Message from server: ' + event.data)
      let newData = processData(event.data)
      updateMatches(newData)
      if (event.data === '1') {
        ws.send('2')
      } else {
        let msg = { appId: 'dqiu-prod', msgId: 'AZCmQfMtvlEPnuKUQHdh', room: '/livescore/10400496' }
      }
      // Reset heartbeat on any message from server
      // resetHeartbeat()
    }

    ws.onclose = function () {
      console.log('WebSocket closed!')
      // Clear the heartbeat interval
      clearInterval(heartbeatInterval)
    }

    ws.onerror = function (error) {
      console.log('WebSocket error: ' + error.type)
    }

    const startHeartbeat = () => {
      heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send('2')
          console.log('Heartbeat sent: ping')
        }
      }, 10000) // Send a ping every 30 seconds
    }

    const resetHeartbeat = () => {
      clearInterval(heartbeatInterval)
      startHeartbeat()
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

const startPolling = () => {
  // 清除任何现有的轮询
  if (pollingInterval) {
    clearInterval(pollingInterval)
  }
  // 设置新的轮询，每10秒调用一次
  pollingInterval = setInterval(() => {
    getTennisLists()
  }, 10000)
}

const stopPolling = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval)
    pollingInterval = null
  }
}

const closeSocket = () => {
  if (ws) {
    ws.close()
    ws = null
  }
}
onMounted(() => {
  getTennisLists() // 立即获取一次数据
  startPolling() // 开始轮询
  // initSocket()
})

onUnmounted(() => {
  stopPolling() // 停止轮询
  closeSocket()
})

const activeAction = ref('网球')

const setActiveAction = (action) => {
  activeAction.value = action
  router.push('/score/baseball')
  // 这里可以添加切换运动类型的逻辑
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
}
.score-container {
  position: sticky;
  display: flex;
  justify-content: space-between;
  top: 70px;
  z-index: 10;

  background: #f5f7ff;
  .category-list {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.button {
  width: 64px; /* 根据需要调整按钮大小 */
  height: 36px; /* 根据需要调整按钮大小 */
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background: url('@assets/icons/score_status_btn.webp') no-repeat;
  background-size: 100% 100%;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  color: $scoreTennis-text-default;
  box-shadow: 0px 0px 10px $scoreTennis-shadow-default;
  border-radius: 20px 20px 20px 20px;
}

.button.active {
  background: url('@assets/icons/score_status_btn_active.webp') no-repeat;
  box-shadow: 0px 0px 10px $scoreTennis-shadow-success;
  background-size: 100% 100%;
  color: $scoreTennis-text-white;
  /* 在需要时添加额外的选中样式 */
}

.outer-container {
  width: 100%;
  background: $scoreTennis-page-bg;
  box-sizing: border-box;
  min-height: 100vh;
}

.inner-container {
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;

  background: $scoreTennis-page-bg;
}

/* 最小宽度限制 */
@media (max-width: 1400px) {
  .inner-container {
    min-width: 1200px;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  position: relative;
  .list-scroll {
    height: 100%;
    padding-right: 10px;
  }
}
.empty_list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 100%;
  background: $scoreTennis-text-white;
  color: $scoreTennis-text-gray;
  .empty-image {
    width: 300px;
  }
}
.match-detail {
  position: sticky;
  top: 200px;
  width: 375px;
  height: 100vh;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.actions_list {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 466px;
}

.action_item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:
    PingFang SC,
    PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: $scoreTennis-text-light;
  width: 72px;
  height: 30px;
  background: $scoreTennis-text-white;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid $scoreTennis-border-light;
  margin-right: 10px;

  span {
    margin-right: 4px;
  }
}
.action_other {
  width: 130px;
  height: 32px;
  background: $scoreTennis-text-white;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid $scoreTennis-border-light;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 4px 2px;
  span {
    width: 70px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  .active {
    background: $scoreTennis-btn-active;
    font-weight: bold;
    color: $scoreTennis-text-white;
    border-radius: 20px 20px 20px 20px;
  }
}

.down-arrow,
.settings-icon {
  width: 16px;
  height: 16px;
}
</style>
