<template>
  <div class="chat_container">
    <div class="activity">
      <img src="@assets/icons/live-detail/speaker.webp" />
      <span class="txt">任何群、广告均为诈骗，违规者封号处理 </span>
    </div>
    <div class="message-container" ref="messageContainer">
      <div class="vip-list">
        <div class="vip-item" v-for="(res, index) in messageList" :key="res.id">
          <img src="@assets/icons/live-detail/chat-level0.webp" alt="VIP1" class="vip-badge" />
          <img
            src="@assets/icons/live-detail/chat-platform-188.webp"
            alt="VIP1"
            class="vip-badge"
            v-show="res.platform === 188"
          />
          <img
            src="@assets/icons/live-detail/chat-platform-199.webp"
            alt="VIP1"
            class="vip-badge"
            v-show="res.platform === 199"
          />
          <div>
            <span class="nickname">{{ res?.nickname }}:</span>
            <span class="text">{{ res?.content }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="join-list">
      <span class="nickname">{{ joinList.data.nickname }}</span>
      <span class="text">{{ joinList.data.content }}</span>
    </div>
    <div class="comment-section">
      <div class="comment-input">
        <div class="isNotLogin" v-if="!isLogin">
          <span class="login_btn" @click="login">登陆</span>
          <span>参与与主播一起聊球</span>
        </div>
        <div class="isLogin" v-if="isLogin">
          <input type="text" placeholder="说点有爱的评论~" v-model="msg" @keyup.enter="sendMsg" />
          <button class="send-button" @click="sendMsg">发送</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, reactive, watch, nextTick, onUnmounted } from 'vue'
import { getDQRongImLibToken } from '@/api/user'
import * as RongIMLib from '@rongcloud/imlib-next'
import { userStore } from '@/stores/user'
import { AppConfigStore } from '@/stores/app'
import { message } from 'ant-design-vue'
import { textFilterAPI } from '@/api/live'
const Events = RongIMLib.Events
const props = defineProps({
  chatId: {
    type: [String, Number],
    required: true
  },
  theme: {
    type: String,
    default: 'football', // 'football' or 'basketball'
    required: false
  }
})

const userState = userStore()
const appConfigState = AppConfigStore()
const isLogin = ref(false)
const msg = ref('')
const messageContainer = ref(null)
const im = RongIMLib.init({ appkey: 'tdrvipksty5k5' })
const messageList = ref([])
const joinList = reactive({
  data: {}
})
const chatRoomIdRef = ref('')
let reconnectTimer = null

if (userState?.token) {
  isLogin.value = true
} else {
  isLogin.value = false
}

const initRongIMLib = async () => {
  try {
    if (!chatRoomIdRef.value) {
      console.error('聊天室ID未设置')
      return false
    }

    // 先退出之前的聊天室
    // try {
    //   await RongIMLib.quitChatRoom(chatRoomIdRef.value.toString())
    // } catch (error) {
    //   console.log('退出之前的聊天室失败:', error)
    // }

    // 清空消息列表
    messageList.value = []

    // 获取token并连接
    const res = await getDQRongImLibToken()
    if (res.code === 200 && res.data?.url) {
      const token = res.data.url
      
      // 断开之前的连接
      try {
        await RongIMLib.disconnect()
      } catch (error) {
        console.log('断开之前的连接失败:', error)
      }

      // 建立新连接
      const connectResult = await RongIMLib.connect(token)
      if (connectResult.code === RongIMLib.ErrorCode.SUCCESS) {
        // 加入聊天室
        console.log('加入聊天室:', chatRoomIdRef.value)
        const joinResult = await RongIMLib.joinExistChatRoom(chatRoomIdRef.value.toString(), {
          count: 100
        })
        if (joinResult.code === RongIMLib.ErrorCode.SUCCESS) {
          console.log('加入聊天室成功')
          return true
        } else {
          console.error('加入聊天室失败:', joinResult.code)
          return false
        }
      } else {
        console.error('连接失败:', connectResult.code)
        return false
      }
    }
    return false
  } catch (error) {
    console.error('初始化聊天室失败:', error)
    return false
  }
}

const listener = (evt) => {
  try {
    evt.messages.forEach(async (val) => {
      if (val.content.content) {
        const data = JSON.parse(val.content.content) ? JSON.parse(val.content.content) : null
        switch (data.type?.toString()) {
          case '0':
            if (data.identity !== 3) {
              const filterResult = await textFilterAPI({
                text: data.content,
                chatId: chatRoomIdRef.value.toString()
              })
              if (filterResult.code === 200 && filterResult.data.pass === 1) {
                messageList.value.push(data)
              }
              scrollToBottom()
            }
            break
          case '1':
            joinList.data = data
            break
        }
      }
    })
  } catch (error) {
    console.log(error, 'error')
  }
}

RongIMLib.addEventListener(Events.MESSAGES, listener)

const login = () => {
  appConfigState.LoginModalHandle(true)
}

const sendMsg = async () => {
  if (!userState.userInfo) {
    message.error('请先登录')
    return
  }

  if (userState.userInfo.black_id !== 0) {
    message.error('您已被禁言，无法发送消息')
    return
  }

  if (!msg.value.trim()) {
    message.error('请输入内容')
    return
  }
  if (!chatRoomIdRef.value) {
    message.error('聊天室未连接，请刷新重试')
    return
  }

  try {
    // 检查聊天室状态
    try {
      const status = await RongIMLib.getChatRoomInfo(chatRoomIdRef.value.toString())
      if (!status || status.code !== RongIMLib.ErrorCode.SUCCESS) {
        console.log('聊天室不存在或已断开，尝试重新连接')
        const reconnected = await initRongIMLib()
        if (!reconnected) {
          message.error('聊天室连接失败，请刷新重试')
          return
        }
      }
    } catch (error) {
      console.error('检查聊天室状态失败:', error)
      message.error('聊天室状态异常，请刷新重试')
      return
    }

    // 文本过滤
    const filterResult = await textFilterAPI({
      text: msg.value,
      chatId: chatRoomIdRef.value.toString()
    })

    if (filterResult.data.pass === 0) {
      message.error('消息包含敏感内容，请修改后重试')
      return
    }

    const conversation = {
      conversationType: RongIMLib.ConversationType.CHATROOM,
      targetId: chatRoomIdRef.value
    }

    const data = {
      content: msg.value,
      contentColor: '',
      experienceLevel: 0,
      headUrl: undefined,
      id: '1816427620896739328',
      identity: 0,
      isLink: 0,
      nickname: userState?.userInfo?.nick ? userState?.userInfo?.nick : 'momo',
      nobleLevel: 0,
      platform: __TEMPLATE__ === 'dtiyu' ? 188 : 199,
      pushTime: null,
      sign: '',
      type: 0,
      userId: 2170106,
      violationStatus: false
    }

    const messageIm = new RongIMLib.TextMessage({
      content: JSON.stringify(data)
    })

    const res = await RongIMLib.sendMessage(conversation, messageIm)

    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      msg.value = ''
      const data = JSON.parse(res.data.content.content)
      if (data) {
        switch (data.type?.toString()) {
          case '0':
            if (data.identity !== 3) {
              messageList.value.push(data)
              scrollToBottom()
            }
            break
          case '1':
            joinList.data = data
            break
        }
      }
    } else {
      message.error('消息发送失败，请重试')
      console.log('消息发送失败', res.code, res.msg)
    }
  } catch (error) {
    console.error('发送消息失败:', error)
    message.error('发送失败，请重试')
  }
}

const scrollToBottom = () => {
  if (messageContainer.value) {
    messageContainer.value.scrollTop = messageContainer.value.scrollHeight
  }
}

watch(
  () => props.chatId,
  (newId, oldId) => {
    if (newId) {
      chatRoomIdRef.value = newId
      initRongIMLib()
    }
  },
  { immediate: true }
)

watch(messageList, (newData) => {
  if (newData) {
    nextTick(() => {
      scrollToBottom()
    })
  }
})

onUnmounted(() => {
  if (chatRoomIdRef.value) {
    RongIMLib.quitChatRoom(chatRoomIdRef.value.toString())
  }
  RongIMLib.removeEventListener(Events.MESSAGES, listener)
  if (reconnectTimer) {
    clearInterval(reconnectTimer)
  }
})

// 添加重连机制
RongIMLib.addEventListener(Events.DISCONNECT, () => {
  console.log('连接断开，尝试重连...')
  if (!reconnectTimer) {
    reconnectTimer = setInterval(async () => {
      const result = await initRongIMLib()
      if (result) {
        clearInterval(reconnectTimer)
        reconnectTimer = null
      }
    }, 3000)
  }
})

RongIMLib.addEventListener(Events.CONNECTED, () => {
  console.log('连接成功')
  if (reconnectTimer) {
    clearInterval(reconnectTimer)
    reconnectTimer = null
  }
})
</script>

<style lang="scss" scoped>
.chat_container {
  height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  .activity {
    background-color: $scoreFootball-matchChat-success-bg;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    color: $scoreFootball-matchChat-success;
    display: flex;
    align-items: center;
    img {
      width: 20px;
    }
    .txt {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .message-container {
    flex: 1;
    overflow: scroll;
  }
  .vip-list {
    display: flex;
    flex-direction: column;

    .vip-item {
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: flex-start;
      .nickname {
        color: $scoreFootball-matchChat-link;
        margin-right: 4px;
      }
      .vip-badge {
        width: 52px;
        margin-right: 10px;
        display: inline;

        object-fit: contain;
      }

      .vip-info {
        font-size: 12px;
        color: $scoreFootball-matchChat-text-primary;
        word-break: break-all;
        word-wrap: break-word;
        line-height: 20px;
      }
    }
  }

  .join-list {
    padding: 10px 12px;
    display: flex;
    .nickname {
      color: $scoreFootball-matchChat-link;
      padding-right: 6px;
    }
    .text {
      color: $scoreFootball-matchChat-text-secondary;
    }
  }
  .comment-section {
    .comment-tools {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .tool-text {
        font-size: 14px;
        color: $scoreFootball-matchChat-success;
        margin-right: 10px;
      }

      .tool-icon {
        width: 20px;
        height: 20px;
        background-color: $scoreFootball-matchChat-icon-bg;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .isNotLogin {
      flex: 1;
      height: 30px;
      padding: 0 10px;
      background: $scoreFootball-matchChat-bg-light;
      box-shadow: 0px 1px 0px 0px $scoreFootball-matchChat-bg-light;
      border-radius: 15px 15px 15px 15px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      .login_btn {
        margin-right: 6px;
        color: $scoreFootball-matchChat-link-light;
        cursor: pointer;
      }
    }
    .isLogin {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .comment-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 36px;
      background: $scoreFootball-matchChat-bg-light;
      border-radius: 20px 20px 20px 20px;

      input {
        flex: 1;
        border: none;
        padding: 5px 10px;
        border-radius: 20px;
        outline: none;
        background: transparent;
      }

      .send-button {
        background-color: $scoreFootball-matchChat-success;
        color: $scoreFootball-matchChat-white;
        border: none;
        border-radius: 0 20px 20px 0;
        padding: 5px 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
