<template>
  <div class="exponent">
    <div class="tab-container">
      <div v-for="(tab, index) in tabs" :key="index" class="tab" :class="{ active: activeTab === tab.id }"
        @click="setActiveTab(tab.id)">
        <span>{{ tab.name }}</span>
        <div v-if="activeTab === index" class="underline">
        </div>
      </div>
    </div>

    <div class="fairText">
      <div class="tableBase table">
        <a-table 
          :columns="getColumnsForTab(activeTab)" 
          :pagination="false" 
          :data-source="trendHundredChangeData"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="['label'].includes(column.dataIndex)">
              <div class="table-label">
                <span>初</span>
                <span>即</span>
              </div>
            </template>
            
            <template v-if="['host', 'guest'].includes(column.dataIndex)">
              <div class="table-label">
                <span>{{ record[column.dataIndex === 'host' ? 'value0ForType1' : 'value0ForType2'] }}</span>
                <div class="value-with-arrow">
                  <span>{{ record[column.dataIndex === 'host' ? 'valueForType1' : 'valueForType2'] }}</span>
                  <span v-if="showArrow(record, column.dataIndex)" 
                        :class="['arrow', getArrowDirection(record, column.dataIndex)]">
                    {{ getArrowDirection(record, column.dataIndex) === 'up' ? '↑' : '↓' }}
                  </span>
                </div>
              </div>
            </template>

            <template v-if="['score'].includes(column.dataIndex)">
              <div class="table-label">
                <span>{{ activeTab === 2 ? record.value0ForTypeX : record.ovalue0 }}</span>
                <span>{{ activeTab === 2 ? record.valueForTypeX : record.ovalue }}</span>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>

</template>


<script setup lang="ts">
// 1. Group imports
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { scoreRightStore } from "@/stores/useScoreRight";
import { getMatchFootballOddsAPI } from "@/api/match";

// 2. Store setup
const useScoreRightStore = scoreRightStore()
const { scoreItemData } = storeToRefs(useScoreRightStore)

// 3. Group reactive references
const activeTab = ref(1)
const tabs = ref([
  { name: '让球', id: 1 },
  { name: '胜平负', id: 2 },
  { name: '总进球', id: 3 },
  { name: '角球', id: 9 }
])
const trendHundredData = ref<any[]>([])
const trendHundredChangeData = ref<any[]>([])

// Types
interface TableColumn {
  title: string;
  width?: number;
  dataIndex: string;
  align?: 'left' | 'center' | 'right';
}

interface Tab {
  name: string;
  id: number;
}

// Common column configuration
const baseColumns: TableColumn[] = [
  {
    title: '公司',
    width: 100,
    dataIndex: 'name'
  },
  {
    title: '',
    dataIndex: 'label',
  }
];

const getColumnsForTab = (tabId: number): TableColumn[] => {
  const columns = [...baseColumns];
  
  switch(tabId) {
    case 1:
    case 9:
      return [...columns, 
        { title: '主', dataIndex: 'host', align: 'center' },
        { title: '让', dataIndex: 'score', width: 80, align: 'center' },
        { title: '客', dataIndex: 'guest', align: 'center' }
      ];
    case 2:
      return [...columns,
        { title: '胜', dataIndex: 'host', align: 'center' },
        { title: '平', dataIndex: 'score', width: 80, align: 'center' },
        { title: '负', dataIndex: 'guest', align: 'center' }
      ];
    case 3:
      return [...columns,
        { title: '大', dataIndex: 'host', align: 'center' },
        { title: '总', dataIndex: 'score', width: 80, align: 'center' },
        { title: '小', dataIndex: 'guest', align: 'center' }
      ];
    default:
      return columns;
  }
};

// 6. Group methods
const setActiveTab = (index: number) => {
  activeTab.value = index
  setTrendHundredData()
}

const setTrendHundredData = () => {
  trendHundredChangeData.value = trendHundredData.value.filter((item: any) => item.typeId === activeTab.value)
  columnsBase1.forEach((item: any) => {
    if (item.dataIndex === 'name') {
      item.title = `公司(${trendHundredData.value.length})家`
    }
  })
  columnsBase2.forEach((item: any) => {
    if (item.dataIndex === 'name') {
      item.title = `公司(${trendHundredData.value.length})家`
    }
  })
  columnsBase3.forEach((item: any) => {
    if (item.dataIndex === 'name') {
      item.title = `公司(${trendHundredData.value.length})家`
    }
  })
  columnsBase9.forEach((item: any) => {
    if (item.dataIndex === 'name') {
      item.title = `公司(${trendHundredData.value.length})家`
    }
  })
}

const getFootballData = (matchId: number) => {
  getMatchFootballOddsAPI({ matchId: String(matchId) })
    .then(res => {
      let data = res.data || [];
      let includesArr = [1, 2, 3, 9];
      let bookData: any = [];

      data.forEach(item => {
        if (item.coOddsWithMatchList && item.coOddsWithMatchList.length) {
          item.coOddsWithMatchList.forEach((i: any) => {
            if (includesArr.includes(i.typeId)) {
              bookData.push({
                key: `${item.bookName}-${i.typeId}`,
                name: item.bookName,
                ...i
              });
            }
          });
        }
      });
      trendHundredData.value = [...bookData];
    })
    .finally(() => {
      setTrendHundredData();
    });
};

const showArrow = (record: any, type: string) => {
  const firstValue = Number(type === 'host' ? record.value0ForType1 : record.value0ForType2);
  const secondValue = Number(type === 'host' ? record.valueForType1 : record.valueForType2);
  return firstValue !== secondValue;
}

const getArrowDirection = (record: any, type: string) => {
  const firstValue = Number(type === 'host' ? record.value0ForType1 : record.value0ForType2);
  const secondValue = Number(type === 'host' ? record.valueForType1 : record.valueForType2);
  return secondValue > firstValue ? 'up' : 'down';
}

// 7. Watch effects
watch(
  () => scoreItemData.value,
  (newVal) => {
    getFootballData(newVal.id)
  },
  { deep: true, immediate: true }
)
</script>

<style scoped lang="scss">
.exponent {
  padding: 0 16px;
  background-color: $scoreFootball-exponent-background;

  .table {
    :deep(.ant-table-thead th) {
      background-color: $scoreFootball-exponent-table-header;
      color: $scoreFootball-exponent-text-secondary;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px !important;
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    :deep(.ant-table-tbody td) {
      color: $scoreFootball-exponent-text-primary;
      background-color: $scoreFootball-exponent-background;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px !important;
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    .title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: $scoreFootball-exponent-text-primary;

      .vertical {
        width: 2px;
        height: 20px;
        background: $scoreFootball-exponent-primary;
        border-radius: 2px;
      }

      .text {
        margin: 0 6px;
      }

      .base {
        font-weight: 500;
        font-size: 12px;
        color: $scoreFootball-exponent-text-secondary;
      }
    }

  }



  .tab-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    background: $scoreFootball-exponent-table-header;
    border-radius: 20px 20px 20px 20px;
  }

  .tab {
    position: relative;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: $scoreFootball-exponent-text-secondary;
    text-align: center;

    p {
      line-height: 1.5;
    }
  }

  .tab.active {
    color: $scoreFootball-exponent-background;
    font-weight: bold;
    height: 28px;
    background: $scoreFootball-exponent-primary;
    border-radius: 20px 20px 20px 20px;
  }

  .fairText {
    .text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: $scoreFootball-exponent-text-primary;
      }
    }

    .confrontation {
      position: relative;
      height: 8px;
      margin-top: 10px;
      background: $scoreFootball-exponent-confrontation-bg;
      border-radius: 8px;

      .right {
        position: absolute;
        content: '';
        background-color: $scoreFootball-exponent-primary;
        border-radius: 8px;
        height: 8px;
        width: 50%;
        right: 0;
        top: 0;
      }

      .left {
        position: absolute;
        content: '';
        background-color: $scoreFootball-exponent-confrontation-red;
        border-radius: 8px;
        height: 8px;
        width: 32px;
        left: 0;
        top: 0;
      }
    }

  }

  .tableBase {
    margin-top: 24px;

    :deep(.ant-table-tbody td) {
      background-color: $scoreFootball-exponent-table-header;
      font-size: 12px;
    }
  }

}

.table-label {
  display: flex;
  flex-direction: column;
  gap: 32px;
  > span:nth-child(1) {
    color: #AFB8CB;
  }
}

.value-with-arrow {
  display: flex;
  align-items: center;
  gap: 4px;
}

.arrow {
  font-size: 12px;
  
  &.up {
    color: #ff4d4f;
  }
  
  &.down {
    color: #52c41a;
  }
}
</style>
