<template>
  <div class="content">
    <div class="line" v-for="(stat, index) in stats" :key="index">
      <div class="text">
        <p>{{ homeStats[stat.key] }}</p>
        <p>{{ stat.label }}</p>
        <p>{{ awayStats[stat.key] }}</p>
      </div>
      <div class="bar">
        <div class="left"
          :style="calculateBarWidth(homeStats[stat.key], awayStats[stat.key], 'left')">
        </div>
        <div class="right"
          :style="calculateBarWidth(homeStats[stat.key], awayStats[stat.key], 'right')">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  homeStats: any,
  awayStats: any,
  stats: any
}>();


const calculateBarWidth = (homeValue: number, awayValue: number, side: 'left' | 'right') => {
  if (!homeValue && !awayValue) return { width: '0%' };

  const total = (homeValue || 0) + (awayValue || 0);
  let percentage = side === 'left'
    ? ((homeValue || 0) / total) * 50
    : ((awayValue || 0) / total) * 50;

  return side === 'left'
    ? { width: `${percentage}%`, right: '50%' }
    : { width: `${percentage}%`, left: '50%' };
};
</script>

<style scoped>
.content {
  width: 100%;
  height: auto;
}

.line {
  margin-bottom: 12px;
}

.text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.text p {
  margin: 0;
  font-size: 12px;
  color: #666;
}

.bar {
  height: 4px;
  display: flex;
  background: #f5f5f5;
  position: relative;
  overflow: hidden;
}

.left, .right {
  height: 100%;
}

.left {
  background: #4080ff;
  position: absolute;
  right: 50%;
}

.right {
  background: #B5BFCD;
  position: absolute;
  left: 50%;
}
</style> 