<template>
  <div class="matchResult">
    <div class="table">
      <a-table :columns="columns" :pagination="false" :data-source="data"></a-table>
    </div>

    <div class="tab-container">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="{ active: activeTab === index }"
        @click="setActiveTab(index)"
      >
        {{ tab }}
        <div v-if="activeTab === index" class="underline"></div>
      </div>
    </div>
    <div class="chat" v-if="tabs.length">
      <div v-if="activeTab === 0">
        <critical-event
          :data="eventBall.firstHalf"
          title="上半场"
          v-if="eventBall.firstHalf.length"
        />
        <div style="margin-top: 20px"></div>
        <critical-event
          :data="eventBall.secondHalf"
          title="下半场"
          v-if="eventBall.secondHalf.length"
        />
        <div class="empty" v-if="!eventBall.firstHalf.length && !eventBall.secondHalf.length">
          <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
          <p>暂无数据</p>
        </div>
      </div>

      <div v-else class="statistics">
        <p class="title">比赛信息</p>
        <div class="border_event" style="margin-top: 9px; margin-bottom: 16px"></div>
        <div class="referee">
          <div class="referee-name flex mt-10">
            <span class="name">当值主裁</span>
            <span class="key">--</span>
          </div>
          <div class="penaltyCard mt-10">
            <p class="name">场均罚牌</p>
            <div>
              <p>
                <img src="@assets/icons/score/red_card.webp" alt="" />
                <span class="key">{{
                  footballSituationData.redCards.team1 + footballSituationData.redCards.team2
                }}</span>
              </p>
              <p>
                <img src="@assets/icons/score/yellow_card.webp" alt="" />
                <span class="key">{{
                  footballSituationData.yellowCards.team1 + footballSituationData.yellowCards.team2
                }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="border_event" style="margin: 16px 0"></div>
        <div class="info">
          <p class="flex mt-10">
            <span class="name">比赛场馆</span>
            <span class="key">{{ scoreItemData.title }}</span>
          </p>
          <p class="flex mt-10">
            <span class="name">比赛地点</span>
            <span class="key">--</span>
          </p>
          <p class="flex mt-10">
            <span class="name">现场观众人数</span>
            <span class="key">{{ scoreItemData.fans }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mockData_event } from '@/views/live-detail/footballComponents/eventLegendStatus'
import LineupList from '@/views/scoreFootball/components/lineupList.vue'
import { onMounted, reactive, ref, watch } from 'vue'
import { getFootballSituationsAPI, getMatchFootballEventAPI } from '@/api/match'

import { scoreRightStore } from '@/stores/useScoreRight'
import { storeToRefs } from 'pinia'
import CriticalEvent from '@/views/scoreFootball/components/criticalEvent.vue'

const useScoreRightStore = scoreRightStore()
const { scoreItemData } = storeToRefs(useScoreRightStore)

const columns = [
  {
    title: '比赛对阵',
    dataIndex: 'name',
    key: 'name',
    width: 150
  },
  {
    title: '上',
    dataIndex: 'top',
    key: 'top',
    ellipsis: true
  },
  {
    title: '下',
    dataIndex: 'down',
    key: 'down',
    ellipsis: true
  },
  {
    title: '全场',
    dataIndex: 'allThose',
    key: 'allThose',
    ellipsis: true,
    width: 80,
    render: () => {
      return {
        style: { color: 'red' }
      }
    }
  }
]

const data: any = ref([])

const activeTab = ref(0)
const tabs: any = ref(['关键事件', '技术统计'])

const setActiveTab = (index: number) => {
  activeTab.value = index
}

const activeKey = ref(['1'])
const expandIconPosition = ref('right')

const eventBall: any = reactive({
  firstHalf: [], // 上半场
  secondHalf: [] // 下半场
})

const noneInfo = ref(false)

const getKeyEvents = (matchId: number) => {
  // let matchId = route.query.id

  getMatchFootballEventAPI({ matchId: matchId.toString() }).then((res) => {
    // eventId 0:暂无比赛消息  1：比赛开始  13：中场休息  3：比赛结束
    let data = res.data

    // console.log(res.data, '-res.data-');

    // let data = mockData_event
    if (data.length === 1 && data[0].eventId === 0) return (noneInfo.value = true)
    let dataEvent = data.filter((item: any) => ![1, 0, 3].includes(item.eventId))
    let halftimeIndex = dataEvent.findIndex((item: any) => item.eventId === 13)
    if (halftimeIndex === -1) {
      eventBall.firstHalf = [...dataEvent]
    } else {
      eventBall.secondHalf = [...dataEvent.slice(0, halftimeIndex - 1)]
      eventBall.firstHalf = [...dataEvent.slice(halftimeIndex + 1)]
    }
  })
}

const footballSituationData: any = ref({})
const getFootballSituations = (matchId: number) => {
  getFootballSituationsAPI({ matchId: matchId.toString() }).then((res) => {
    footballSituationData.value = res.data
  })
}

watch(
  () => scoreItemData.value,
  (newVal) => {
    setTableData(newVal)
  },
  { deep: true }
)

const setTableData = (val: any) => {
  data.value = [
    {
      name: val.home_team_name,
      top: val.half_score[0],
      allThose: val.full_score[0],
      down: val.full_score[0] - val.half_score[0]
    },
    {
      name: val.away_team_name,
      top: val.half_score[1],
      allThose: val.full_score[1],
      down: val.full_score[1] - val.half_score[1]
    }
  ]

  getKeyEvents(val.id)
  getFootballSituations(val.id)
}

onMounted(() => {
  setTableData(scoreItemData.value)
})
</script>

<style scoped lang="scss">
.matchResult {
  padding: 20px 16px;

  // 隐藏滚动条
  ::-webkit-scrollbar {
    display: none;
  }

  .table {
    :deep(.ant-table-thead th) {
      background-color: $scoreFootball-matchResult-thead-bg;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: $scoreFootball-matchResult-text-secondary;
    }

    :deep(.ant-table-tbody td) {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: $scoreFootball-matchResult-text-primary;
      background-color: #fff;
    }
  }

  .tab-container {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    background-color: #fff;
    border-top: 1px solid $scoreFootball-matchResult-border;
  }

  .tab {
    position: relative;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: $scoreFootball-matchResult-text-secondary;
    text-align: center;
  }

  .tab.active {
    color: $scoreFootball-matchResult-active;
    font-weight: bold;
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background-color: $scoreFootball-matchResult-active;
    border-radius: 1px;
  }

  .statistics {
    padding: 10px 20px;
    height: 300px;
    background: $scoreFootball-matchResult-statistics-bg;
    border-radius: 8px 8px 8px 8px;

    .border_event {
      height: 1px;
      background: $scoreFootball-matchResult-border-event;
      border-radius: 0px 0px 0px 0px;
    }

    .name {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: $scoreFootball-matchResult-text-secondary;
    }

    .key {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: $scoreFootball-matchResult-text-primary;
    }

    .flex {
      display: flex;
      justify-content: space-between;
    }

    .mt-10 {
      margin-top: 10px;
    }

    .title {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: $scoreFootball-matchResult-text-secondary;
    }

    .referee {
      .referee-name {
      }
    }

    .penaltyCard {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;

        p {
          display: flex;
          align-items: center;
          margin-right: 16px;

          img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
      }

      p {
        display: flex;
      }
    }
  }
}
</style>
