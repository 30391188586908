import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { getUserInfoAPI } from '@/api/user' // 添加这行导入

export const userStore = defineStore('user', () => {
  const userInfo = ref<any>(null)
  const token = ref<string | null>(null)

  const isLoggedIn = computed(() => !!token.value)

  function loginHandle(res: any) {
    userInfo.value = res.user_info
    token.value = res.token
    localStorage.setItem('userInfo', JSON.stringify(res.user_info))
    localStorage.setItem('token', JSON.stringify(res.token))
  }

  function logoutHandle() {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('token')
    userInfo.value = null
    token.value = null
  }

  function initUserInfo() {
    const storedUserInfo = localStorage.getItem('userInfo')
    const storedToken = localStorage.getItem('token')
    if (storedUserInfo) {
      userInfo.value = JSON.parse(storedUserInfo)
    }
    if (storedToken) {
      token.value = storedToken
    }
  }

  function getToken() {
    return token.value || ''
  }

  async function getUserInfo() {
    try {
      const response = await getUserInfoAPI({})

      if (response.data) {
        userInfo.value = response.data
        localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
      } else {
        throw new Error('获取用户信息失败')
      }
    } catch (error) {
      console.error('获取用户信息出错:', error)
    }
  }

  return {
    userInfo,
    token,
    isLoggedIn,
    loginHandle,
    logoutHandle,
    initUserInfo,
    getToken,
    getUserInfo // 添加新函数到返回对象中
  }
})

// 使用插件来自动同步 localStorage
export const useLocalStoragePlugin = ({ store }) => {
  store.$subscribe((mutation, state) => {
    console.log(state.userInfo, 'state')
    if (state.userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    }
    if (state.token) {
      localStorage.setItem('token', state.token)
    }
  })
}
