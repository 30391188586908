<template>
  <div class="match-list-wrapper">
    <template v-for="(group, groupStatus) in groupedMatches" :key="groupStatus">
      <template v-if="group.length > 0">
        <div class="group-header">{{ getGroupTitle(groupStatus) }}</div>
        <div
          class="match-container"
          v-for="(match, index) in group"
          :key="match.match_id"
          :class="match.match_id === selectedMatchId && 'active'"
          @click="() => selectMatch(match)"
        >
          <!-- 头部内容 -->
          <div class="header-container">
            <div class="league-info">
              <img :src="match.league_logo" alt="League Logo" class="league-logo" />
              <span class="league-name">{{ match.league_name }}</span>
            </div>
            <div class="header-titles">
              <span class="session">1</span>
              <span class="session">2</span>
              <span class="session">3</span>
              <span class="session">4</span>
              <span class="session final">全场</span>
              <span class="live">直播</span>
              <span class="odds">指数</span>
              <span class="op">操作</span>
            </div>
          </div>

          <!-- 底部内容 -->
          <div class="match-info">
            <div class="left">
              <div class="selected">
                <img src="@assets/icons/score/select.webp" />
              </div>
              <div class="status" :class="match.match_status === 2 && 'process'">
                <span class="status-text">{{ match.match_status_txt }}</span>
                <span class="time" v-show="timeShowHandle(match.match_status)">{{
                  formatTime(match.match_time)
                }}</span>
              </div>
              <div class="teams">
                <div class="team">
                  <img :src="match.home_team?.logo" alt="塞尔维亚女篮" class="team-logo" />
                  <span class="team-name">{{ match.home_team?.name }}</span>
                </div>
                <div class="team">
                  <img :src="match.guest_team?.logo" alt="澳大利亚女篮" class="team-logo" />
                  <span class="team-name">{{ match.guest_team?.name }}</span>
                </div>
              </div>
            </div>

            <div class="scores">
              <div class="score-row">
                <span>{{
                  statusMatchScoreText(match.home_section_score[0], match.match_status)
                }}</span>
                <span>{{
                  statusMatchScoreText(match.home_section_score[1], match.match_status)
                }}</span>
                <span>{{
                  statusMatchScoreText(match.home_section_score[2], match.match_status)
                }}</span>
                <span>{{
                  statusMatchScoreText(match.home_section_score[3], match.match_status)
                }}</span>
                <span class="total-score">{{
                  statusMatchScoreText(match.home_total_score, match.match_status)
                }}</span>
              </div>
              <div class="score-row">
                <span>{{
                  statusMatchScoreText(match.guest_section_score[0], match.match_status)
                }}</span>
                <span>{{
                  statusMatchScoreText(match.guest_section_score[1], match.match_status)
                }}</span>
                <span>{{
                  statusMatchScoreText(match.guest_section_score[2], match.match_status)
                }}</span>
                <span>{{
                  statusMatchScoreText(match.guest_section_score[3], match.match_status)
                }}</span>
                <span class="total-score">{{
                  statusMatchScoreText(match.guest_total_score, match.match_status)
                }}</span>
              </div>
            </div>
            <div class="live">
              <div class="live-icon">
                <img
                  src="@assets/icons/score/animation-active.webp"
                  v-show="hasAnimationIcon(match)"
                />
                <img src="@assets/icons/score/video-active.webp" v-show="hasVideoIcon(match)" />
              </div>
            </div>
            <div class="odds">
              <div class="odd">
                <span class="value">{{ match.w_1[0] }}</span>
                <span class="value">{{ match.w_2[0] }}</span>
              </div>
              <div class="odd">
                <span class="value">{{ match.w_1[1] }}</span>
                <span class="value">{{ match.w_2[0] }}</span>
              </div>
              <div class="odd">
                <span class="value">{{ match.w_1[2] }}</span>
                <span class="value">{{ match.w_2[0] }}</span>
              </div>
            </div>
            <div class="actions">
              <div class="op_txt">
                <p @click="analyze">分析</p>
                <p @click="indices">指数</p>
              </div>
              <div class="op_icons">
                <img src="@assets/icons/score/stick.webp" />
                <img src="@assets/icons/score/collect.webp" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import dayjs from 'dayjs'
const myProps = defineProps(['list', 'hasReset'])
const myEmit = defineEmits(['selectMatch'])
const matches = ref([])
// const selectIndex = ref(0)
const selectedMatchId = ref(null)
const isInitialized = ref(false)
const selectMatch = (res) => {
  myEmit('selectMatch', res)
  selectedMatchId.value = res.match_id
}
const handleSelect = (index) => {
  console.log(index, 'index')
}
watch(
  () => myProps.list,
  (newList) => {
    matches.value = newList
    if (!isInitialized.value && newList && newList.length > 0) {
      selectMatch(newList[0])
      isInitialized.value = true
    }
  },
  { immediate: true }
)

watch(
  () => myProps.hasReset,
  (newProps) => {
    if (newProps) {
      myEmit('selectMatch', myProps.list[0])
      console.log(myProps.list[0], 'myProps.list[0]')
      selectedMatchId.value = myProps.list[0].match_id
    }
  }
)
const formatTime = (num) => {
  return dayjs(num).format('HH:mm')
}
const status = (statusIndex) => {
  switch (statusIndex) {
    case 1:
      return ''
    case 2:
      return ''
    case 3:
      return ''
  }
}
const timeShowHandle = (statusIndex) => {
  switch (statusIndex) {
    case 1:
      return true
    case 2:
      return false
    case 3:
      return true
  }
}
const statusMatchScoreText = (score, statusIndex) => {
  switch (statusIndex) {
    case 1:
      return '-'
    case 2:
      return score
    case 3:
      return score
  }
}

const hasAnimationIcon = (res) => {
  // console.log(res, 'res')
  if (res.has_vid === 1) {
    return false
  } else if (res.has_anim === 1) {
    return true
  } else {
    return false
  }
}
const hasVideoIcon = (res) => {
  // console.log(res, 'res')
  if (res.has_vid === 1) {
    return true
  } else {
    return false
  }
}
const hasAvatarIcon = (res) => {
  // console.log(res, 'res')
  if (res.anchor_pic) {
    return true
  }
}

const groupedMatches = computed(() => {
  const groups = {
    inProgress: [],
    notStarted: [],
    finished: []
  }

  matches.value.forEach((match) => {
    if (match.match_status === 2) {
      groups.inProgress.push(match)
    } else if (match.match_status === 1) {
      groups.notStarted.push(match)
    } else if (match.match_status === 3) {
      groups.finished.push(match)
    }
  })

  return groups
})

const getGroupTitle = (status) => {
  switch (status) {
    case 'inProgress':
      return '进行中'
    case 'notStarted':
      return '未开始'
    case 'finished':
      return '已结束'
    default:
      return ''
  }
}


</script>

<style lang="scss" scoped>
.match-list-wrapper {
  width: 815px;
}
.match-container {
  width: 100%;
  cursor: pointer;
  font-family: Arial, sans-serif;
  background: $scoreBasketball-matchList-white;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid transparent;
  height: 90px;
  margin-bottom: 20px;
  position: relative;
  &:hover {
    border: 1px solid $scoreBasketball-matchList-border-active;
  }
  &.active {
    background: $scoreBasketball-matchList-bg-active;
    border: 1px solid $scoreBasketball-matchList-border-active;
    &::after {
      content: '';
      width: 9px;
      height: 9px;
      border-top: 1px solid $scoreBasketball-matchList-border-active;
      border-right: 1px solid $scoreBasketball-matchList-border-active;
      position: absolute;
      right: -5px;
      top: 42%;
      transform: rotate(45deg);
      background-color: $scoreBasketball-matchList-bg-active;
    }
  }
  .header-container,
  .match-info {
    display: flex;
    align-items: center;
    .left {
      width: 300px;
      display: flex;
      align-items: center;
    }
  }

  .header-container {
    border-bottom: 1px solid $scoreBasketball-matchList-border-divider;
    height: 30px;
    overflow: hidden;
    .league-info {
      display: flex;
      align-items: center;
      width: 550px;

      .league-logo {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 20px;
      }

      .league-name {
        font-weight: 500;
        font-size: 12px;
        color: $scoreBasketball-matchList-text-dark;
      }
    }

    .header-titles {
      display: flex;
      flex: 1;
      font-weight: 400;
      font-size: 12px;
      color: $scoreBasketball-matchList-text-dark;

      .session {
        width: 50px;
        text-align: center;
      }
      .final {
        margin-left: 20px;
      }
      .live {
        width: 40px;
        text-align: center;
      }
      .odds {
        width: 155px;
        text-align: center;
      }
      .op {
        width: 80px;
        text-align: center;
      }
    }
  }

  .match-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    .left {
      height: 100%;

      border-right: 1px solid $scoreBasketball-matchList-border-light;
    }
    .selected {
      width: 60px;
      text-align: center;
      img {
        width: 20px;
        height: 20px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .status {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $scoreBasketball-matchList-border-light;
      font-size: 12px;
      color: $scoreBasketball-matchList-text-gray;
      padding-right: 15px;
      &.process {
        color: $scoreBasketball-matchList-text-red;
      }
      .status-text {
        font-weight: bold;
        width: 50px;
        text-align: center;
      }

      .time {
        font-size: 14px;
      }
    }

    .teams {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 140px;

      .team {
        display: flex;
        align-items: center;

        .team-logo {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        .team-name {
          font-size: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100px;
          white-space: nowrap;
        }
      }
    }

    .scores {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border-right: 1px solid $scoreBasketball-matchList-border-light;
      .score-row {
        display: flex;
        align-items: center;

        span {
          width: 50px;
          text-align: center;
          font-size: 14px;
        }
        .total-score {
          margin-left: 20px;
          width: 50px;
          color: $scoreBasketball-matchList-text-red;
          font-weight: bold;
        }
      }
    }

    .live {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border-right: 1px solid $scoreBasketball-matchList-border-light;
      width: 50px;
      margin-left: 10px;
      .live-icon {
        font-size: 24px;

        img {
          width: 20px;
          height: 20px;
        }
        .avatar {
          border-radius: 50%;
        }
      }
    }

    .odds {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 300px;
      height: 100%;
      border-right: 1px solid $scoreBasketball-matchList-border-light;
      .odd {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 125px;
      .op_txt {
        margin-right: 20px;
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        color: $scoreBasketball-matchList-text-light-gray;
        p {
          &:nth-child(1) {
            margin-bottom: 6px;
          }
          &:hover {
            color: $scoreBasketball-matchList-text-darker-gray;
          }
        }
      }
      .op_icons {
        img {
          object-fit: contain;
          width: 16px;
          height: 16px;
          &:nth-child(1) {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}

.group-header {
  padding: 10px 15px;
  background-color: transparent;
  color: $scoreBasketball-matchList-text-title;
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
}
</style>
