<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="wrapper">
        <div class="score-container">
          <div class="category-list">
            <button
              class="button"
              :class="{ active: activeButton === '0' }"
              @click="setActive('0')"
            >
              即时
            </button>
            <button
              class="button"
              :class="{ active: activeButton === '3' }"
              @click="setActive('3')"
            >
              赛果
            </button>
            <button
              class="button"
              :class="{ active: activeButton === '1' }"
              @click="setActive('1')"
            >
              赛程
            </button>
            <button
              class="button"
              :class="{ active: activeButton === '2' }"
              @click="setActive('2')"
            >
              收藏
            </button>
          </div>
          <ul class="actions_list">
            <li class="action_item">
              <span>赛事</span>
              <img src="@assets/icons/score/more.webp" alt="下拉箭头" class="down-arrow" />
            </li>
            <li class="action_other">
              <span :class="{ active: activeAction === 'LOL' }" @click="setActiveAction('LOL')"
                >LOL</span
              >
              <span :class="{ active: activeAction === 'CSGO' }" @click="setActiveAction('CSGO')"
                >CSGO</span
              >
              <span :class="{ active: activeAction === 'DOTA2' }" @click="setActiveAction('DOTA2')"
                >DOTA2</span
              >
              <span :class="{ active: activeAction === 'KOG' }" @click="setActiveAction('KOG')"
                >KOG</span
              >
            </li>

            <li class="action_item">
              <span>默认</span>
              <img src="@assets/icons/score/music.webp" alt="下拉箭头" class="down-arrow" />
            </li>
            <li class="action_item">
              <span>设置</span>
              <img src="@assets/icons/score/setting.webp" alt="设置" class="settings-icon" />
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="list-scroll" v-show="plainMatches.length > 0">
            <MatchList :list="plainMatches" @selectMatch="selectMatch" :hasReset="hasReset" />
          </div>
          <div v-show="!loading && plainMatches.length <= 0">
            <div class="empty_list">
              <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
              <p>暂无数据</p>
            </div>
          </div>
          <SkeletonLoader :loading="loading" type="match-list" :count="5"> </SkeletonLoader>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import TabNavigation from './TabNavigation.vue'
import MatchList from './MatchList.vue'
// import MatchDetails from './MatchDetails.vue'
import LoadingSpinner from '@/components/loading.vue'
// import { ref } from 'vue'
import { onMounted, ref, watch, computed, onUnmounted, reactive, getCurrentInstance } from 'vue'
import { getLOLList, getCSGOLists, getDota2Lists, getKogLists } from '@/api/match'

import { fromJS, Map } from 'immutable'
import { getDQToken } from '@/api/user'
import { useRouter, useRoute } from 'vue-router'

import dayjs from 'dayjs'
import pako from 'pako'
import axios from 'axios'
import SkeletonLoader from '@/components/SkeletonLoader.vue'

const { proxy } = getCurrentInstance()
const menusList = ref([])
const hasReset = ref(false)
const loading = ref(true)
const isFirstLoading = ref(true)
const router = useRouter()
const matchSelect = reactive({
  match: {}
})
const route = useRoute()

// onMounted(() => {
//   getData()
// })
const selectMatch = (res) => {
  matchSelect.match = res
}
const categorySelect = (tab) => {
  //   console.log(tab, 'tab')
  //   categoryIdRef.value = tab.leagueIds
  //   getLists()
}
// const getFootballCetegoryLists = () => {
//   getFootballMatchCategory()
//     .then((res) => {
//       console.log(res)
//       menusList.value = res.data
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }
// const activeButton = ref('0')
watch(
  () => route.query.league,
  (newLeague) => {
    if (newLeague) {
      activeAction.value = newLeague
      setActiveAction(newLeague)
    }
  }
)

const setActive = (button) => {
  if (button === '2') {
    proxy.$message.success('当前无收藏的比赛')
    return
  }
  activeButton.value = button
  stateRef.value = parseInt(button)
  getLists()
}

const myProps = defineProps(['categoryId', 'status'])
let ws = null
const list = ref(fromJS([]))
const plainMatches = computed(() => list.value.toJS())
const categoryIdRef = ref(null)
const stateRef = ref(null)
const init = ref(false)
// const leagueId = ref(null)

const updateMatches = (newData) => {
  const newImmutableData = fromJS(newData)

  if (newImmutableData) {
    // console.log(newImmutableData, 'newImmutableData')
    list.value = list.value?.map((match) => {
      let updatedMatch = newImmutableData.find(
        (newMatch) => newMatch.get('match_id') === parseInt(match.get('match_id'))
      )
      // console.log(updatedMatch?.toObject(), 'updatedMatch')
      return updatedMatch?.toObject() ? match.merge(updatedMatch) : match
    })
  }
}

let pollingInterval = null

const getData = () => {
  if (isFirstLoading.value) {
    loading.value = true
  }

  let apiCall
  switch (activeAction.value) {
    case 'LOL':
      apiCall = getLOLList
      break
    case 'CSGO':
      apiCall = getCSGOLists
      break
    case 'DOTA2':
      apiCall = getDota2Lists
      break
    case 'KOG':
      apiCall = getKogLists
      break
    default:
      apiCall = getLOLList
  }

  apiCall({
    leagueId: categoryIdRef.value ? categoryIdRef.value : null,
    state: stateRef.value ? stateRef.value : null
  })
    .then((res) => {
      if (res.code === 200) {
        try {
          // 解码 Base64 数据
          const binaryString = atob(res.data)
          const charData = binaryString.split('').map((char) => char.charCodeAt(0))
          const binData = new Uint8Array(charData)

          // 解压缩数据
          const decompressedData = pako.ungzip(binData, { to: 'string' })
          const parseData = JSON.parse(decompressedData)

          if (parseData) {
            list.value = fromJS(parseData)
            hasReset.value = true
            setTimeout(() => {
              hasReset.value = false
            }, 100)
          } else {
            list.value = fromJS([])
          }
        } catch (error) {
          console.error('解压缩失败:', error)
          list.value = fromJS([])
        }
      } else {
        list.value = fromJS([])
      }
    })
    .catch((error) => {
      console.log(error)
      list.value = fromJS([])
    })
    .finally(() => {
      loading.value = false
      isFirstLoading.value = false
    })
}

const initSocket = async () => {
  // let userId = generateUserID()
  // let unix = dayjs().valueOf()
  try {
    let tokenData = await getDQToken()
    console.log(tokenData, 'token')
    const token = tokenData.data.token
    const wss = tokenData.data.connectionWsAddress
    ws = new WebSocket(
      `${wss}/socket.io/?token=${encodeURIComponent(token)}&EIO=3&transport=websocket`
    )

    let heartbeatInterval

    ws.onopen = function () {
      console.log('WebSocket connected!')
      let sendmsg1 = `40/dqiu-prod?token=${token}`
      ws.send(sendmsg1)
      let msg2 = `42/dqiu-prod,["join","livescore/*"]`
      ws.send(msg2)

      // Start heartbeat
      startHeartbeat()
    }

    ws.onmessage = function (event) {
      // console.log('Message from server: ' + event.data)
      // let newData = processData(event.data)
      // updateMatches(newData)
      if (event.data === '1') {
        ws.send('2')
      } else {
        let msg = { appId: 'dqiu-prod', msgId: 'AZCmQfMtvlEPnuKUQHdh', room: '/livescore/10400496' }
      }
      // Reset heartbeat on any message from server
      // resetHeartbeat()
    }

    ws.onclose = function () {
      console.log('WebSocket closed!')
      // Clear the heartbeat interval
      clearInterval(heartbeatInterval)
    }

    ws.onerror = function (error) {
      console.log('WebSocket error: ' + error.type)
    }

    const startHeartbeat = () => {
      heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send('2')
          console.log('Heartbeat sent: ping')
        }
      }, 10000) // Send a ping every 30 seconds
    }

    const resetHeartbeat = () => {
      clearInterval(heartbeatInterval)
      startHeartbeat()
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

const startPolling = () => {
  // 清除任何现有的轮询
  if (pollingInterval) {
    clearInterval(pollingInterval)
  }
  // 设置新的轮询，每10秒调用一次
  pollingInterval = setInterval(() => {
    getData()
  }, 10000)
}

const stopPolling = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval)
    pollingInterval = null
  }
}

const closeSocket = () => {
  if (ws) {
    ws.close()
    ws = null
  }
}
onMounted(() => {
  getData() // 立即获取一次数据
  const league = route.query.league
  if (league) {
    activeAction.value = league
    setActiveAction(league)
  }

  startPolling() // 开始轮询
  // initSocket()
})

onUnmounted(() => {
  stopPolling() // 停止轮询
  closeSocket()
})

const activeAction = ref('LOL')

const setActiveAction = (action) => {
  activeAction.value = action
  router.push('/score/esport?league=' + action)
  getData()
  // router.push('/score/tennis')
  // 这里可以添加切换运动类型的逻辑
}

const handleError = (error) => {
  proxy.$message.error(error.message || '发生错误')
  loading.value = false
  isFirstLoading.value = false
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
}
.score-container {
  position: sticky;
  display: flex;
  justify-content: space-between;
  top: 70px;
  z-index: 10;

  background: #f5f7ff;
  .category-list {
    display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.button {
  width: 64px; /* 根据需要调整按钮大小 */
  height: 36px; /* 根据需要调整按钮大小 */
  border: none;
  border-radius: 20px;
  //   background-size: cover;
  cursor: pointer;
  background: url('@assets/icons/score_status_btn.webp') no-repeat;
  background-size: 100% 100%;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  color: $scoreEsport-scorePage-text-default;
  box-shadow: 0px 0px 10px $scoreEsport-scorePage-shadow-default;
  border-radius: 20px 20px 20px 20px;
}

.button.active {
  background: url('@assets/icons/score_status_btn_active.webp') no-repeat;
  box-shadow: 0px 0px 10px $scoreEsport-scorePage-shadow-green;
  background-size: 100% 100%;
  color: $scoreEsport-scorePage-text-white;
  /* 在需要时添加额外的选中样式 */
}

.outer-container {
  width: 100%;
  background: $scoreEsport-scorePage-bg-main;
  box-sizing: border-box;
  min-height: 100vh;
}

.inner-container {
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;

  background: $scoreEsport-scorePage-bg-main;
}

/* 最小宽度限制 */
@media (max-width: 1400px) {
  .inner-container {
    min-width: 1200px;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  position: relative;
  .list-scroll {
    height: 100%;
    padding-right: 10px;
  }
}
.empty_list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 100%;
  background: $scoreEsport-scorePage-bg-white;
  color: $scoreEsport-scorePage-text-gray;
  .empty-image {
    width: 300px;
  }
}
.match-detail {
  position: sticky;
  top: 200px;
  width: 375px;
  height: 100vh;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.actions_list {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 466px;
}

.action_item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:
    PingFang SC,
    PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: $scoreEsport-scorePage-text-light;
  width: 72px;
  height: 30px;
  background: $scoreEsport-scorePage-bg-white;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid $scoreEsport-scorePage-border-light;
  margin-right: 10px;

  span {
    margin-right: 4px;
  }
}
.action_other {
  width: 217px;
  height: 32px;
  background: $scoreEsport-scorePage-bg-white;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid $scoreEsport-scorePage-border-light;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 4px 2px;
  span {
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 0 10px;
  }
  .active {
    background: $scoreEsport-scorePage-active-green;
    font-weight: bold;
    color: $scoreEsport-scorePage-text-white;
    border-radius: 20px 20px 20px 20px;
  }
}

.down-arrow,
.settings-icon {
  width: 16px;
  height: 16px;
}
</style>
