<template>
  <div class="legend-container">
    <div class="legend-title">比分图例</div>
    <div class="legend-items">
      <div class="legend-item" v-for="(item, index) in legendItems" :key="index">
        <img :src="getIconPath(item.icon)" :alt="item.label" class="legend-icon" />
        <span class="legend-label">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const legendItems = ref([
  { icon: 'goal.webp', label: '进球' },
  { icon: 'penalty.webp', label: '点球' },
  { icon: 'missed_penalty.webp', label: '点球不进' },
  { icon: 'own_goal.webp', label: '乌龙球' },
  { icon: 'corner.webp', label: '角球' },
  { icon: 'assist.webp', label: '助攻' },
  { icon: 'yellow_card.webp', label: '黄牌' },
  { icon: 'red_card.webp', label: '红牌' },
  { icon: 'yellow_red_card.webp', label: '两黄一红' },
  { icon: 'substitution.webp', label: '换人' },
  { icon: 'injury.webp', label: '受伤' },
  { icon: 'animation-active.webp', label: '动画' },
  { icon: 'video-active.webp', label: '视频' },
  { icon: 'player_rating.webp', label: '球员评分' },
  { icon: 'starting_lineup.webp', label: '首发阵容' },
  { icon: 'post_match_stats.webp', label: '赛后技术统计' }
])
const getIconPath = (icon) => {
  return new URL(`/src/assets/${__TEMPLATE__}/icons/score/${icon}`, import.meta.url).href
}
</script>

<style scoped lang="scss">
.legend-container {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 785px;
  height: 78px;
  margin: 16px auto;
  background: $scoreFootball-legend-bg;
  border-radius: 12px 12px 12px 12px;
  box-sizing: border-box;
}

.legend-title {
  font-size: 16px;
  font-weight: bold;
  color: $scoreFootball-legend-title-color;
  width: 40px;
}

.legend-items {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-row-gap: 10px;
  margin-left: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.legend-label {
  font-size: 12px;
  color: $scoreFootball-legend-label-color;
}
</style>
