<template>
  <div class="lineup-container" v-if="footballLineInfo.hostTeamMatchLineupList.length > 0">
    <!-- 球队信息头部 -->
    <div class="field">
      <!-- 球场背景 -->
      <div class="pitch">
        <!-- 球场线条 -->

        <!-- 主队球员 -->
        <div v-if="homeTeam.length" class="team home-team">
          <div class="winRate">
            <div class="logo">
              <img :src="footballLineInfo.hostTeamLogo" alt="">
            </div>
            <div class="info">
              <p>
                <span>阵型 {{ footballLineInfo.hostFormation }}</span>
                <span>胜率 {{ footballLineInfo.hostTeamWinRate }}</span>
              </p>
            </div>
          </div>
          <div v-for="(player, index) in homeTeam" :key="index" class="player"
            :style="{ left: homeTeamPosition[index].x + '%', top: homeTeamPosition[index].y + '%' }">
            <div class="player-number">
              <span>{{ player.shirtNumber }}</span>
              <img src="@assets/icons/score/image_1090@2x.webp" alt="">
            </div>
            <div class="player-name">{{ player.name }}</div>
            <div class="player-rating" v-show="player.rating">{{ player.rating }}</div>
            <div class="player-events">
              <div class="time" v-if="player.time">
                <span>{{ player.time }}</span>
                <img src="@assets/icons/score/red_bottom.webp" alt="">
              </div>
              <div class="type" v-if="eventLegendIcon[player.type]">
                <img :src="eventLegendIcon[player.type].src" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 客队球员 -->
        <div v-if="awayTeam.length" class="team away-team">
          <div class="winRate">

            <div class="info">
              <p>
                <span>阵型 {{ footballLineInfo.guestFormation }}</span>
                <span>胜率 {{ footballLineInfo.guestTeamWinRate }}</span>
              </p>
            </div>
            <div class="logo">
              <img :src="footballLineInfo.guestTeamLogo" alt="">
            </div>
          </div>
          <div v-for="(player, index) in awayTeam" :key="index" class="player"
            :style="{ left: awayTeamPosition[index].x + '%', top: awayTeamPosition[index].y + '%' }">
            <div class="player-number">
              <span>{{ player.shirtNumber }}</span>
              <img src="@assets/icons/score/image_1090@2x(1).webp" alt="">
            </div>
            <div class="player-name">{{ player.name }}</div>
            <div class="player-rating" v-show="player.rating">{{ player.rating }}</div>
            <div class="player-events">
              <div class="time" v-if="player.time">
                <span>{{ player.time }}</span>
                <img src="@assets/icons/score/red_bottom.webp" alt="">
              </div>
              <div class="type" v-if="eventLegendIcon[player.type]">
                <img :src="eventLegendIcon[player.type].src" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-container">
      <div v-for="(tab, index) in tabs" :key="index" class="tab" :class="{ active: activeTab === index }"
        @click="setActiveTab(index)">
        {{ tab.name }}
        <div v-if="activeTab === index" class="underline">
        </div>
      </div>
    </div>
    <div class="chat" v-if="tabs.length">
      <!--      <MatchChat :chatId="chatId" />-->
      <!--      <Lineup/>-->
      <LineupList :type="tabs[activeTab].type === 'host' ? 'hostTeamMatchLineupList' : 'guestTeamMatchLineupList'"
        :data="footballLineInfo" :firstData="footballFirstInfo" :downData="footballSubstituteInfo" />
    </div>
  </div>
  <div v-else>
    <div class="empty_list">
      <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getFootballLineApi } from "@/api/match";
import { computed, markRaw, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

// import {eventLegendIcon, occurTime} from './eventLegendStatus'
import { eventLegendIcon, occurTime, mockData } from "@/views/live-detail/footballComponents/eventLegendStatus";
import LineupList from "@/views/scoreFootball/components/lineupList.vue";
import Exponent from "@/views/scoreFootball/components/exponent.vue";
import Lineup from "@/views/scoreFootball/components/lineup.vue";
import { storeToRefs } from "pinia";
import { scoreRightStore } from "@/stores/useScoreRight";


const useScoreRightStore = scoreRightStore()
const { scoreItemData } = storeToRefs(useScoreRightStore)


interface PlayerEvent {
  type: 'goal' | 'yellow-card' | 'red-card' | 'substitution';
}

interface Player {
  name: string;
  rating: number;
  x: number;
  y: number;
  events?: PlayerEvent[];
  distance?: string;
  positionOften?: string;
  position: number;
}

const route = useRoute();

const homeTeam: any = ref([]);


const activeTab = ref(0)
const tabs: any = ref([])

const setActiveTab = (index: number) => {
  activeTab.value = index
}

const homeTeamPosition = [
  { x: 49, y: 7, position: 1 },
  { x: 14, y: 16, position: 2 },
  { x: 37, y: 16, position: 3 },
  { x: 64, y: 16, position: 4 },
  { x: 85, y: 16, position: 5 },

  { x: 25, y: 26, position: 6 },
  { x: 50, y: 26, position: 7 },
  { x: 75, y: 26, position: 8 },

  { x: 25, y: 35, position: 9 },
  { x: 50, y: 35, position: 10 },
  { x: 75, y: 35, position: 11 },
]

const awayTeamPosition = [
  // {},
  { x: 51, y: 93 },
  { x: 86, y: 84 },
  { x: 63, y: 84 },
  { x: 36, y: 84 },
  { x: 15, y: 84 },

  { x: 75, y: 74 },
  { x: 50, y: 74 },
  { x: 25, y: 74 },

  { x: 75, y: 65 },
  { x: 50, y: 65 },
  { x: 25, y: 65 },
]

const awayTeam: any = ref([])


const footballLineInfo: any = ref({
  hostTeamMatchLineupList: [],
  guestTeamMatchLineupList: [],
})


// 替补名单
const footballSubstituteInfo: any = ref({
  hostTeamMatchLineupList: [],
  guestTeamMatchLineupList: [],
  hostEventList: [],
  guestEventList: []

})

// 首发名单
const footballFirstInfo: any = ref({
  hostTeamMatchLineupList: [],
  guestTeamMatchLineupList: [],
  hostEventList: [],
  guestEventList: []
})


const getFootballLine = (matchId: number) => {
  getFootballLineApi({ matchId: matchId.toString() }).then(res => {
    // footballLineInfo.value = res.data
    let data = res.data
    // let data =  mockData

    footballLineInfo.value = data


    // 球场
    if (data.hostTeamMatchLineupList.length) {
      data.hostTeamMatchLineupList.forEach((item: any) => {
        if (item.position < 12 && !item.substitute) {
          homeTeam.value.push({ name: item.playerName, positionOften: item.positionOften, rating: item.rating, position: item.position, ...item })
        }
        if (item.position < 12) {
          if (item.substitute) {
            footballSubstituteInfo.value.hostTeamMatchLineupList.push({ ...item })
          } else {
            footballFirstInfo.value.hostTeamMatchLineupList.push({ ...item })
          }
        }

      })
    } else {
      homeTeam.value = []
    }


    if (data.guestTeamMatchLineupList.length) {
      data.guestTeamMatchLineupList.forEach((item: any) => {
        if (item.position < 12 && !item.substitute) {
          awayTeam.value.push({ name: item.playerName, positionOften: item.positionOften, rating: item.rating, position: item.position, ...item })
        }
        if (item.position < 12) {
          if (item.substitute) {
            footballSubstituteInfo.value.guestTeamMatchLineupList.push({ ...item })
          } else {
            footballFirstInfo.value.guestTeamMatchLineupList.push({ ...item })
          }
        }

      })
    } else {
      awayTeam.value = []
    }


    // 球场事件
    if (data.hostEventList.length) {

      data.hostEventList.forEach((item: any) => {
        homeTeam.value.forEach((i: any) => {
          if (i.name === item.playerName && item.resetTypeName === '换人' && item.position < 12) {
            i.time = occurTime(item.time)
            i.type = item.resetTypeName
          }
        })

        // 首发事件
        footballFirstInfo.value.hostTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position < 12) {
            footballFirstInfo.value.hostEventList.push({ ...item })
          }
        })
        //替补事件
        footballSubstituteInfo.value.hostTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position < 12) {
            footballSubstituteInfo.value.hostEventList.push({ ...item })
          }
        })

      })

    }

    if (data.guestEventList.length) {
      data.guestEventList.forEach((item: any) => {
        awayTeam.value.forEach((i: any) => {
          if (i.name === item.playerName && item.resetTypeName === '换人' && item.position < 12) {
            i.time = occurTime(item.time)
            i.type = item.resetTypeName
          }
        })

        // 首发事件
        footballFirstInfo.value.guestTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position < 12) {
            footballFirstInfo.value.guestEventList.push({ ...item })
          }
        })
        //替补事件
        footballSubstituteInfo.value.guestTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position < 12) {
            footballSubstituteInfo.value.guestEventList.push({ ...item })
          }
        })
      })
    }

    tabs.value.push(...[{ name: data.hostTeamName, type: 'host' }, { name: data.guestTeamName, type: 'guest' }])
  })
}

// const isShow = computed(() => {
//   return Object.keys(footballLineInfo.value).length
// })

watch(() => scoreItemData.value,
  (newVal) => {
    getFootballLine(newVal.id)
  },
  { deep: true, immediate: true }
)

onMounted(() => {
})

</script>

<style scoped lang="scss">
.lineup-container {
  width: 100%;


  .field {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border: 2px solid $scoreFootball-lineup-border;
    border-radius: 4px;

    .pitch {
      position: relative;
      width: 100%;
      height: 667px;

      background-image: url("@assets/icons/score/soccerField_vertical.webp");
      background-position: top;
      background-size: contain;
      background-repeat: no-repeat;

      .team {
        .winRate {
          display: flex;
          align-items: center;
          padding: 6px 10px;
          top: 14px;
          left: 12px;
          width: 118px;
          height: 44px;
          position: absolute;
          background-image: url("@assets/icons/score/winRate_bg.webp");
          background-position: top;
          background-size: contain;
          background-repeat: no-repeat;

          .logo {
            margin-right: 2px;

            img {
              width: 20px;
            }
          }

          p {
            font-weight: 400;
            font-size: 12px;
            color: $scoreFootball-lineup-text-white;

            span {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 70px;
            }
          }
        }

        .player {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: translate(-50%, -50%);
          z-index: 1;

          .player-number {
            position: relative;
            width: 28px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $scoreFootball-lineup-text-white;
            font-weight: bold;

            img {
              width: 28px;
              height: 30px;
            }

            span {
              position: absolute;
              font-family: DIN, DIN;
              font-weight: 500;
              font-size: 12px;
              color: $scoreFootball-lineup-text-white;
            }

            &.blue {
              background: $scoreFootball-lineup-number-blue;
            }
          }

          .player-rating {
            width: 42px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            background: $scoreFootball-lineup-rating-bg;
            color: $scoreFootball-lineup-text-white;
            border-radius: 12px;
            font-size: 12px;
          }

          .player-name {
            width: 60px;
            margin: 3px 0;
            font-family: DIN, DIN;
            font-weight: 500;
            font-size: 12px;
            color: $scoreFootball-lineup-text-white;
            line-height: 14px;
            text-align: center;
            white-space: nowrap;
            /* 保持文本在一行内 */
            overflow: hidden;
            /* 隐藏超出容器的文本 */
            text-overflow: ellipsis;
            /* 当文本被截断时显示省略号 */
          }

          .player-events {
            position: relative;
            display: flex;
            gap: 4px;
            margin-top: 4px;

            .type {
              position: absolute;
              top: -19px;
              left: 35px;
              width: 12px;
              height: 12px;
            }

            .time {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: -63px;
              left: 6px;
              width: 36px;
              height: 15px;
              background: $scoreFootball-lineup-time-bg;
              border-radius: 10px;

              span {
                font-family: DIN, DIN;
                font-weight: 500;
                font-size: 12px;
                color: $scoreFootball-lineup-time-text;
              }

              img {
                width: 6px;
                height: 12px;
              }
            }
          }

        }
      }

      .away-team {
        .winRate {
          left: 222px;
          top: 607px;
          justify-content: right;
          background-image: url("@assets/icons/score/winRate_bg_right.webp");

          .logo {
            margin-left: 2px;

          }

          .info {
            text-align: center;
          }
        }
      }
    }


  }


  .tab-container {
    display: flex;
    // justify-content: space-around;
    padding: 10px 0;
    background-color: $scoreFootball-lineup-text-white;
    border-top: 1px solid $scoreFootball-lineup-border-light;
  }

  .tab {
    position: relative;
    padding: 5px 20px;
    font-size: 14px;
    cursor: pointer;
    color: $scoreFootball-lineup-tab-text;
    text-align: center;
  }

  .tab.active {
    color: $scoreFootball-lineup-tab-active;
    font-weight: bold;
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 2px;
    background-color: $scoreFootball-lineup-tab-active;
    border-radius: 1px;
  }
  .list {
    >div {
      margin-bottom: 20px;
    }
  }
}
.empty_list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 100%;
  background: $scoreFootball-white;
  color: $scoreFootball-text-muted;
  .empty-image {
    width: 300px;
  }
}
</style>
