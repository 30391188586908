<template>
  <div class="playerInfo">
    <div class="information">
      <p class="title">
        <span class="vertical"></span>
        <span class="text">最佳数据</span>
      </p>

      <div class="information-data">
        <div class="item-info" v-for="item in bestScorers" :key="item.type">
          <div class="item">
            <div class="avatar">
              <img :src="item.home.picUrl || '@assets/icons/nav/我的_(2) 1@2x.webp'" alt="">
            </div>
            <div class="item-content">
              <div class="name">
                <p>{{item.home.name}}</p>
              </div>
              <div class="bar">
                <div class="line" :style="{ width: calculateWidth(item.home.point, item.away.point) + '%' }"></div>
              </div>
            </div>
          </div>

          <div class="content">
            <p style="color: #E95D5D">{{item.home.point}}</p>
            <p style="margin: 0 10px;">{{item.type}}</p>
            <p style="color: #327EFD">{{item.away.point}}</p>
          </div>
          <div class="item right">
            <div class="item-content">
              <div class="name">
                <p>{{item.away.name}}</p>
              </div>
              <div class="bar">
                <div class="line" :style="{ width: calculateWidth(item.away.point, item.home.point) + '%' }"></div>
              </div>
            </div>

            <div class="avatar">
              <img :src="item.away.picUrl || '@assets/icons/nav/我的_(2) 1@2x.webp'" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="table">
        <div class="title">
          <div class="left">
            <img :src="lineData.hostTeamLogo" alt="">
            <span>{{lineData.hostTeamName}}</span>
          </div>
          <p>{{lineData.hostTeamScore}}</p>
        </div>

        <div class="table-item">
          <a-table :columns="columns" :pagination="false" :data-source="playerData?.homeTeamPlayerStats"></a-table>
        </div>
      </div>

      <div class="table">
        <div class="title">
          <div class="left">
            <img :src="lineData.guestTeamLogo" alt="">
            <span>{{lineData.guestTeamName}}</span>
          </div>
          <p>{{lineData.guestTeamScore}}</p>
        </div>

        <div class="table-item">
          <a-table :columns="columns" :pagination="false" :data-source="playerData?.awayTeamPlayerStats"></a-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { getBasketballPlayerCount, getBasketballLineApi } from "@/api/match";

const props = defineProps<{
  chatId?: string | number
}>()

const lineData = ref({})

const columns = [
  {
    title: '#',
    dataIndex: 'shirtNumber',
    key: 'shirtNumber',
    width: 40,
    customRender: ({ text }) => text || '-'
  },
  {
    title: '球员',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: 70,
    customRender: ({ text }) => text || '-'
  },
  {
    title: '位置',
    dataIndex: 'position',
    key: 'position',
    width: 50,
    customRender: ({ text }) => text || '-'
  },
  {
    title: '得分',
    dataIndex: 'point',
    key: 'point',
    width: 45,
    customRender: ({ text }) => text || '-'
  },
  {
    title: '篮板',
    dataIndex: 'rebound',
    key: 'rebound',
    width: 45,
    customRender: ({ text }) => text || '-'
  },
  {
    title: '盖帽',
    dataIndex: 'block',
    key: 'block',
    width: 45,
    customRender: ({ text }) => text || '-'
  },
  {
    title: '抢断',
    dataIndex: 'steal',
    key: 'steal',
    width: 45,
    customRender: ({ text }) => text || '-'
  }
];

const playerData = ref([])
watch(() => props.chatId, (newVal) => {
  if (newVal) {
    getBasketballPlayerCount({ matchId: props.chatId }).then((res) => {
      playerData.value = res.data
    })
    getBasketballLineApi({ matchId: props.chatId }).then((res) => {
      lineData.value = res.data
    })
  }
}, { immediate: true });

// 添加计算属性来处理最佳得分球员
const bestScorers = computed(() => {
  if (!playerData.value?.homeTeamPlayerStats?.length || !playerData.value?.awayTeamPlayerStats?.length) {
    return [];
  }

  // 对主队球员按得分排序
  const sortedHome = [...playerData.value.homeTeamPlayerStats].sort((a, b) => b.point - a.point).slice(0, 3);
  // 对客队球员按得分排序
  const sortedAway = [...playerData.value.awayTeamPlayerStats].sort((a, b) => b.point - a.point).slice(0, 3);

  const result = Array.from({ length: 3 }, (_, index) => ({
    home: sortedHome[index] || { name: '', point: 0 },
    away: sortedAway[index] || { name: '', point: 0 },
    type: ['得分', '篮板', '助攻'][index]
  }));

  return result;
});

// Add this function to calculate relative width
const calculateWidth = (current: number, opposite: number) => {
  const total = current + opposite;
  if (total === 0) return 50; // Default to 50% if no points
  return (current / total) * 100;
};
</script>



<style scoped lang="scss">
.playerInfo {
  padding: 0 16px;
  background-color: $scoreBasketball-playerInfo-bg;

  .information {
    .title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: $scoreBasketball-playerInfo-primary-text;

      .vertical {
        width: 2px;
        height: 20px;
        background: $scoreBasketball-playerInfo-vertical-bar;
        border-radius: 2px;
      }

      .text {
        margin: 0 6px;
      }

      .base {
        font-weight: 500;
        font-size: 12px;
        color: $scoreBasketball-playerInfo-secondary-text;
      }
    }

    .information-data {
      width: 100%;

      .item-info {
        margin-top: 40px;
        position: relative;
        width: 100%;
        justify-content: space-between;
        display: flex;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $scoreBasketball-playerInfo-primary-text;
          }

          .avatar {
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
          }

          .item-content {
            .bar {
              display: flex;
              width: 130px;
              height: 4px;
              background: $scoreBasketball-playerInfo-bar-bg;
              margin-top: 10px;

              .line {
                width: 50%;
                height: 100%;
                background-color: $scoreBasketball-playerInfo-score-left;
              }
            }
          }
        }

        .right {
          .item-content {
            text-align: right;

            .name {
              text-align: right;
              margin-left: auto;
            }

            .bar {
              justify-content: right;

              .line {
                background-color: $scoreBasketball-playerInfo-score-right;
              }
            }
          }
        }

        .content {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;

          p {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $scoreBasketball-playerInfo-secondary-text;
          }

        }

      }

    }
  }

  .table {
    margin-top: 41px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: $scoreBasketball-playerInfo-primary-text;
      }

      .left {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: $scoreBasketball-playerInfo-primary-text;
        }
      }
    }

    .table-item {
      :deep(.ant-table) {
        width: 100%;
        min-width: 340px;
      }
      
      :deep(.ant-table-thead th) {
        padding: 8px 5px !important;
        font-size: 12px !important;
        color: #AFB8CB !important;
      }
      
      :deep(.ant-table-tbody td) {
        padding: 8px 5px !important;
        font-size: 12px !important;
        color: #525A79 !important;
      }
    }
  }
}

.name {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
