<template>
  <div class="match-details">
    <!-- 顶部切换 -->
    <div class="top-bar">
      <div class="left">
        <div class="top-bar-item">
          <img
            :src="showType === 0 ? iconsList[0].active : iconsList[0].icon"
            alt="Icon1"
            class="icons"
            @click="() => changeShowType(0)"
          />
        </div>
        <div class="top-bar-item">
          <img
            :src="showType === 1 ? iconsList[1].active : iconsList[1].icon"
            alt="Icon1"
            class="icons"
            @click="() => changeShowType(1)"
          />
        </div>
      </div>

      <button class="details-button" @click="goMachDetail">比赛详情 >></button>
    </div>
    <!-- 中间 WebView -->
    <div class="webview-container">
      <iframe :src="matchRef?.animation" frameborder="0" v-if="showType === 0 && has_anim"></iframe>
      <iframe
        v-if="showType === 1 && has_video"
        frameborder="0"
        :key="matchRef?.liveFlvUrl"
        id="iframe_video"
        src="https://video.dunduiby.com"
      />
      <div class="empty" v-if="showType === 0 && !has_anim">暂无动画内容</div>
      <div class="empty" v-if="showType === 1 && !has_video">暂无视频内容</div>
    </div>

    <!-- 底部标签切换 -->
    <div class="tab-container">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="{ active: activeTab === index }"
        @click="setActiveTab(index)"
      >
        {{ tab.name }}
        <div v-if="activeTab === index" class="underline"></div>
      </div>
    </div>
    <div class="chat">
      <component
        :is="tabs[activeTab].key"
        :chatId="chatId"
        :key="activeTab === 0 ? chatId : undefined"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onUnmounted, markRaw, nextTick } from 'vue'
import { getBasketballDetail } from '@/api/match'
import MatchChat from './MatchChat.vue'
import { useRouter } from 'vue-router'
import { userStore } from '@/stores/user'
import { AppConfigStore } from '@/stores/app'
import { message } from 'ant-design-vue'
import MatchResult from './components/matchResult.vue'
import PlayerInfo from './components/playerInfo.vue'
import Analysis from './components/analysis.vue'
import Exponent from './components/exponent.vue'

const router = useRouter()

const tabs = ref([
  { name: '聊球', key: markRaw(MatchChat) },
  { name: '赛况', key: markRaw(MatchResult) },
  { name: '球员', key: markRaw(PlayerInfo) },
  { name: '分析', key: markRaw(Analysis) },
  { name: '指数', key: markRaw(Exponent) }
  // '赛况', '分析', '指数'
])
const activeTab = ref(0)
const props = defineProps(['match'])
const chatId = ref('')
const matchData = ref(null)
const matchRef = ref(null)
const showType = ref(0)
const has_anim = ref(false)
const has_video = ref(false)
let video_iframe_timer = null
const iconsList = ref([
  {
    icon: new URL('@assets/icons/score/animation-default.webp', import.meta.url).href,
    active: new URL('@assets/icons/score/animation-active.webp', import.meta.url).href
  },
  {
    icon: new URL('@assets/icons/score/video-default.webp', import.meta.url).href,
    active: new URL('@assets/icons/score/video-active.webp', import.meta.url).href
  }
])
const goMachDetail = () => {
  router.push(`/score-detail?id=${matchData.value.match_id}&type=${matchData.value.sport_id}`)
}
const changeShowType = (type) => {
  showType.value = type
  switch (type) {
    case 0:
      break
    case 1: {
      const data = {
        playAddr: {
          flv: matchRef.value.liveFlvUrl,
          m3u8: matchRef.value.liveM3u8Url,
          DPlayerID: 'iFrame_Player'
        }
      }
      sendMessageToIframe(data)
      video_iframe_timer = setInterval(function () {
        sendMessageToIframe(data)
      }, 1000)
      break
    }
  }
}
const setActiveTab = (index) => {
  activeTab.value = index
}
const sendMessageToIframe = (data) => {
  // console.log(data, 'data')
  var iframe = document.querySelectorAll('iframe')[0]
  if (iframe) {
    iframe.contentWindow.postMessage(data, '*')
    iframe.addEventListener('load', function () {
      console.log(iframe)
      iframe.contentWindow.postMessage(data, '*')
    })
  } else {
    console.log('iframe not found')
  }
}

window.addEventListener('message', function (event) {
  if (event.data.msg === 'playing') {
    if (video_iframe_timer) {
      clearInterval(video_iframe_timer)
    }
  }
})
const getData = async (matchid) => {
  try {
    const res = await getBasketballDetail({ matchId: matchid.toString() })
    if (res.code === 200) {
      matchRef.value = res.data
      console.log(matchRef.value, 'matchRef.value')
      if (res.data.animation) {
        has_anim.value = true
      }
      if (res.data.liveFlvUrl) {
        has_video.value = true
        changeShowType(1)
        nextTick(() => {
          console.log(matchRef.value, 'matchRef.value')
          // 播放视频
          const data = {
            playAddr: {
              flv: matchRef.value.liveFlvUrl,
              m3u8: matchRef.value.liveM3u8Url,
              DPlayerID: 'iFrame_Player'
            }
          }
          sendMessageToIframe(data)
          // video_iframe_timer = setInterval(function () {
          //   sendMessageToIframe(data)
          // }, 1000)
        })
      }
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

const showHandle = () => {}
watch(
  () => props.match,
  (newMatch) => {
    if (!newMatch) return
    matchData.value = newMatch
    chatId.value = newMatch.match_id
    // 处理match更新逻辑
    getData(newMatch.match_id)
  }
)

watch(
  () => activeTab.value,
  (newTab) => {
    if (newTab === 0) {
      // 聊球 tab
      // 通过key强制重新创建聊天组件
      nextTick(() => {
        chatId.value = props.match?.match_id
      })
    }
  }
)

onUnmounted(() => {
  if (video_iframe_timer) {
    clearInterval(video_iframe_timer)
  }
  window.removeEventListener('message', function (event) {})
})
</script>

<style scoped lang="scss">
.match-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px 12px 12px 12px;
  height: 750px;
  overflow: scroll;
  position: sticky;
  top: 200px;
  z-index: 10;
  align-self: flex-start;
  background: #fff;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: $scoreBasketball-matchDetails-white;
  border-bottom: 1px solid $scoreBasketball-matchDetails-border;
  height: 60px;

  .left {
    display: flex;
    align-items: center;
  }
}

.top-bar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  .icons {
    width: 24px;
    height: 24px;
  }
}

.details-button {
  color: $scoreBasketball-matchDetails-white;
  border: none;
  cursor: pointer;
  width: 90px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 12px;
  background: $scoreBasketball-matchDetails-primary;
  cursor: pointer;
}

.webview-container {
  overflow: hidden;
  height: 210px;
  background: $scoreBasketball-matchDetails-background-dark;
  position: relative;

  .empty {
    display: inline-block;
    padding: 0 42px;
    border-radius: 8px;
    height: 52px;
    line-height: 51px;
    font-size: 12px;
    color: $scoreBasketball-matchDetails-primary;
    border: 1px solid $scoreBasketball-matchDetails-primary;
    background: rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -70px;
  }
}

.webview-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.tab-container {
  display: flex;
  padding: 10px 0;
  background-color: $scoreBasketball-matchDetails-white;
  border-top: 1px solid $scoreBasketball-matchDetails-border;
}

.tab {
  position: relative;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  color: $scoreBasketball-matchDetails-text-secondary;
  text-align: center;
}

.tab.active {
  color: $scoreBasketball-matchDetails-primary;
  font-weight: bold;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 2px;
  background-color: $scoreBasketball-matchDetails-primary;
  border-radius: 1px;
}

.chat {
  background: $scoreBasketball-matchDetails-white;
  padding: 0 10px 10px 10px;
  flex: 1;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
