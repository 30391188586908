import request from '@/utils/request'

// 获取验证码
export function getSmsCode(params: any) {
  return request.post('/api/verification/Code', params)
}

export function login(params: any) {
  return request.post('/api/app/Login', params)
}

export function getDQToken() {
  return request.get('api/verification/DqToken')
}

export function getDQRongImLibToken() {
  return request.get('/api/verification/RongCloudCToken')
}

export function openLiveAPI(params: any) {
  return request.post('/api/live/AnchorEnable', params)
}

export function closeLiveAPI(params: any) {
  return request.post('/api/live/AnchorDisable', params)
}

export function updateAvatarAPI(params: any) {
  return request.post('/api/app/UserHeadUpdate', params)
}

export function updateNicknameAPI(params: any) {
  return request.post('/api/app/UserNickUpdate', params)
}

export function updateInfoAPI(params: any) {
  return request.post('/api/app/UserIntroductionUpdate', params)
}

export function updatePwdAPI(params: any) {
  return request.post('/api/app/UserSetPwd', params)
}

export function getUserInfoAPI(params: any) {
  return request.post('/api/app/UserInfo', params)
}


// export function openLiveApplicationAPI(params: any) {
//   return request.post('/api/live/AnchorEnable', params)
// }

export function getUserLiveInfoAPI(params: any) {
  return request.post('/api/live/MyRoom', params)
}

export function addBalanceAPI(params: any) {
  return request.post('/api/live/AnchorAddBlack', params)
}
