<template>
  <div class="cruxEvent">
    <template v-if="Object.keys(liveData).length > 0">
      <a-collapse v-model:activeKey="activeKey" :expandIconPosition="expandIconPosition">
        <a-collapse-panel :header="index + ' ' + item[0].scores" v-for="(item, index) in liveData" :key="index">
          <div class="criticalIncidentSlot" v-for="(event, eventIndex) in item" :key="eventIndex">
            <div class="item" :class="{ right: event.team === 2 }">
              <div class="score">
                <p>{{ event.scores }}</p>
                <p>{{ event.playerName }}</p>
              </div>
              <div class="time">
                <p>{{ formatTime(event.occurTime) }}</p>
              </div>
              <div></div>
            </div>
          </div>
          <template #extra>
            <setting-outlined @click="handleClick" />
          </template>
        </a-collapse-panel>
      </a-collapse>
    </template>
    <template v-else>
      <div class="empty">
        <img src="@assets/icons/score/empty.webp" alt="Empty" class="empty-image" />
        <p>暂无数据</p>
      </div>
    </template>
  </div>
</template>


<script setup lang="ts">
import { ref, watch } from "vue";
import { getBasketballTextLive } from "@/api/match";
const activeKey = ref(['1']);
const expandIconPosition = ref('right');

// 添加 chatId 的 prop
const props = defineProps({
  chatId: {
    type: String,
    required: true
  }
});

// 定义文字直播全局变量
const liveData = ref<Record<string, any[]>>({});

// 修改格式化时间的函数
const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

// 处理文字直播数据分组的函数
const processLiveData = (events: any[]) => {
  const groupedData: Record<string, any[]> = {};

  events.forEach(event => {
    const status = event.statusName || '其他';
    if (!groupedData[status]) {
      groupedData[status] = [];
    }
    groupedData[status].push(event);
  });

  liveData.value = groupedData;
};

// 更新 watch 函数中的处理逻辑
watch(() => props.chatId, async (newVal) => {
  try {
    if (newVal) {
      const response = await getBasketballTextLive({ matchId: newVal });
      processLiveData(response.data.events);
    }
  } catch (error) {
    console.error('Failed to fetch text live data:', error);
  }
}, { immediate: true });

const handleClick = (event: MouseEvent) => {
  event.stopPropagation();
};


</script>

<style scoped lang="scss">
.cruxEvent {
  :deep(.ant-collapse-header) {
    background-color: $scoreBasketball-criticalEvent-header-bg;
    border-radius: 8px;
  }

  :deep(.ant-collapse) {
    border: none;
  }

  :deep(.ant-collapse-content-active) {
    background-color: $scoreBasketball-criticalEvent-content-bg;
    border-top: none;
  }

  :deep(.ant-collapse-content-box) {
    height: 200px;
    overflow-y: auto;
  }

  .flex {
    display: flex;
    justify-content: left;
  }

  .criticalIncidentSlot {


    .item {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      p {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: $scoreBasketball-criticalEvent-text;
      }

      .score {
        margin-right: auto;
      }

      .time {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        /* 将第二个子元素水平居中 */
        margin-left: auto;
        padding: 12px 0;
        border-bottom: 1px solid $scoreBasketball-criticalEvent-border;
      }
    }

    .right {
      .score {
        margin: 0 0 0 auto;

        p {
          text-align: right;
        }
      }
    }
  }

  .statistics {
    width: 408px;
    height: 300px;
    background: $scoreBasketball-criticalEvent-content-bg;
    border-radius: 8px 8px 8px 8px;
  }

  :deep(.ant-collapse-item) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(.ant-collapse-header) {
    background-color: $scoreFootball-criticalEvent-header-bg;
    border-radius: 8px;
    text-align: center;
    color: #525A79;
  }

  :deep(.ant-collapse) {
    border: none;

    :deep(.ant-collapse-header) {
      background-color: $scoreFootball-criticalEvent-header-bg;
      border-radius: 8px;
      text-align: center;
      color: #525A79;
    }

    .ant-collapse-expand-icon {
      position: absolute;
      right: 16px;

      .ant-collapse-arrow {
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #AFB8CB transparent;
        width: 0;
        height: 0;

        svg {
          display: none;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon {
        .ant-collapse-arrow {
          border-width: 6px 6px 0 6px;
          border-color: #AFB8CB transparent transparent transparent;
        }
      }
    }
  }
}
</style>
