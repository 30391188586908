import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import { userStore } from '@/stores/user'
import { getVisitorId } from '@/utils/utils'
import { getFastestApi } from './speedTest'

// 解析环境变量中的API列表
const apiUrls = import.meta.env.VITE_BASE_API.split(',').map((url: string) => url.trim())

let baseURL = apiUrls[0] // 默认使用第一个API

// 创建axios实例的函数
const createAxiosInstance = (baseUrl: string): AxiosInstance => {
  return axios.create({
    baseURL: baseUrl,
    timeout: 10 * 1000,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
}

// 初始化service
let service: AxiosInstance = createAxiosInstance(baseURL)

// 初始化时进行测速并更新baseURL
async function initFastestApi() {
  try {
    const fastestApi = await getFastestApi(apiUrls)
    console.log('使用最快的API:', fastestApi)
    // 直接更新service的baseURL
    service.defaults.baseURL = fastestApi
  } catch (error) {
    console.error('API测速失败,使用默认API:', baseURL)
  }
}

// 请求拦截器
const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const store = userStore()
    const token = store.getToken()
    config.headers = config.headers || {}
    config.headers['DeviceId'] = await getVisitorId()
    if (token) {
      config.headers['token'] = `${token}`
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      const data = response.data
      console.log(data)
      if (data.code === 200) {
        return data
      } else {
        return Promise.reject(data)
      }
    },
    (err) => {
      return Promise.reject(err.response)
    }
  )
}

// 初始化拦截器
setupInterceptors(service)

// 创建request对象
const request = {
  get<T = any>(url: string, data?: any): Promise<T> {
    return request.request('GET', url, { params: data })
  },
  post<T = any>(url: string, data?: any): Promise<T> {
    return request.request('POST', url, { data })
  },
  put<T = any>(url: string, data?: any): Promise<T> {
    return request.request('PUT', url, { data })
  },
  delete<T = any>(url: string, data?: any): Promise<T> {
    return request.request('DELETE', url, { params: data })
  },
  request<T = any>(method = 'GET', url: string, data?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      service({ method, url, ...data })
        .then((res) => {
          resolve(res as unknown as Promise<T>)
        })
        .catch((e: Error | AxiosError) => {
          reject(e)
        })
    })
  }
}

// 立即执行初始化
initFastestApi()

export default request
