import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export async function getVisitorId() {
  const storedId = localStorage.getItem('visitorId')
  if (storedId) {
    return storedId
  }

  const fp = await FingerprintJS.load()
  const result = await fp.get()
  const visitorId = result.visitorId

  localStorage.setItem('visitorId', visitorId)
  return visitorId
}

export function calculateRemainingTime(totalSeconds: any) {
  const days = Math.floor(totalSeconds / (24 * 3600))
  const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return {
    days,
    hours,
    minutes,
    seconds
  }
}

export function secondsToMinutes(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return {
    minutes,
    remainingSeconds
  }
}

export function generateUserID() {
  // 生成一个2到4位的随机数
  const length: number = Math.floor(Math.random() * 3) + 2 // 生成2到4之间的整数
  let userID = ''
  for (let i = 0; i < length; i++) {
    userID += Math.floor(Math.random() * 10) // 生成0到9之间的随机整数
  }
  return userID
}

/**
 * 生成一个符合规则的随机 ID
 * @returns {string} 32位长的随机字符串
 */
export function createRandomId() {
  const characters = 'abcdef0123456789'
  let id = ''
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    id += characters[randomIndex]
  }
  return id
}

export function _formatNumber(num: number) {
  if (!num) return
  if (num > 100000000) {
    return (num / 100000000).toFixed(1) + '亿'
  } else if (num > 10000) {
    return (num / 10000).toFixed(1) + '万'
  } else {
    return num
  }
}

export function _dateFormat(val: any, type?: any): string {
  if (!val) return
  if (String(val).length === 10) {
    val = val * 1000
  }
  const d = new Date(Number(val))
  const year = d.getFullYear()
  const m = d.getMonth() + 1
  const mStr = m < 10 ? '0' + m : m
  const day = d.getDate()
  const dayStr = day < 10 ? '0' + day : day
  const h = d.getHours()
  const hStr = h < 10 ? '0' + h : h
  const min = d.getMinutes()
  const minStr = min < 10 ? '0' + min : min
  const sec = d.getSeconds()
  const secStr = sec < 10 ? '0' + sec : sec
  switch (type) {
    case 'Y':
      return year + ''
    case 'M':
      return `${year}-${mStr}`
    case 'M_D':
      return `${mStr}-${dayStr}`
    case 'M_CN':
      return `${year}年${mStr}月`
    case 'D':
      return `${year}-${mStr}-${dayStr}`
    case 'm':
      return `${year}-${mStr}-${dayStr} ${hStr}:${minStr}`
    default:
      return `${year}-${mStr}-${dayStr} ${hStr}:${minStr}:${secStr}`
  }
}

export function _time(time: number) {
  if (String(time).length === 10) {
    time = time * 1000
  }
  const date = new Date(Number(time))
  const now = new Date()
  const d = now.valueOf() - date.valueOf()
  let str = ''
  if (d < 1000 * 60) {
    str = '刚刚'
  } else if (d < 1000 * 60 * 60) {
    str = `${(d / (1000 * 60)).toFixed()}分钟前`
  } else if (d < 1000 * 60 * 60 * 24) {
    str = `${(d / (1000 * 60 * 60)).toFixed()}小时前`
  } else if (d < 1000 * 60 * 60 * 24 * 2) {
    str = '1天前'
  } else if (d < 1000 * 60 * 60 * 24 * 3) {
    str = '2天前'
  } else if (d < 1000 * 60 * 60 * 24 * 4) {
    str = '3天前'
  } else if (date.getFullYear() === now.getFullYear()) {
    str = _dateFormat(time, 'M_D')
  } else {
    str = _dateFormat(time, 'D')
  }
  return str
}

export function _sleep(duration: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

export function cloneDeep(val: any) {
  return JSON.parse(JSON.stringify(val))
}

export function random(min: number, max: number) {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled) // 包含最小值和最大值
}
export function _stopPropagation(e: Event) {
  e.stopImmediatePropagation()
  e.stopPropagation()
  e.preventDefault()
}
export function _duration(v) {
  if (!v) return '00:00'
  const m = Math.floor(v / 60)
  // let s = v % 60
  const s = Math.round(v % 60)
  let str: string = ''
  if (m === 0) {
    str = '00'
  } else if (m > 0 && m < 10) {
    str = '0' + m
  } else {
    str = m + ''
  }
  str += ':'
  if (s === 0) {
    str += '00'
  } else if (s > 0 && s < 10) {
    str += '0' + s
  } else {
    str += s
  }
  return str
}

export async function decryptImageData(url: any) {
  if (!url) return ''

  const parsedUrl: URL = new URL(url)
  const secret = Number(parsedUrl.searchParams.get('secret') || '')
  const extend = parsedUrl.searchParams.get('extend') || ''

  try {
    const response: any = await axios.get(parsedUrl.origin + parsedUrl.pathname, {
      responseType: 'arraybuffer'
    })

    const data = response.data
    const decryptedData = xorDecrypt(new Uint8Array(data), secret)
    const base64Image = uint8ArrayToBase64(decryptedData)
    // console.log(base64Image, 'base64Image')
    return `data:image/${extend};base64,${base64Image}`
  } catch (error) {
    console.error('Error fetching or decrypting data:', error)
    return ''
  }
}

function xorDecrypt(data: any, key: number) {
  const length = data.length
  for (let i = 0; i < length; i++) {
    data[i] = data[i] ^ key
  }
  return data
}

function uint8ArrayToBase64(data: any) {
  const CHUNK_SIZE = 0x8000 // 32768
  const result = []
  for (let i = 0; i < data.length; i += CHUNK_SIZE) {
    const chunk = data.subarray(i, i + CHUNK_SIZE)
    result.push(String.fromCharCode.apply(null, chunk))
  }
  return btoa(result.join(''))
}

// Usage
// decryptImageData(url).then((base64Image) => {
//   console.log(base64Image)
// })
