<template>
  <div class="match-list-wrapper">
    <!-- 头部内容 -->
    <div class="header-container">
      <div class="header-item checkbox"></div>
      <!-- <div class="header-item star"></div> -->
      <div class="header-item league">赛事</div>
      <div class="header-item time">时间</div>
      <div class="header-item status">状态</div>
      <div class="header-item host-team">主队</div>
      <div class="header-item score">比分</div>
      <div class="header-item away-team">客队</div>
      <div class="header-item live">直播</div>
      <div class="header-item odds">指数</div>
      <div class="header-item data">数据</div>
      <div class="header-item actions">操作</div>
    </div>

    <!-- 比赛列表 -->
    <div class="match-container" v-for="match in matches" :key="match.matchId">
      <div class="match-info">
        <div class="checkbox">
          <input type="checkbox" />
        </div>
        <!-- <div class="star">
          <img
            :src="
              match.isStarred
                ? '@assets/icons/score/star-active.webp'
                : '@assets/icons/score/star.webp'
            "
            alt="星标"
          />
        </div> -->
        <!-- 赛事信息 -->
        <div class="league-info">
          <img :src="match.tournamentLogo" alt="联赛logo" class="league-logo" />

          <p>{{ match.tournamentName }}</p>
        </div>
        <!-- 时间 -->
        <div class="date-time">
          <span class="time">{{ formatTime(match.matchTime) }}</span>
        </div>
        <!-- 状态 -->
        <div class="status" :class="getStatusClass(match)">
          {{ match.match_status_txt }}
        </div>
        <!-- 主队 -->
        <div class="team host">
          <span class="team-name">{{ match.hostName }}</span>
          <img :src="match.hostLogo" alt="主队logo" class="team-logo" />
        </div>
        <!-- 比分 -->
        <div class="scores">
          <template v-if="match.status !== 3">
            <span>{{ match.hostBattleScore }} - {{ match.awayBattleScore }}</span>
            <span class="total-score">全:{{ match.hostScore }} - {{ match.awayScore }}</span>
          </template>
          <template v-else>
            <span>{{ match.hostScore }} - {{ match.awayScore }}</span>
          </template>
        </div>
        <!-- 客队 -->
        <div class="team away">
          <img :src="match.awayLogo" alt="客队logo" class="team-logo" />
          <span class="team-name">{{ match.awayName }}</span>
        </div>
        <!-- 直播图标 -->
        <div class="live-icon">
          <img v-if="match.has_vid" src="@assets/icons/score/video-active.webp" alt="直播" />

          <img src="@assets/icons/score/animation-active.webp" alt="直播" />
        </div>
        <!-- 指数 -->
        <div class="odds">
          <span>{{ match.homeValue }}</span>
          <span>{{ match.awayValue }}</span>
        </div>
        <!-- 数据 -->
        <div class="data">
          <!-- 这里可能需要添加具体的数据内容 -->
          <button>分析</button>
          <button>指数</button>
        </div>
        <!-- 操作 -->
        <div class="actions">
          <!-- <img
            :src="
              match.hasFollow
                ? '@assets/icons/score/collect-active.webp'
                : '@assets/icons/score/collect.webp'
            "
            alt="收藏"
          /> -->
          <!-- <img src="@assets/icons/score/more.webp" alt="更多" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
import dayjs from 'dayjs'

const props = defineProps(['list', 'hasReset'])
const emit = defineEmits(['selectMatch'])
const matches = ref([])

watch(
  () => props.list,
  (newList) => {
    matches.value = newList
  }
)

const formatTime = (timestamp) => {
  return dayjs(timestamp).format('HH:mm')
}

const getStatusClass = (match) => {
  switch (match.status) {
    case 2:
      return 'status-live'
    case 3:
      return 'status-finished'
    default:
      return 'status-not-started'
  }
}
</script>

<style lang="scss" scoped>
.match-list-wrapper {
  width: 1200px;
  border-radius: 10px;
  overflow: hidden;

  .header-container {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: $scoreEsport-matchList-bg-white;
    border-bottom: 1px solid $scoreEsport-matchList-border-light;
    font-size: 14px;
    color: $scoreEsport-matchList-text-gray;
    padding: 0 10px;

    .header-item {
      &.checkbox {
        width: 50px;
      }
      &.league {
        width: 120px;
      }
      &.time {
        width: 60px;
      }
      &.status {
        width: 80px;
      }
      &.host-team {
        width: 250px;
        text-align: right;
      }
      &.away-team {
        width: 250px;
        text-align: left;
      }
      &.score {
        width: 60px;
        text-align: center;
      }
      &.live {
        width: 40px;
        text-align: center;
      }
      &.odds {
        width: 80px;
        text-align: center;
      }
      &.data {
        width: 80px;
        text-align: center;
      }
      &.actions {
        flex: 1;
        text-align: center;
      }
    }
  }

  .match-container {
    background: $scoreEsport-matchList-bg-white;
    border-bottom: 1px solid $scoreEsport-matchList-border-light;

    .match-info {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 10px;

      > div {
        display: flex;
        align-items: center;
      }

      .checkbox,
      .star {
        width: 50px;
        justify-content: center;
      }

      .league-info {
        width: 120px;
        font-size: 14px;
        color: $scoreEsport-matchList-text-dark;

        p {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .league-logo {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }

      .date-time {
        width: 60px;
        font-size: 14px;
        color: $scoreEsport-matchList-text-gray;
      }

      .status {
        width: 80px;
        font-size: 14px;
        text-align: center;
        &.status-finished {
          color: $scoreEsport-matchList-text-light;
        }
        &.status-live {
          color: $scoreEsport-matchList-text-red;
        }
        &.status-not-started {
          color: $scoreEsport-matchList-text-dark;
        }
      }

      .team {
        width: 250px;
        display: flex;
        align-items: center;
        &.host {
          justify-content: flex-end;
        }
        &.away {
          justify-content: flex-start;
        }
        .team-logo {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .team-name {
          font-size: 14px;
          margin: 0 10px;
          color: $scoreEsport-matchList-text-dark;
        }
      }

      .scores {
        width: 60px;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: $scoreEsport-matchList-text-red;

        .total-score {
          font-size: 12px;
          color: $scoreEsport-matchList-text-light;
        }
      }

      .live-icon {
        width: 40px;
        justify-content: center;
        flex-direction: column;
        img {
          width: 16px;
        }
      }

      .odds {
        width: 80px;
        justify-content: space-around;
        flex-direction: column;
        font-size: 12px;
        color: $scoreEsport-matchList-text-dark;
      }

      .data {
        width: 80px;
        justify-content: center;
        button {
          background: $scoreEsport-matchList-bg-white;
          font-size: 12px;
          cursor: pointer;
          outline: none;
          border: none;
        }
      }

      .actions {
        flex: 1;
        justify-content: flex-end;

        button,
        img {
          margin-left: 10px;
        }

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
