import { ref, computed, reactive } from 'vue'
import { defineStore, createPinia } from 'pinia'

export const AppConfigStore = defineStore('appConfig', () => {
  const loginModalShow = ref(false)
  //   const doubleCount = computed(() => count.value * 2)
  function LoginModalHandle(res: any) {
    loginModalShow.value = res
  }

  return { loginModalShow, LoginModalHandle }
})
