import request from '@/utils/request'

// 直播间详情
export function getLiveDetail(params: any) {
  return request.post('/api/live/Detail', params)
}

// 足球比赛详情
export function getFootballMatchDetail(params: any) {
  return request.post('/api/match/Detail', params)
}

// 篮球比赛详情
export function getBasketballMatchDetail(params: any) {
  return request.post('/api/match/BasketDetail', params)
}

export function getMoreMatchLive(params: any) {
  return request.post('/api/match/Live', params)
}

export function getFootballInfoList(params: any) {
  return request.post('/api/match/FootballProphecy', params)
}

export function getShortVideoList(params: any) {
  return request.post('/api/video/List', params)
}

export function getLiveList(params: any) {
  return request.post('/api/live/List', params)
}


export function textFilterAPI(params: any) {
  return request.post('/api/app/TxtFilter', params)
}
