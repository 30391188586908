<template>
    <div class="analysis-container">
        <div class="title">
            <div class="title-decorator"></div>
            <span>积分排名</span>
        </div>
        <div class="analysis-content">
            <a-table :columns="columns1" :data-source="homeTeamData" :pagination="false" size="small"
                class="analysis-table">
                <template #headerCell="{ column }">
                    <template v-if="column.key === 'title'">
                        <img :src="props.matchData?.home_team?.logo" class="country-flag" alt="国旗" />
                    </template>
                </template>
                <template #bodyCell="{ column, text }">
                    <template v-if="column.dataIndex === 'rank'">
                        <a-tooltip placement="left" :title="text" color="#fff"
                            :overlayInnerStyle="{ color: '#666', fontSize: '12px' }">
                            <span>{{ text }}</span>
                        </a-tooltip>
                    </template>
                </template>
            </a-table>

            <a-table :columns="columns1" :data-source="guestTeamData" :pagination="false" size="small"
                class="analysis-table">
                <template #headerCell="{ column }">
                    <template v-if="column.key === 'title'">
                        <img :src="props.matchData?.guest_team?.logo" class="country-flag" alt="国旗" />
                    </template>
                </template>
                <template #bodyCell="{ column, text }">
                    <template v-if="column.dataIndex === 'rank'">
                        <a-tooltip placement="left" :title="text" color="#fff"
                            :overlayInnerStyle="{ color: '#666', fontSize: '12px' }">
                            <span>{{ text }}</span>
                        </a-tooltip>
                    </template>
                </template>
            </a-table>
        </div>

        <div class="title">
            <div class="title-decorator"></div>
            <span>历史交锋</span>
        </div>

        <a-table :columns="battleHistoryColumnsColumns" :data-source="battleHistoryData" :pagination="false"
            size="small" class="analysis-table">
            <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'leagueName'">
                    <div class="league-name">
                        <div>{{ record.leagueName }}</div>
                        <div>{{ formatDate(record.matchTime) }}</div>
                    </div>
                </template>
                <template v-if="column.dataIndex === 'rank'">
                    <div class="score-detail" v-if="record">
                        <div class="score">
                            <div :class="{ 'win': record.hostTeamScore > record.guestTeamScore }">
                                {{ record.hostTeamScore ?? '-' }}
                            </div>
                            <div :class="{ 'win': record.hostTeamScore > record.guestTeamScore }">
                                {{ record.guestTeamScore ?? '-' }}
                            </div>
                        </div>
                        <div class="detail">
                            <div>{{ record.hostTeamName ?? '-' }}</div>
                            <div>{{ record.guestTeamName ?? '-' }}</div>
                        </div>
                    </div>
                </template>
                <template v-if="column.dataIndex === 'halfResult'">
                    <div class="score-half" v-if="record">
                        <div class="detail">
                            <div>{{ record.hostTeamHalfScore ?? '-' }}</div>
                            <div>{{ record.guestTeamHalfScore ?? '-' }}</div>
                        </div>
                    </div>
                </template>
                <template v-if="column.dataIndex === 'rateDetail'">
                    <div class="score-half" v-if="record">
                        <div class="detail">
                            <div :class="{'lose': true, 'win': record.asiaResult === '赢' }">{{ record.rateDetail[1].ovalue0 ?? '-' }}</div>
                            <div :class="{'lose': true, 'win': record.asiaResult === '赢' }">{{record.asiaResult}}</div>
                        </div>
                    </div>
                </template>
                <template v-if="column.dataIndex === 'pointsSumPerGame'">
                    <div class="score-half" v-if="record">
                        <div class="detail">
                            <div :class="{'lose': true, 'win': record.asiaResult === '赢' }">{{ record.dxRate ?? '-' }}</div>
                            <div :class="{'lose': true, 'win': record.asiaResult === '赢' }">{{record.dxResult}}</div>
                        </div>
                    </div>
                </template>
            </template>
        </a-table>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { getBasketballScoreRank, getBasketballBattleHistory } from '@/api/match'

const columns1 = [
    {
        title: '',
        dataIndex: 'title',
        key: 'title',
        width: 40,
        align: 'center',
    },
    {
        title: '排名',
        dataIndex: 'rank',
        key: 'rank',
        width: 60,
        align: 'center',
    },
    {
        title: '胜负',
        dataIndex: 'win',
        key: 'win',
        align: 'center',
    },
    {
        title: '均得',
        dataIndex: 'goal',
        key: 'goal',
        align: 'center',
    },
    {
        title: '均失',
        dataIndex: 'lostGoal',
        key: 'lostGoal',
        align: 'center',
    },
]

const battleHistoryColumnsColumns = [

    {
        title: '赛事',
        dataIndex: 'leagueName',
        key: 'leagueName',
        width: 80,
        fixed: 'left',
        align: 'center',
    },
    {
        title: '比分/对阵',
        dataIndex: 'rank',
        key: 'rank',
        width: 80,
        align: 'center',
    },
    {
        title: '半',
        dataIndex: 'halfResult',
        key: 'halfResult',
        minWidth: 100,
        align: 'center',
    },
    {
        title: '让',
        dataIndex: 'rateDetail',
        key: 'rateDetail',
        minWidth: 100,
        align: 'center',
    },
    {
        title: '总',
        dataIndex: 'pointsSumPerGame',
        key: 'pointsSumPerGame',
        minWidth: 100,
        align: 'center',
    }
]

const rankData = ref(null)

// Add these refs near the top of the script setup section
const homeTeamData = ref(null)
const guestTeamData = ref(null)
const battleHistoryData = ref(null)
// Add props definition
const props = defineProps({
    chatId: {
        type: [String, Number],
        required: true
    },
    matchData: {
        type: Object,
        required: true
    }
})

const fetchIntegralRank = async (chatId) => {
    try {
        const response = await getBasketballScoreRank({ matchId: chatId })
        if (response.code === 200) {
            rankData.value = response.data
            homeTeamData.value = transformRankData(rankData.value[props.matchData.home_team.id])
            guestTeamData.value = transformRankData(rankData.value[props.matchData.guest_team.id])
        }
        const battleHistoryResponse = await getBasketballBattleHistory({ matchId: chatId })
        if (battleHistoryResponse.code === 200) {
            battleHistoryData.value = battleHistoryResponse.data.matches
        }
    } catch (error) {
        console.error('Failed to fetch integral rank:', error)
    }
}

watch(
    [() => props.chatId, () => props.matchData],
    ([chatId, matchData]) => {
        if (chatId && matchData) {
            fetchIntegralRank(chatId)
        }
    },
    { immediate: true }
)

// Update initAnalysisData to use the API
const initAnalysisData = () => {
    if (props.chatId) {
        fetchIntegralRank(props.chatId)
    }
}

// 生命周期钩子
onMounted(() => {
    // 在组件挂载时初始化数据
    initAnalysisData()
})

const transformRankData = (data) => {
    return [
        {
            // 所有包含 all 的数据
            title: '总',
            rank: data.allRank || '-',
            win: (data.allWin || '-') + '-' + (data.allLost || '-'),
            lostGoal: data.allLostGoal || '-',
            goal: data.allGoal || '-',
        },
        {
            // 所有包含 host 的数据
            title: '主',
            rank: data.hostRank || '-',
            win: (data.hostWin || '-') + '-' + (data.hostLost || '-'),
            lostGoal: data.hostLostGoal || '-',
            goal: data.hostGoal || '-',
        },
        {
            // 所有包含 guest 的数据
            title: '客',
            rank: data.guestRank || '-',
            win: (data.guestWin || '-') + '-' + (data.guestLost || '-') ,
            lostGoal: data.guestLostGoal || '-',
            goal: data.guestGoal || '-',
        }
    ]
}

// Add this function before the component logic
const formatDate = (timestamp) => {
    if (!timestamp) return ''
    const date = new Date(timestamp)
    return date.toISOString().split('T')[0] // Returns YYYY-MM-DD format
}
</script>

<style scoped lang="scss">
.analysis-container {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.analysis-content {
    margin-top: 16px;
    width: 100%;
}

.analysis-table {
    :deep(.ant-table) {
        width: 100%;
    }

    :deep(.ant-table-thead > tr > th) {
        color: #AFB8CB;
        background-color: #F6F8FF !important;
        font-weight: normal;
        padding: 8px 4px;
    }

    :deep(.ant-table-tbody > tr > td) {
        color: #525A79;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 4px;
    }

    :deep(.ant-table-container) {
        width: 100%;
    }

    :deep(.ant-table-content) {
        width: 100%;
    }

    // 强制表格不换行且适应容器
    :deep(.ant-table-container) {
        table {
            width: 100% !important;
            table-layout: fixed;
        }
    }

    // 确保单元格内容不会撑开表格
    :deep(.ant-table-cell) {
        max-width: 0; // 这个很重要，确保内容会被截断
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.country-flag {
    width: 24px;
    height: 16px;
    object-fit: cover;
}

.title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #525A79;
    margin-bottom: 16px;

    // 添加标题之间的间距
    &:not(:first-child) {
        margin-top: 20px;
    }
}

.title-decorator {
    width: 2px;
    height: 14px;
    background-color: #00B578;
    border-radius: 1px;
}

:deep(.ant-tooltip) {
    .ant-tooltip-inner {
        background-color: #fff !important;
        color: #666 !important;
        border: 1px solid #e5e5e5;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    .ant-tooltip-arrow {
        &::before {
            background-color: #fff !important;
        }
    }
}

.league-name {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-direction: column;
    font-size: 12px;

    > :nth-child(2) {
        font-size: 10px;
        color: #AFB8CB;
    }
}

.score-detail {
    display: flex;
    gap: 4px;
    flex-direction: row;
    font-size: 10px;
    
    .score {
        display: flex;
        flex-direction: column;
        color: #fff;
        
        div {
            padding: 2px 4px;
            background-color: #FF4D4F; // 默认红色背景
            
            &.win {
                background-color: #00B578 !important; // 使用!important确保优先级
            }
        }
    }
    
    .detail {
        display: flex;
        flex-direction: column;
        gap: 4px;
        > :nth-child(2) {
            color: #AFB8CB;
        }
    }
}
.score-half{
    display: flex;
    align-items: center;
    gap: 4px;
    flex-direction: row;
    font-size: 10px;
    color: #AFB8CB;
    justify-content: center;
    .win{
        color: #00B578 !important;
    }
    .lose{
        color: #FF4D4F;
    }
}
</style>
