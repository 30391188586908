<template>
  <div class="lineup-list">
    <!-- 教练信息 -->
    <div class="coaches">
      <div class="coachesInfo" v-if="props.type === 'hostTeamMatchLineupList'">
        <div class="coach" >


          <div class="coach-info">
            <a-image :width="48" :height="48" :src="props.data.hostCoachPic || ''" fallback="/src/assets/imgs/default-head-1.webp"/>
            <div>
              <div class="coach-name">{{props.data.hostCoachName}}</div>
              <div class="formation">执教胜率{{props.data.hostTeamWinRate}}%</div>
            </div>
          </div>
          <div class="money">
            <p>球员总身价</p>
            <p>{{props.data.hostMarketValue}}</p>
          </div>
        </div>
      </div>



      <div class="coachesInfo" v-else>
        <div class="coach" >
          <div class="coach-info">
            <a-image :width="48" :height="48" :src="props.data.guestCoachPic || ''" fallback="/src/assets/imgs/default-head-1.webp"/>

            <div>
              <div class="coach-name">{{props.data.guestCoachName}}</div>
              <div class="formation">执教胜率{{props.data.guestTeamWinRate}}%</div>
            </div>
          </div>

          <div class="money">
            <p>球员总身价</p>
            <p>{{ props.data.guestMarketValue }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="players-wrapper">


      <!--    首发  -->
      <div class="team-list">
        <div class="title rollCall">首发名单</div>
        <div v-for="player in props.firstData[type]"
             :key="player.id"
             class="player-item">
          <div class="player-info">
            <a-image :width="24" :height="24" :src="player.picUrl || ''" fallback="/src/assets/imgs/default-head-1.webp"/>

            <span class="player-number">{{ player.number }}</span>
            <div class="player-name">
              <p>
                <span style="margin-right: 6px;margin-bottom: 3px;">{{ player.playerName }}</span>
                <HEvent :playerName="player.playerName" index="hostEventList" />

              </p>
              <p class="resetTypeName" v-if="player.downTimeList.length || player.upTimeList.length">
                <img src='@assets/icons/score/substitution.webp' alt="">
                <span>{{ hostSubstitution(player, 'hostTeamMatchLineupList') }}</span>
              </p>
            </div>
          </div>

          <div class="player-status">
            <div class="player-rating" v-if="player.rating">{{ player.rating }}</div>
          </div>
        </div>
      </div>

      <!--    替补  -->

      <div class="team-list">
        <div class="title rollCall">替补名单</div>
        <div v-for="player in props.downData[type]"
             :key="player.id"
             class="player-item">
          <div class="player-info">
            <a-image :width="24" :height="24" :src="player.picUrl || ''" fallback="/src/assets/imgs/default-head-1.webp"/>

            <span class="player-number">{{ player.number }}</span>
            <div class="player-name">
              <p>
                <span style="margin-right: 6px;margin-bottom: 3px;">{{ player.playerName }}</span>
                <HEvent :playerName="player.playerName" index="hostEventList" />

              </p>
              <p class="resetTypeName" v-if="player.downTimeList.length || player.upTimeList.length">
                <img src='@assets/icons/score/substitution.webp' alt="">
                <span>{{ hostSubstitution(player, 'hostTeamMatchLineupList') }}</span>
              </p>
            </div>
          </div>

          <div class="player-status">
            <div class="player-rating" v-if="player.rating">{{ player.rating }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, h, onMounted, defineProps} from "vue";
import {eventLegendIcon} from "@/views/live-detail/footballComponents/eventLegendStatus";

interface PlayerEvent {
  type: string;
  icon: string;
}

interface Player {
  id: number;
  number: number;
  name: string;
  avatar: string;
  rating: string;
  events?: PlayerEvent[];
}

const props = defineProps<{
  data: any
  firstData: any
  downData: any
  type: string
}>()

const HEvent = ({playerName = '', index = ''}) => {
  let hData = h('img', {src: ''})

  let data = props.data[index]
  let findData = data.filter((item: any) => item.typeName !== '换人' && item.playerName === playerName)
  if (findData.length) {



    let imgInfo = eventLegendIcon[findData[0].typeName]
    hData = h('img', {src: imgInfo.src})
  }

  return hData
}

const hostSubstitution = (item: any, key: string) => {
  let str = ''
  if (item.upTimeList.length) {
    let index = item.upTimeList[0]
    let data = props.downData[key]
    data.forEach((i: any) => {
      if (i.downTimeList.length && i.downTimeList[0] === index) {
        str = i.playerName
      }
    })
  }
  //
  if (item.downTimeList.length) {
    let index = item.downTimeList[0]
    let data = props.downData[key]
    data.forEach((i: any) => {
      if (i.upTimeList.length && i.upTimeList[0] === index) {
        str = i.playerName
      }
    })
  }

  return str

}

onMounted(() => {
  console.log(props.data, '-data-');
})

</script>

<style scoped lang="scss">
.lineup-list {
  background: $scoreFootball-lineup-bg-primary;
  border-radius: 8px;
  overflow: hidden;

  .coaches {

    margin-top: 20px;
    padding: 16px;
    //background: rgba(215, 42, 42, 0.66);
    border-radius: 8px;
    .coach {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .coach-info {
        display: flex;
        align-items: center;
        & > div {
          margin-left: 4px;
          .coach-name {
            font-size: 14px;
            margin-bottom: 4px;
            color: $scoreFootball-lineup-text-primary;
          }
          .formation {
            font-size: 12px;
            color: $scoreFootball-lineup-text-secondary;
          }
        }
      }
      .money {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: $scoreFootball-lineup-text-primary;
      }
    }
  }

  .list-header {
    padding: 12px 0;
    text-align: center;

    background-repeat: no-repeat;

    .title {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: $scoreFootball-lineup-text-dark;
      position: relative;
      background-image: url("@assets/icons/score/rollCall.webp");
      background-size: 100% 100%;


      &::before {
        left: -30px;
      }

      &::after {
        right: -30px;
      }
    }
  }

  .players-wrapper {
    //display: flex;
    //gap: 1px;
    background: $scoreFootball-lineup-bg-primary;
  }

  .team-list {
    flex: 1;
    margin-top: 20px;
    background: $scoreFootball-lineup-bg-secondary;
    .rollCall {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      color: $scoreFootball-lineup-text-secondary;
      background: $scoreFootball-lineup-bg-header;
      border-radius: 8px 8px 8px 8px;
    }
  }

  .player-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin: 0 16px;
    border-bottom: 1px solid $scoreFootball-lineup-border;

    &:last-child {
      border-bottom: none;
    }

    .player-info {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;

      .player-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #f0f0f0;
      }

      .player-number {
        min-width: 20px;
        color: #666;
        font-size: 14px;

      }

      .player-name {
        font-size: 14px;
        color: $scoreFootball-lineup-text-dark;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        p.resetTypeName {
          span {
            color: $scoreFootball-lineup-text-secondary !important;
          }
        }

        p {
          display: flex;
          align-items: center;

          span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $scoreFootball-lineup-text-primary;
          }

          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }

    .player-status {
      display: flex;
      align-items: center;
      gap: 8px;

      .player-events {
        display: flex;
        gap: 4px;

        .event-icon {
          width: 16px;
          height: 16px;
        }
      }

      .player-rating {
        min-width: 36px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: $scoreFootball-lineup-rating-bg;
        color: $scoreFootball-lineup-bg-primary;
        border-radius: 10px;
        font-size: 12px;
      }
    }
  }
}
</style>
