import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

interface ScoreItem {
  // 定义你的数据类型
  [key: string]: any
}

export const scoreRightStore = defineStore('scoreRight', () => {
    const scoreItemData = ref<ScoreItem>({})

    function getScoreItemData(item: ScoreItem) {
        scoreItemData.value = item
    }

    return { 
        scoreItemData, 
        getScoreItemData 
    }
})

